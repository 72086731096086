<template>
  <div class="playermon-card-border border-gradient">
    <div class="playermon-card" @click="disabled ? '' : onClick()" :class="disabled ? 'disabled' : ''">
      <div class="playermon-card-header">
        <img v-if="icon" :src="icon" class="img-fluid" style="width: 10px; height: 12px; margin-right: 8px" />
        <div style="display: initial; font-size: 12px; padding-top: 3px">#{{ id }}</div>
        <div v-if="id <= 30000" style="display: initial; background: #f05b36; padding: 1px 12px; font-size: 12px; border-radius: 12px; margin-left: auto; padding-top: 2px">Genesis</div>
      </div>
      <div class="playermon-card-body" style="display: flex; justify-content: center; align-items: center">
        <img class="img-fluid" :src="image" style="height: 150px !important" />
      </div>
      <div class="playermon-card-footer">
        <div style="font-size: 14px">Playermon #{{ id }}</div>
        <div v-if="price" style="display: flex; flex-direction: column; justify-content: center; margin-top: 8px">
          <span style="font-size: 18px">{{ price }} PYM</span>
          <span v-if="priceOfPlayermonInUSD > 0" style="font-size: 14px; color: #ffffff75">${{ priceOfPlayermonInUSD }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import { onMounted, onUpdated } from '@vue/runtime-core';

export default {
  name: 'PlayermonCard',
  props: ['id', 'breedCount', 'img', 'price', 'usd', 'disabled', 'icon'],
  setup(props) {
    const { id, price, img } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const priceOfPlayermonInUSD = ref(0);
    const usdPerMatic = ref(0);
    const image = ref('');

    onMounted(async () => {
      // console.log('price', price.value);
      await getUsdPerMatic();
      getPlayermonUsd();
      imageMapping();
    });

    onUpdated(() => {
      getPlayermonUsd();
      imageMapping();
    });

    const imageMapping = () => {
      image.value = img.value.replace('ipfs://', 'https://ipfs.io/ipfs/');
    };

    const onClick = () => {
      router.push({ name: 'Playermon', query: { id: id.value } });
    };

    const getUsdPerMatic = async () => {
      await store.dispatch('loading/setLoading', true);
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=playermon&vs_currencies=usd');
      usdPerMatic.value = response.data['playermon'].usd;
      await store.dispatch('loading/setLoading', false);
    };

    const getPlayermonUsd = () => {
      if (price.value) {
        const usdPerMaticBN = ethers.utils.parseEther(usdPerMatic.value.toString()); // parseEther will have 18 digits
        const pricePerPlayermonBN = ethers.utils.parseEther(price.value.toString()); // parseEther will have 18 digits
        const priceOfPlayermonInUSDBN = usdPerMaticBN.mul(pricePerPlayermonBN); // 18 digits * 18 digits = 36 digits
        const rawPriceOfPlayermonInUSD = ethers.utils.formatUnits(priceOfPlayermonInUSDBN, 36); // format unit 36 digits
        priceOfPlayermonInUSD.value = parseFloat(rawPriceOfPlayermonInUSD).toFixed(2);
      }
    };

    return { image, onClick, priceOfPlayermonInUSD };
  },
};
</script>

<style scoped>
.playermon-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 265px; */
  min-width: 200px;
  border-radius: 24px;
  padding: 16px;
  background-color: #4fa9f9;
  cursor: pointer;
}

.playermon-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  padding: 6px;
  background: linear-gradient(#0083dc, #005691);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.playermon-card:hover {
  border-color: #ffffff50;
}

.playermon-card-header {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 8px;
  background: #161a69;
}

.playermon-card-body {
  width: 180px;
  height: 180px;
  margin: 8px 0;
  border-radius: 12px;
  background-color: #161a69;
  background-size: cover;
}

.playermon-card-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  padding: 8px;
  background: #161a69;
}

.disabled {
  cursor: auto;
}

.disabled:hover {
  border-color: #ffffff26;
}
</style>
