<template>
  <MarketplaceNavBar :noSidebar="true" :matic="matic" :pym="pym" :sgem="sgem" :connected="connected" @connectWallet="connectWallet" />
  <VueSlider />
  <div class="custom-container">
    <marketplace-tab class="marketplace-tab-link" />
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-lg-3 d-none d-xxl-block">
          <sidebar-filter
            v-model="classTypeCheck"
            v-model:rarityCheck="rarityCheck"
            v-model:stageCheck="stageCheck"
            @onClick="onClick"
            :filtersCount="filtersCount"
            @clearFilters="clearFilters"
            :selectSwappableBodyParts="selectSwappableBodyParts"
            :filterSwappableBodyParts="filterSwappableBodyParts"
            :resultFilterSwappableBodyParts="resultFilterSwappableBodyParts"
            :swappableBodyParts="swappableBodyParts"
            :removeSwappableBodyParts="removeSwappableBodyParts"
            :setStats="setStats"
          />
        </div>
        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12 mx-auto">
          <div class="container">
            <div>
              <span class="d-flex justify-content-center d-block d-xxl-none mt-4 mb-3">
                <button class="btn btn-primary w-50" type="button" data-bs-toggle="offcanvas" data-bs-target="#canvasSideBarFilter" aria-controls="canvasSideBarFilter">Filter ({{ filtersCount }})</button>
              </span>
              <!-- canvas-->
              <div class="offcanvas offcanvas-start d-block d-xxl-none" tabindex="-1" id="canvasSideBarFilter" aria-labelledby="canvasSideBarFilterLabel">
                <sidebar-filter
                  v-model="classTypeCheck"
                  v-model:rarityCheck="rarityCheck"
                  @onClick="onClick"
                  @clearFilters="clearFilters"
                  :filtersCount="filtersCount"
                  :selectSwappableBodyParts="selectSwappableBodyParts"
                  :filterSwappableBodyParts="filterSwappableBodyParts"
                  :resultFilterSwappableBodyParts="resultFilterSwappableBodyParts"
                  :swappableBodyParts="swappableBodyParts"
                  :removeSwappableBodyParts="removeSwappableBodyParts"
                  :setStats="setStats"
                />
              </div>

              <div class="row mt-2">
                <div class="col-lg-12 d-flex justify-content-between">
                  <div class="mt-2">{{ totalPlayermons }} Playermons</div>
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{{ currentGeneralSort.name.value }}</button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li v-for="item in generalSorting" :key="item.name">
                        <a class="dropdown-item" @click="setCurrentGeneralSort(item.name, item.value)">{{ item.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- <div class="sale-sort dropdown col col-sm-6 d-flex justify-content-end">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">{{ currentSaleSort }}</button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                    <li v-for="item in saleSorting" :key="item">
                      <a class="dropdown-item" href="#" @click="setCurrentSaleSort(item)">{{ item }}</a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>

            <div v-if="playermons.length === 0" style="display: flex; justify-content: center">
              <empty-playermon />
            </div>
            <div v-else>
              <div class="playermons-container">
                <playermon-card v-for="item in playermons" :key="item.id" :id="item.playermon_id" :breedCount="item.breedCount" :img="item.image" :usd="item.usd" :price="item.price" />
              </div>
              <bottom-page-button @onLeft="onLeft" @onRight="onRight" :currentPage="currentPage" :totalPage="totalPage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { ethers } from 'ethers';
import { useStore } from 'vuex';
import { JSBI, Fraction } from '@pancakeswap-libs/sdk';

import ERC20 from '../../../assets/abi/ERC20.json';
import PlayermonNFT from '../../../assets/abi/PlayermonNFT.json';
import { getPlayermonList } from '../src/marketplaceApi';
import { rarityMapper } from '../../../helper';

import MarketplaceNavBar from '@/components/MarketplaceNavBar.vue';
import PlayermonCard from '../../../components/PlayermonCard.vue';
import BottomPageButton from '../../../components/BottomPageButton.vue';
import EmptyPlayermon from '../../../components/Inventory/EmptyPlayermon.vue';
import { useRoute, useRouter } from 'vue-router';
import MarketplaceTab from './components/MarketplaceTab.vue';
import SidebarFilter from './components/SidebarFilter.vue';
import SWAP from './SWAPPABLE_LIST.JSON';

export default {
  components: {
    MarketplaceNavBar,
    PlayermonCard,
    BottomPageButton,
    EmptyPlayermon,
    MarketplaceTab,
    SidebarFilter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const generalSorting = [
      { name: 'Latest', value: 'latest' },
      { name: 'Lowest price', value: 'priceAsc' },
      { name: 'Highest price', value: 'priceDesc' },
      { name: 'Lowest ID', value: 'idAsc' },
      { name: 'Highest ID', value: 'idDesc' },
    ];
    const saleSorting = ['All', 'For sale', 'Not for sale'];
    const currentGeneralSort = { name: ref(generalSorting[1].name), value: ref(generalSorting[1].value) };
    const currentSaleSort = ref(saleSorting[1]);
    const playermons = ref([]);

    const playermonsBodyPartCategory = SWAP;

    const playermonsPerPage = 16;
    const userWalletAddress = ref('');
    const connected = ref(false);
    const matic = ref(0);
    const sgem = ref(0.0);
    const pym = ref(0);
    const currentPage = ref(1);
    const totalPage = ref(1);
    const totalPlayermons = ref(0);
    const params = { limit: 10 };

    const filtersCount = ref(0);

    const classTypeCheck = ref([]);
    const rarityCheck = ref([]);
    const stageCheck = ref([]);
    const resultFilterSwappableBodyParts = ref([]);
    const swappableBodyParts = ref([]);

    onMounted(async () => {
      const { page, type, rarity, sort, minAgility, maxAgility, minLuck, maxLuck, minStrength, maxStrength, minIntelligence, maxIntelligence, stage, part } = route.query;

      if (page) {
        const queryPage = parseInt(page);
        if (queryPage > 0) {
          currentPage.value = queryPage;
          params.page = currentPage.value;
        }
      }

      if (type) {
        params.classType = type;
      }

      if (rarity) {
        params.rarity = rarityMapper(rarity);
      }

      if (sort) {
        params.sort = currentGeneralSort.value.value;
      }

      if (minAgility) {
        params.minAgility = minAgility;
      }

      if (maxAgility) {
        params.maxAgility = maxAgility;
      }

      if (minLuck) {
        params.minLuck = minLuck;
      }

      if (maxLuck) {
        params.maxLuck = maxLuck;
      }

      if (minStrength) {
        params.minStrength = minStrength;
      }

      if (maxStrength) {
        params.maxStrength = maxStrength;
      }

      if (minIntelligence) {
        params.minIntelligence = minIntelligence;
      }

      if (maxIntelligence) {
        params.maxIntelligence = maxIntelligence;
      }

      if (stage) {
        params.stages = stage;
      }

      if (part) {
        const parts = Array.isArray(part) ? playermonsBodyPartCategory.filter((player) => part.some((part) => part == player.name)) : playermonsBodyPartCategory.filter((player) => player.name == part);
        setSwappableBodyParts(parts);
      }

      await getMarketplacePlayermonList(params);
    });

    const getMarketplacePlayermonList = async (page) => {
      try {
        await store.dispatch('loading/setLoading', true);
        const response = await getPlayermonList(page);
        if (response.status) {
          playermons.value = response.data.marketplace_items;
          totalPage.value = response.data.total_pages;
          totalPlayermons.value = response.data.total_items;
        }
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        console.error(e);
        await store.dispatch('loading/setLoading', false);
      }
    };

    const getMatic = async () => {
      try {
        const ownEthersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ownEthersProvider.getSigner();
        const matic = await signer.getBalance();
        const readableMatic = new Fraction(JSBI.BigInt(matic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        return readableMatic;
      } catch (e) {
        console.error(e);
      }
    };

    const setCurrentGeneralSort = async (name, value) => {
      currentGeneralSort.value.value = value;
      currentGeneralSort.name.value = name;

      const { rarity, type, minAgility, maxAgility, minLuck, maxLuck, minStrength, maxStrength, minIntelligence, maxIntelligence, stage, sort } = route.query;
      if (rarity || type || minAgility || maxAgility || minLuck || maxLuck || minStrength || maxStrength || minIntelligence || maxIntelligence || stage || sort) {
        params.page = currentPage.value;
        params.classType = type;
        params.rarity = rarityMapper(rarity);
        params.minAgility = minAgility;
        params.maxAgility = maxAgility;
        params.minLuck = minLuck;
        params.maxLuck = maxLuck;
        params.minStrength = minStrength;
        params.maxStrength = maxStrength;
        params.minIntelligence = minIntelligence;
        params.maxIntelligence = maxIntelligence;
        params.sort = currentGeneralSort.value.value;
        params.stages = stage;
        router.push({
          name: 'Marketplace',
          query: {
            type: type,
            rarity: rarity,
            sort: currentGeneralSort.value.value,
            page: currentPage.value,
            minAgility: minAgility,
            maxAgility: maxAgility,
            minLuck: minLuck,
            maxLuck: maxLuck,
            minStrength: minStrength,
            maxStrength: maxStrength,
            minIntelligence: minIntelligence,
            maxIntelligence: maxIntelligence,
            stage: stage,
          },
        });

        await getMarketplacePlayermonList(params);
      } else {
        params.sort = currentGeneralSort.value.value;
        router.push({
          name: 'Marketplace',
          query: {
            sort: currentGeneralSort.value.value,
            page: currentPage.value,
          },
        });
        await getMarketplacePlayermonList(params);
      }
    };

    const setCurrentSaleSort = (value) => {
      currentSaleSort.value = value;
    };

    const onLeft = async () => {
      const { rarity, type, sort, minAgility, maxAgility, minLuck, maxLuck, minStrength, maxStrength, minIntelligence, maxIntelligence, stage, part } = route.query;
      if (currentPage.value >= 1) {
        currentPage.value -= 1;
        if (rarity || type || sort || minAgility || maxAgility || minLuck || maxLuck || minStrength || maxStrength || minIntelligence || maxIntelligence || stage) {
          params.page = currentPage.value;
          params.classType = type;
          params.rarity = rarityMapper(rarity);
          params.sort = sort;
          params.minAgility = minAgility;
          params.maxAgility = maxAgility;
          params.minLuck = minLuck;
          params.maxLuck = maxLuck;
          params.minStrength = minStrength;
          params.maxStrength = maxStrength;
          params.minIntelligence = minIntelligence;
          params.maxIntelligence = maxIntelligence;
          params.stages = stage;
          if (part) {
            const parts = Array.isArray(part) ? playermonsBodyPartCategory.filter((player) => part.some((part) => part == player.name)) : playermonsBodyPartCategory.filter((player) => player.name == part);
            setSwappableBodyParts(parts);
          }
          await getMarketplacePlayermonList(params);
          router.push({
            name: 'Marketplace',
            query: {
              type: type,
              rarity: rarity,
              sort: sort,
              page: currentPage.value,
              minAgility: minAgility,
              maxAgility: maxAgility,
              minLuck: minLuck,
              maxLuck: maxLuck,
              minStrength: minStrength,
              maxStrength: maxStrength,
              minIntelligence: minIntelligence,
              maxIntelligence: maxIntelligence,
              stage: stage,
              part: part,
            },
          });
        } else {
          router.push({ name: 'Marketplace', query: { type: classTypeCheck.value, rarity: rarityCheck.value, sort: currentGeneralSort.value.value, stage: stageCheck.value, page: currentPage.value } });
          params.rarity = rarityMapper(rarityCheck.value);
          params.page = currentPage.value;
          params.classType = classTypeCheck.value;
          params.sort = currentGeneralSort.value.value;
          params.stages = stageCheck.value;
          await getMarketplacePlayermonList(params);
        }
      }
    };

    const onRight = async () => {
      const { rarity, type, sort, minAgility, maxAgility, minLuck, maxLuck, minStrength, maxStrength, minIntelligence, maxIntelligence, part, stage } = route.query;

      if (totalPage.value > 1) {
        currentPage.value += 1;
        params.page = currentPage.value;
        if (rarity || type || sort || minAgility || maxAgility || minLuck || maxLuck || minStrength || maxStrength || minIntelligence || maxIntelligence || stage) {
          params.classType = type;
          params.rarity = rarityMapper(rarity);
          params.sort = sort;
          params.minAgility = minAgility;
          params.maxAgility = maxAgility;
          params.minLuck = minLuck;
          params.maxLuck = maxLuck;
          params.minStrength = minStrength;
          params.maxStrength = maxStrength;
          params.minIntelligence = minIntelligence;
          params.maxIntelligence = maxIntelligence;
          params.stages = stage;
          if (part) {
            const parts = Array.isArray(part) ? playermonsBodyPartCategory.filter((player) => part.some((part) => part == player.name)) : playermonsBodyPartCategory.filter((player) => player.name == part);
            setSwappableBodyParts(parts);
          }
          await getMarketplacePlayermonList(params);
          router.push({
            name: 'Marketplace',
            query: {
              type: type,
              rarity: rarity,
              sort: sort,
              page: currentPage.value,
              minAgility: minAgility,
              maxAgility: maxAgility,
              minLuck: minLuck,
              maxLuck: maxLuck,
              minStrength: minStrength,
              maxStrength: maxStrength,
              minIntelligence: minIntelligence,
              maxIntelligence: maxIntelligence,
              stage: stage,
              part: part,
            },
          });
        } else {
          params.rarity = rarityMapper(rarityCheck.value);
          params.classType = classTypeCheck.value;
          params.sort = currentGeneralSort.value;
          params.stage = stageCheck.value;
          await getMarketplacePlayermonList(params);
          router.push({ name: 'Marketplace', query: { type: classTypeCheck.value, rarity: rarityCheck.value, sort: currentGeneralSort.value.value, page: currentPage.value, stage: stageCheck.value } });
        }
      }
    };

    const onClick = async () => {
      if (classTypeCheck.value) {
        params.classType = classTypeCheck.value;
      }

      if (rarityCheck.value) {
        params.rarity = rarityMapper(rarityCheck.value);
      }

      if (stageCheck.value.length > 0) {
        params.stages = stageCheck.value;
      }

      params.page = currentPage.value;
      router.push({ name: 'Marketplace', query: { sort: currentGeneralSort.value.value, type: classTypeCheck.value, page: currentPage.value, rarity: rarityCheck.value, part: swappableBodyParts.value.map((swappableBodyParts) => swappableBodyParts.name), stage: stageCheck.value } });
      await getMarketplacePlayermonList(params);
      filtersCount.value = classTypeCheck.value.length + rarityCheck.value.length + stageCheck.value.length;
    };

    const clearFilters = async () => {
      Object.keys(params).forEach((key) => {
        delete params[key];
      });
      rarityCheck.value = [];
      classTypeCheck.value = [];
      stageCheck.value = [];
      currentPage.value = 1;
      params.sort = generalSorting[0].value.value;
      router.push({ name: 'Marketplace', query: { page: currentPage.value } });
      await getMarketplacePlayermonList(params);
      filtersCount.value = 0;
    };

    const connectWallet = async () => {
      const provider = window.ethereum;
      const ownEthersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ownEthersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      if (!provider) {
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await changeNetwork();
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });

          connected.value = true;
          const userAddress = await signer.getAddress();
          userWalletAddress.value = userAddress;

          const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
          const nftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, signer);

          const userNFTBalance = await nftContract.balanceOf(userAddress);
          totalPlayermons.value = parseInt(userNFTBalance.toString());
          totalPage.value = Math.ceil(totalPlayermons.value / playermonsPerPage);

          const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
          matic.value = await getMatic();
          pym.value = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
          const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
          sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        }
      } catch (e) {
        connected.value = false;
        console.error('Metamask', e);
      }
    };

    const changeNetwork = async () => {
      const provider = window.ethereum;
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(137).toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    };

    const filterSwappableBodyParts = (term) => {
      resultFilterSwappableBodyParts.value = playermonsBodyPartCategory.filter((playermon) => {
        return playermon.name.toLowerCase().includes(term.toLowerCase());
      });

      if (swappableBodyParts.value.length > 0) {
        resultFilterSwappableBodyParts.value = resultFilterSwappableBodyParts.value.filter((playermon) => {
          return !swappableBodyParts.value.find((rm) => rm.name === playermon.name);
        });
      }
    };

    const selectSwappableBodyParts = async (swappableBodyPart) => {
      swappableBodyParts.value.push(swappableBodyPart);
      router.push({
        name: 'Marketplace',
        query: {
          type: classTypeCheck.value,
          page: currentPage.value,
          rarity: rarityCheck.value,
          part: swappableBodyParts.value.map((swappableBodyParts) => swappableBodyParts.name),
          minAgility: params.minAgility,
          maxAgility: params.maxAgility,
          minLuck: params.minLuck,
          maxLuck: params.maxLuck,
          minStrength: params.minStrength,
          maxStrength: params.maxStrength,
          minIntelligence: params.minIntelligence,
          maxIntelligence: params.maxIntelligence,
        },
      });
      setSwappableBodyParts(swappableBodyParts.value);
      await getMarketplacePlayermonList(params);

      resultFilterSwappableBodyParts.value = [];
      filtersCount.value += 1;
    };

    const removeSwappableBodyParts = async (swappableBodyPart) => {
      swappableBodyParts.value = swappableBodyParts.value.filter((category) => category.name != swappableBodyPart.name);
      setSwappableBodyParts(swappableBodyParts.value);
      await getMarketplacePlayermonList(params);
      router.push({
        name: 'Marketplace',
        query: {
          type: classTypeCheck.value,
          page: currentPage.value,
          rarity: rarityCheck.value,
          part: swappableBodyParts.value.map((swappableBodyParts) => swappableBodyParts.name),
          minAgility: params.minAgility,
          maxAgility: params.maxAgility,
          minLuck: params.minLuck,
          maxLuck: params.maxLuck,
          minStrength: params.minStrength,
          maxStrength: params.maxStrength,
          minIntelligence: params.minIntelligence,
          maxIntelligence: params.maxIntelligence,
        },
      });
      filtersCount.value -= 1;
    };

    const setSwappableBodyParts = (swappableBodyParts) => {
      let part = {
        swappableArmsName: [],
        swappableHeadsName: [],
        swappableEyesName: [],
        swappableTailsName: [],
        swappableBodiesName: [],
        swappableLegsName: [],
        swappableChestsName: [],
      };
      for (const swappableBodyPart of swappableBodyParts) {
        if (swappableBodyPart.category == 'head') {
          part.swappableHeadsName.push(swappableBodyPart.value);
        }

        if (swappableBodyPart.category == 'eye') {
          part.swappableEyesName.push(swappableBodyPart.value);
        }

        if (swappableBodyPart.category == 'arm') {
          part.swappableArmsName.push(swappableBodyPart.value);
        }

        if (swappableBodyPart.category == 'tail_object') {
          part.swappableTailsName.push(swappableBodyPart.value);
        }

        if (swappableBodyPart.category == 'body') {
          part.swappableBodiesName.push(swappableBodyPart.value);
        }

        if (swappableBodyPart.category == 'leg') {
          part.swappableLegsName.push(swappableBodyPart.value);
        }

        if (swappableBodyPart.category == 'chest_object') {
          part.swappableChestsName.push(swappableBodyPart.value);
        }
      }
      if (Object.keys(part).length > 0) {
        Object.assign(params, part);
      }
    };

    const setStats = async (stats) => {
      let statsParams = {};
      if (stats.name == 'Agility') {
        statsParams.maxAgility = stats.value[1];
        statsParams.minAgility = stats.value[0];
      }

      if (stats.name == 'Luck') {
        statsParams.maxLuck = stats.value[1];
        statsParams.minLuck = stats.value[0];
      }

      if (stats.name == 'Strength') {
        statsParams.maxStrength = stats.value[1];
        statsParams.minStrength = stats.value[0];
      }

      if (stats.name == 'Intelligence') {
        statsParams.minIntelligence = stats.value[0];
        statsParams.maxIntelligence = stats.value[1];
      }
      if (Object.keys(statsParams).length > 0) {
        Object.assign(params, statsParams);
      }
      await getMarketplacePlayermonList(params);
      router.push({
        name: 'Marketplace',
        query: {
          type: classTypeCheck.value,
          page: currentPage.value,
          rarity: rarityCheck.value,
          minAgility: params.minAgility,
          maxAgility: params.maxAgility,
          minLuck: params.minLuck,
          maxLuck: params.maxLuck,
          minStrength: params.minStrength,
          maxStrength: params.maxStrength,
          minIntelligence: params.minIntelligence,
          maxIntelligence: params.maxIntelligence,
          part: swappableBodyParts.value.map((swappableBodyParts) => swappableBodyParts.name),
        },
      });
    };

    return {
      sgem,
      pym,
      matic,
      currentPage,
      totalPage,
      generalSorting,
      saleSorting,
      currentGeneralSort,
      currentSaleSort,
      playermons,
      totalPlayermons,
      connected,
      setCurrentGeneralSort,
      setCurrentSaleSort,
      onLeft,
      onRight,
      connectWallet,
      classTypeCheck,
      rarityCheck,
      onClick,
      filtersCount,
      clearFilters,
      filterSwappableBodyParts,
      resultFilterSwappableBodyParts,
      swappableBodyParts,
      selectSwappableBodyParts,
      removeSwappableBodyParts,
      setSwappableBodyParts,
      setStats,
      stageCheck,
    };
  },
};
</script>

<style scoped>
.marketplace-tab-link {
  padding-left: 45px;
  padding-top: 16px;
  background: var(--sub-background-color);
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .marketplace-tab-link {
    background: none;
  }
}

.btn {
  position: sticky;
  top: 0;
}

.btn-secondary:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

.dropdown-menu {
  background: #1e0830;
}

.dropdown-item {
  color: whitesmoke;
}

.dropdown-item:hover {
  background-color: var(--sub-background-color);
}

@media screen and (max-width: 344px) {
  .btn {
    width: 100% !important;
    min-width: 220px;
  }
}

.playermons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 24px;
  margin-top: 48px;
  z-index: 1;
}

.container-fluid {
  overflow: hidden;
}

.hide {
  display: none;
}

.show {
  visibility: visible;
}

.offcanvas {
  background-color: var(--main-background-color);
  height: 100vh;
  overflow-y: scroll;
}
</style>
