<template>
  <div class="list-item">
    <div :style="titleConfig">{{ title }}</div>
    <div :style="metadataConfig">{{ metadata }}</div>
  </div>
</template>

<script>
export default {
  props: ['title', 'titleConfig', 'metadata', 'metadataConfig'],
};
</script>

<style scoped>
.list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: baseline;
}

.list-item div {
  text-transform: capitalize;
}
</style>
