<template>
  <div style="background: #2d0e54">
    <NavBar />
    <div class="download-container" :style="styleObject">
      <div class="download">
        <h4>PLAY NOW</h4>
        <div style="margin-bottom: 32px">Playermon BETA version 2.4.0 is available on:</div>
        <div v-if="distance > 0" class="d-flex justify-content-center" style="font-size: 5vw; gap: 16px; margin-bottom: 32px">
          <div style="width: 6vw">{{ days }}</div>
          <div>|</div>
          <div style="width: 6vw">{{ hours }}</div>
          <div>|</div>
          <div style="width: 6vw">{{ minutes }}</div>
          <div>|</div>
          <div style="width: 6vw">{{ seconds }}</div>
        </div>
        <div v-if="distance > 0" class="button-container">
          <button class="buttons" disabled style="background: grey; opacity: 0.6; cursor: auto">
            <img src="../assets/windows.png" width="30" />
            <div>Windows</div>
          </button>
          <button class="buttons" disabled style="background: grey; opacity: 0.6; cursor: auto">
            <img src="../assets/android.png" width="30" />
            <div>Android</div>
          </button>
        </div>
        <div v-if="distance < 1" class="button-container">
          <a class="buttons" href="https://download.playermon.com/playermon-game-v2.4.0.zip" target="_blank" download style="z-index: 2">
            <img src="../assets/windows.png" width="21" height="27" />
            <div>Windows</div>
          </a>
          <a class="buttons" href="https://download.playermon.com/playermon-game-v2.4.0.apk" target="_blank" download style="z-index: 2">
            <img src="../assets/android.png" width="28" />
            <div>Android</div>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import { onMounted, ref } from '@vue/runtime-core';

export default {
  name: 'ComingSoon',
  components: {
    Footer,
    NavBar,
  },
  data() {
    const countDownDate = ref(164018160000);
    const days = ref(0);
    const hours = ref(0);
    const minutes = ref(0);
    const seconds = ref(0);
    const distance = ref(0);

    onMounted(() => {
      countDown();
    });

    const countDown = () => {
      const now = new Date().getTime();
      setTimeout(() => {
        const calDistance = countDownDate.value - now;
        if (calDistance > 0) {
          if (distance.value < 0) {
            days.value = '00';
            hours.value = '00';
            minutes.value = '00';
            seconds.value = '00';
          } else {
            // Time calculations for days, hours, minutes and seconds
            const calDays = Math.floor(calDistance / (1000 * 60 * 60 * 24));
            const calHours = Math.floor((calDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const calMinutes = Math.floor((calDistance % (1000 * 60 * 60)) / (1000 * 60));
            const calSeconds = Math.floor((calDistance % (1000 * 60)) / 1000);
            days.value = ('0' + calDays).slice(-2);
            hours.value = ('0' + calHours).slice(-2);
            minutes.value = ('0' + calMinutes).slice(-2);
            seconds.value = ('0' + calSeconds).slice(-2);
            distance.value = calDistance;
            countDown();
          }
        }
      }, 1000);
    };

    return {
      days,
      hours,
      minutes,
      seconds,
      distance,
      styleObject: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url(${require('../assets/backgrounds/ruin_bg.png')})`,
      },
    };
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: linear-gradient(0deg, rgba(89, 198, 215, 0.6) 0%, #59c6d7 100%);
  border: 4px solid #9fd3e7;
  border-radius: 10px;
  box-shadow: 0 0 0 4px #112874bd;
  color: white;
  cursor: pointer;
}

.download-container {
  display: flex;
  height: 85vh;
}

.download {
  width: 70vw;
  border-radius: 28px;
  padding: 32px;
  margin: auto;
  margin-top: 15vw;
  color: white;
  background: #02006a99;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
</style>
