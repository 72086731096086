<template>
  <div class="tab-nav">
    <ul class="nav text-white w-100">
      <li v-for="item in tabs" :key="item.name">
        <a :class="currentTab === item.name ? 'active' : ''" class="tab-link" @click="setTab(item.name)">
          <img :src="item.img" height="30" />
          <span style="margin-left: 10px; margin-top: 3px">{{ item.name }}</span>
        </a>
      </li>
    </ul>
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{{ currentTab }}</button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li v-for="item in tabs" :key="item.name">
          <a class="dropdown-item" @click="setTab(item.name)">{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
  data: () => ({
    tabs: [
      { name: 'playermon', img: require('../../assets/playermon_white.png') },
      { name: 'breeding pod', img: require('../../assets/breeding_pod.png') },
      { name: 'crafting material', img: require('../../assets/breeding_pod_shard.png') },
    ],
  }),
  computed: {
    currentTab() {
      return this.$store.state.inventory.currentTab;
    },
  },
  setup() {
    const store = useStore();
    const setTab = (value) => {
      store.dispatch('inventory/setCurrentTab', value);
    };

    return { setTab };
  },
};
</script>

<style scoped>
.tab-nav {
  display: flex;
  margin-bottom: 24px;
  color: whitesmoke;
  justify-content: flex-start;
}

@media only screen and (max-width: 767px) {
  .tab-nav {
    display: block;
  }

  .tab-link {
    display: none !important;
  }

  .dropdown {
    display: block !important;
  }
}

.tab-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  margin-left: 16px;
  color: whitesmoke;
  text-transform: capitalize;
  cursor: pointer;
}

.tab-link:hover {
  border-bottom: 5px solid #ffffff26;
}

.active {
  border-bottom: 5px solid white !important;
}

.dropdown {
  display: none;
}

.dropdown-toggle {
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: none;
  background-color: #3a3f50;
  border: none;
}

.dropdown-menu {
  width: 180px;
  min-width: 180px;
  max-height: 0;
  background-color: #3a3f50;
  top: 0;
  display: block;
  overflow: hidden;
  padding: 0;
  transform: translate(0px, 38px);
  transition: 0.4s ease;
}

.dropdown-toggle,
.dropdown-item {
  color: whitesmoke;
  text-transform: capitalize;
}

.dropdown-menu.show {
  max-height: 300px;
  transition: 0.4s;
  padding: 0.5rem 0;
}
</style>
