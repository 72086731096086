<template>
  <div :style="isFromMarketplace ? 'background: #242735; height: 100%' : 'background: #21339d; min-height: 100%'">
    <marketplace-nav-bar v-if="isFromMarketplace" :matic="matic" :pym="pym" :sgem="sgem" />
    <nav-bar v-else style="background: rgb(10, 16, 90)" />
    <div :class="isFromMarketplace ? 'inventory sys-container' : 'custom-content'" style="padding-bottom: 32px">
      <div class="tokenomics container">
        <div class="tokenomics-card" :style="isFromMarketplace ? 'background: #1E083065' : 'background: #00000026'">
          <div style="margin-bottom: 16px">
            <span style="font-size: 1.2rem; float: left; font-weight: bold">Maximum Supply</span>
            <span style="color: #ffffff90; font-size: 1.2rem; float: right; font-weight: bold">1,000,000,000.00</span>
          </div>
          <div v-for="token in tokenList" :key="token">
            <span style="font-size: 1.1rem; float: left; margin: 4px 0"
              >{{ token.name }}
              <a :href="'https://polygonscan.com/token/0x0bd49815ea8e2682220bcb41524c0dd10ba71d41?a=' + token.address" target="_blank" style="word-break: break-word; margin-bottom: 16px; margin-top: 24px">{{
                token.address.substring(0, 5) + '...' + token.address.substring(token.address.length - 5, token.address.length)
              }}</a></span
            >
            <span style="color: #ffffff90; font-size: 1.1rem; float: right">- {{ token.pymAmount }}</span>
          </div>
          <div style="margin-top: 16px">
            <span style="font-size: 1.2rem; float: left; font-weight: bold">Circulating Supply</span>
            <span style="color: #ffffff90; font-size: 1.2rem; float: right; font-weight: bold">{{ circulatingSupply }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { onMounted, ref } from '@vue/runtime-core';
import MarketplaceNavBar from '../components/MarketplaceNavBar.vue';
import NavBar from '../components/NavBar.vue';
import axios from 'axios';
import ERC20 from '../assets/abi/ERC20.json';
import { numberWithComma } from '../helper';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { JSBI, Fraction } from '@pancakeswap-libs/sdk';

export default {
  name: 'PYM Tokenomics',
  components: { MarketplaceNavBar, NavBar },
  props: ['fromMarketplace'],
  setup() {
    const isFromMarketplace = ref(false);
    const circulatingSupply = ref(0);
    const tokenList = ref([]);
    const store = useStore();
    const route = useRoute();
    const matic = ref(0);
    const sgem = ref(0.0);
    const pym = ref(0);

    onMounted(async () => {
      try {
        await store.dispatch('loading/setLoading', true);
        const fromMarketplace = route.query?.fromMarketplace;

        const response = await axios.get('https://api.playermon.com/api/stats/getallstats');
        let tokenData = [];
        for (let i = 0; i < response.data.length; i++) {
          const amount = response.data[i].pymAmount;
          tokenData.push({
            name: response.data[i].name,
            address: response.data[i].address,
            pymAmount: numberWithComma(amount.substring(0, amount.indexOf('.') + 3)),
          });
        }
        tokenList.value = tokenData;
        isFromMarketplace.value = fromMarketplace;

        const circulatingSupplyResponse = await axios.get('https://api.playermon.com/api/stats/circulatingSupply');
        circulatingSupply.value = numberWithComma(Number(circulatingSupplyResponse.data).toFixed(2));

        matic.value = await getMatic();
        await getPym();

        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        console.error(e);
      }
    });

    const getMatic = async () => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const matic = await signer.getBalance();
        const readableMatic = new Fraction(JSBI.BigInt(matic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        return readableMatic;
      } catch (e) {
        console.error(e);
      }
    };

    const getPym = async () => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const userAddress = await signer.getAddress();
        const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
        const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
        pym.value = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

        const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
        const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
        sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
      } catch (e) {
        console.error(e);
      }
    };

    // const getSgem = async () => {
    //   try {
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };

    return { pym, matic, sgem, isFromMarketplace, tokenList, circulatingSupply };
  },
};
</script>

<style scoped>
.custom-content {
  min-height: 100%;
  padding-top: 100px;
}

.tokenomics {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
}

.tokenomics-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 310px;
  padding: 16px;
  border: 1px solid #ffffff26;
  border-radius: 16px;
  color: whitesmoke;
}
</style>
