<template>
  <breeding-pod-shard />
</template>

<script>
import BreedingPodShard from './BreedingPodShard.vue';
export default {
  components: { BreedingPodShard },
  name: 'Crafting material',
  setup() {},
};
</script>
