<template>
  <MarketplaceNavBar :matic="matic" :pym="pym" :sgem="sgem" />
  <div class="inventory sys-container">
    <div v-if="!connected" class="connect-wallet">
      <span>Let's connect your wallet to see your claimable token balance</span>
      <div style="margin-top: 16px">
        <button type="button" class="btn btn-outline-primary" @click="connectWallet">Connect Wallet</button>
      </div>
    </div>
    <div v-else-if="connected" style="display: flex; flex-wrap: wrap">
      <div v-for="item in claimableTokens" :key="item.title">
        <div class="card token-container" v-if="item.token != 0 || item.source === 'sgem'">
          <img :src="item.source === 'sgem' ? require('../assets/sgem_icon.png') : require('../assets/logo.png')" style="height: 60px" />
          <div style="margin-top: 16px; color: #ffffff85">{{ item.title }}</div>
          <div style="margin: 8px 0 16px">
            <div style="font-size: 24px">{{ item.token }}</div>
            <div v-if="distance > 0 && item.source === 'sgem' && !sgemClaimable" style="font-size: 14px; color: #ffffff75">
              <div>
                Your locked <span style="color: white; font-size: 16px; font-weight: bold">{{ item.token }}</span> will be unlocked in
              </div>
              <div style="color: white; margin-bottom: 8px">{{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }}</div>
            </div>
            <div style="font-size: 12px; color: #f0b22b">Claimable: {{ item.claimableToken }}</div>
          </div>
          <button data-bs-toggle="modal" data-bs-target="#claimModal" class="claim-button btn btn-outline" @click="setModalValue(item)" :disabled="item.claimableToken < 1">
            <span v-if="item.source === 'sgem'" style="color: whitesmoke">Claim SGEM</span>
            <span v-else style="color: whitesmoke">Claim PYM</span>
          </button>
        </div>
      </div>
    </div>
    <!-- <div v-else-if="connected && totalToken < 1">
      <span>Hmm.. It seem like you don't have any tokens to claim. PreIDO and IDO tokens are sent to your address directly</span>
    </div> -->
  </div>

  <div class="modal fade" id="claimModal" tabindex="-1" role="dialog" aria-labelledby="claimModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div style="display: flex; justify-content: flex-end">
            <span data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="cursor: pointer"><mdicon name="close" /></span>
            </span>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 20px; flex-direction: column">
            <img :src="modalValue.source === 'sgem' ? require('../assets/sgem_icon.png') : require('../assets/logo.png')" style="width: 120px" />
            <div v-if="modalValue.source === 'sgem'" style="font-size: 24px; color: #f0b22b; margin: 16px 0">Claimable: {{ modalValue.claimableToken }} SGEM</div>
            <div v-else style="font-size: 24px; color: #f0b22b; margin: 16px 0">Claimable: {{ modalValue.claimableToken }} PYM</div>
            <div class="claim-button" style="width: 150px; color: whitesmoke" @click="claimButton(modalValue)">
              <span v-if="modalValue.source === 'sgem'">Claim SGEM now</span>
              <span v-else>Claim PYM now</span>
            </div>
            <div v-if="successAlert" style="color: green; margin-top: 16px; word-break: break-word">
              Broadcasted: <a target="_blank" :href="'https://polygonscan.com/tx/' + successAlert">{{ successAlert }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { BigNumber, ethers } from 'ethers';
import { useStore } from 'vuex';
import { JSBI, Fraction } from '@pancakeswap-libs/sdk';

import MarketplaceNavBar from '@/components/MarketplaceNavBar.vue';
import PlayermonPrivateSaleClaim from '../assets/abi/PlayermonPrivateSaleClaim.json';
import SpaceGemManager from '../assets/abi/SpaceGemManager.json';

import ERC20 from '../assets/abi/ERC20.json';
import { ref, onMounted } from '@vue/runtime-core';
import userService from '../services/userService';
import { claimSgem, checkSgemNonce } from '../modules/claim-tokens/src/claimTokensApi';

export default {
  name: 'Inventory',
  components: {
    MarketplaceNavBar,
  },
  setup() {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);

    const chainId = 137;
    const claimableTokens = ref([
      {
        title: 'Seed Sale',
        token: 0,
        claimableToken: 0,
        source: 'seed',
        isTGE: false,
      },
      {
        title: 'Private 1 Sale',
        token: 0,
        claimableToken: 0,
        source: 'p1',
        isTGE: false,
      },
      {
        title: 'Private 2 Sale',
        token: 0,
        claimableToken: 0,
        source: 'p2',
        isTGE: false,
      },
      {
        title: 'SGEM',
        token: 0,
        claimableToken: 0,
        source: 'sgem',
        isTGE: false,
      },
    ]);
    const successAlert = ref('');
    const connected = ref(false);
    const totalToken = ref(0);
    const store = useStore();
    const matic = ref(0);
    const sgem = ref(0.0);
    const pym = ref(0);
    const sgemClaimableTime = ref('');
    const sgemClaimable = ref(false);
    const sgemReclaimBool = ref(false);

    // count down
    const distance = ref(0);
    const days = ref('');
    const hours = ref('');
    const minutes = ref('');
    const seconds = ref('');

    const getMatic = async () => {
      const signer = await ethersProvider.getSigner();
      const matic = await signer.getBalance();
      const readableMatic = new Fraction(JSBI.BigInt(matic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
      return readableMatic;
    };

    onMounted(async () => {
      await connectWallet();
      totalToken.value += (await getSgem()) || 0;
      matic.value = await getMatic();
      countDown();
    });

    const getSgem = async () => {
      try {
        const res = await userService.getUserSgem();
        if (res.data.status) {
          const sgem = res.data.data.sgem_tokens;
          const claimableTimeStamp = res.data.data.sgem_claimable_timestamp;
          const dbNonce = res.data.data.nonce;
          const blockchainSgemClaimableTokens = await sgemClaimableValidator(dbNonce);
          console.log(blockchainSgemClaimableTokens);
          sgemClaimableTime.value = blockchainSgemClaimableTokens.success ? blockchainSgemClaimableTokens.amount : new Date(claimableTimeStamp);
          sgemClaimable.value = blockchainSgemClaimableTokens.success ? blockchainSgemClaimableTokens.amount : claimableTimeStamp < new Date().getTime();
          sgemReclaimBool.value = blockchainSgemClaimableTokens.success ? true : false;
          const calculatedSgem = BigNumber.from(blockchainSgemClaimableTokens.amount).add(sgem);
          claimableTokens.value[3].token = new Fraction(JSBI.BigInt(calculatedSgem)).toSignificant(8, {}, 1);
          claimableTokens.value[3].claimableToken = blockchainSgemClaimableTokens.success ? new Fraction(JSBI.BigInt(blockchainSgemClaimableTokens.amount)).toSignificant(8, {}, 1) : claimableTokens.value[3].token;
          return sgem;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const sgemClaimableValidator = async (dbNonce) => {
      const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();
      const userNetwork = await signer.getChainId();
      const sgemManager = new ethers.Contract(userNetwork === 137 ? '0x5153167E9816Fe0072703348AC06761ae73F74f1' : '0x8c2e4E093DA54f2F2aD27F266FBf734Af0c997FB', SpaceGemManager.abi, signer);
      const blockchainNonceBigNumber = await sgemManager.getUserNonce(userAddress);
      if (blockchainNonceBigNumber.lt(dbNonce.toString())) {
        const response = await checkSgemNonce();
        if (response.status) {
          const { sgem_amount } = response.data;
          return {
            success: true,
            amount: BigNumber.from(sgem_amount).div(ethers.utils.parseUnits('1', 18)).toString(), //new Fraction(JSBI.BigInt(sgem_amount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1),
          };
        }
      }
      return {
        success: false,
        amount: 0,
      };
    };

    const calculatePending = (rewardClaimed, allocatedAmount, tgePercentage, claimedDay, startTime, endTime, distributionDay) => {
      if (rewardClaimed.eq(allocatedAmount)) return 0;
      let currentTimestamp = new Date().getTime() / 1000;
      if (currentTimestamp > endTime) {
        return allocatedAmount.sub(rewardClaimed);
      }

      const currentTimestampDay = Math.floor((currentTimestamp - startTime) / 86400);
      if (currentTimestampDay - claimedDay == 0) return 0;

      const tgeAmount = allocatedAmount.mul(tgePercentage).div(100);
      const vestedAmount = allocatedAmount.sub(tgeAmount).mul(currentTimestampDay).div(distributionDay);
      return vestedAmount.add(tgeAmount).sub(rewardClaimed);
    };

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.log('No Metamask');
        this.noMetaMaskAlert = 'No Metamask';

        return false;
      }
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();

      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await changeNetwork();
          await store.dispatch('loading/setLoading', false);
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });
          connected.value = true;

          const userAddress = await signer.getAddress();

          const playermonSeedSale = new ethers.Contract(userNetwork === 137 ? '0x5Ebf06348522209B16057E384562888b7aA6c017' : '0x296DeA213be2C5df71Dde692DE06bE73681E7dB5', PlayermonPrivateSaleClaim.abi, signer);
          const playermonPrivate1Sale = new ethers.Contract(userNetwork === 137 ? '0xFc3250A6439Eb0D9942a222204A302434C6B40A0' : '0x296DeA213be2C5df71Dde692DE06bE73681E7dB5', PlayermonPrivateSaleClaim.abi, signer);
          const playermonPrivate2Sale = new ethers.Contract(userNetwork === 137 ? '0x483b07430542BE643780c82fAEAcEeeb5cd27B52' : '0x296DeA213be2C5df71Dde692DE06bE73681E7dB5', PlayermonPrivateSaleClaim.abi, signer);
          const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
          const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
          pym.value = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
          const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
          sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          // console.log('userPYMBalanceAmount: ', userPYMBalanceAmount);

          let seedPYMAllocated = 0;
          let private1PYMAllocated = 0;
          let private2PYMAllocated = 0;
          // Total Allocated
          const seedPYMTotalAllocated = await playermonSeedSale.allocatedAmount(userAddress);
          if (seedPYMTotalAllocated.gt(0)) {
            console.log('seedPYMTotalAllocated: ', ethers.utils.formatEther(seedPYMTotalAllocated));
            // Reward claimed
            const seedPYMRewardClaimed = await playermonSeedSale.rewardClaimed(userAddress);
            console.log('seedPYMClaimed: ', ethers.utils.formatEther(seedPYMRewardClaimed));
            // Balance
            seedPYMAllocated = BigNumber.from(seedPYMTotalAllocated).sub(seedPYMRewardClaimed);
            console.log('seedPYMRewardAllocated: ', seedPYMAllocated);
            const seedPYMclaimedTGE = await playermonSeedSale.claimedTGE(userAddress);
            claimableTokens.value[0].isTGE = seedPYMclaimedTGE;

            if (!seedPYMclaimedTGE) {
              const seedClaimable = new Fraction(JSBI.BigInt(ethers.BigNumber.from(seedPYMAllocated).div(100).mul(5)), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
              claimableTokens.value[0].claimableToken = seedClaimable;
              console.log('seedPYMClaimable: ', seedClaimable);
            } else {
              // Pending
              const seedClaimedDay = await playermonSeedSale.claimedDay(userAddress);
              console.log('seedClaimedDay: ', seedClaimedDay);
              const seedPYMPending = calculatePending(seedPYMRewardClaimed, seedPYMTotalAllocated, 5, seedClaimedDay, 1637551800, 1669087800, 365);
              console.log('seedPYMPending: ', ethers.utils.formatEther(seedPYMPending));
              claimableTokens.value[0].claimableToken = new Fraction(JSBI.BigInt(seedPYMPending), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
              console.log('seedPYMClaimable: ', seedPYMPending);
            }
            claimableTokens.value[0].token = new Fraction(JSBI.BigInt(seedPYMAllocated), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
          }

          const private1PYMTotalAllocated = await playermonPrivate1Sale.allocatedAmount(userAddress);
          console.log('private1PYMTotalAllocated: ', ethers.utils.formatEther(private1PYMTotalAllocated));
          if (private1PYMTotalAllocated.gt(0)) {
            const private1PYMRewardClaimed = await playermonPrivate1Sale.rewardClaimed(userAddress);
            console.log('private1PYMClaimed: ', ethers.utils.formatEther(private1PYMRewardClaimed));
            private1PYMAllocated = BigNumber.from(private1PYMTotalAllocated).sub(private1PYMRewardClaimed);
            console.log('private1PYMRewardAllocated: ', private1PYMAllocated);
            const private1PYMclaimedTGE = await playermonPrivate1Sale.claimedTGE(userAddress);
            claimableTokens.value[1].isTGE = private1PYMclaimedTGE;

            if (!private1PYMclaimedTGE) {
              const p1Claimable = new Fraction(JSBI.BigInt(ethers.BigNumber.from(private1PYMAllocated).div(100).mul(10)), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
              claimableTokens.value[1].claimableToken = p1Claimable;
              console.log('seedPYMClaimable: ', p1Claimable);
            } else {
              // Pending
              const private1ClaimedDay = await playermonPrivate1Sale.claimedDay(userAddress);
              console.log('private1ClaimedDay: ', private1ClaimedDay);
              const private1PYMPending = calculatePending(private1PYMRewardClaimed, private1PYMTotalAllocated, 10, private1ClaimedDay, 1637551800, 1663817400, 304);
              console.log('private1PYMPending: ', ethers.utils.formatEther(private1PYMPending));
              claimableTokens.value[1].claimableToken = new Fraction(JSBI.BigInt(private1PYMPending), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
              console.log('private1PYMClaimable: ', private1PYMPending);
            }
            claimableTokens.value[1].token = new Fraction(JSBI.BigInt(private1PYMAllocated), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
          }

          const private2PYMTotalAllocated = await playermonPrivate2Sale.allocatedAmount(userAddress);
          console.log('private2PYMTotalAllocated: ', ethers.utils.formatEther(private2PYMTotalAllocated));
          if (private2PYMTotalAllocated.gt(0)) {
            const private2PYMRewardClaimed = await playermonPrivate2Sale.rewardClaimed(userAddress);
            console.log('private2PYMClaimed: ', ethers.utils.formatEther(private2PYMRewardClaimed));
            private2PYMAllocated = BigNumber.from(private2PYMTotalAllocated).sub(private2PYMRewardClaimed);
            console.log('private2PYMRewardAllocated: ', private2PYMAllocated);
            const private2PYMclaimedTGE = await playermonPrivate2Sale.claimedTGE(userAddress);
            claimableTokens.value[2].isTGE = private2PYMclaimedTGE;

            if (!private2PYMclaimedTGE) {
              const p2Claimable = new Fraction(JSBI.BigInt(ethers.BigNumber.from(private2PYMAllocated).div(100).mul(15)), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
              claimableTokens.value[2].claimableToken = p2Claimable;
              console.log('seedPYMClaimable: ', p2Claimable);
            } else {
              // Pending
              const private2ClaimedDay = await playermonPrivate2Sale.claimedDay(userAddress);
              console.log('private2ClaimedDay: ', private2ClaimedDay);
              const private2PYMPending = calculatePending(private2PYMRewardClaimed, private2PYMTotalAllocated, 15, private2ClaimedDay, 1637551800, 1658460600, 242);
              console.log('private2PYMPending: ', ethers.utils.formatEther(private2PYMPending));
              claimableTokens.value[2].claimableToken = new Fraction(JSBI.BigInt(private2PYMPending), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
              console.log('private2PYMClaimable: ', private2PYMPending);
            }
            claimableTokens.value[2].token = new Fraction(JSBI.BigInt(private2PYMAllocated), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
          }

          totalToken.value = totalToken.value + seedPYMAllocated + private1PYMAllocated + private2PYMAllocated;

          await store.dispatch('loading/setLoading', false);
        }
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        connected.value = false;
        console.log('Metamask', e);
        // this.noMetaMaskAlert = 'Please unlock and sign the message on your MetaMask';
      }
    };

    const claimButton = async (item) => {
      console.log(item);

      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();

      const playermonSeedSale = new ethers.Contract(userNetwork ? '0x5Ebf06348522209B16057E384562888b7aA6c017' : '0x296DeA213be2C5df71Dde692DE06bE73681E7dB5', PlayermonPrivateSaleClaim.abi, signer);
      const playermonPrivate1Sale = new ethers.Contract(userNetwork ? '0xFc3250A6439Eb0D9942a222204A302434C6B40A0' : '0x296DeA213be2C5df71Dde692DE06bE73681E7dB5', PlayermonPrivateSaleClaim.abi, signer);
      const playermonPrivate2Sale = new ethers.Contract(userNetwork ? '0x483b07430542BE643780c82fAEAcEeeb5cd27B52' : '0x296DeA213be2C5df71Dde692DE06bE73681E7dB5', PlayermonPrivateSaleClaim.abi, signer);

      try {
        if (item.source == 'seed') {
          if (!item.isTGE) {
            const tx = await playermonSeedSale.claimTGE();
            const receipt = await tx.wait();
            console.log(receipt.status);

            successAlert.value = `Broadcasted: ${tx.hash}`;
          } else {
            const tx = await playermonSeedSale.claim();
            const receipt = await tx.wait();
            console.log(receipt.status);

            successAlert.value = `Broadcasted: ${tx.hash}`;
          }
        } else if (item.source == 'p1') {
          if (!item.isTGE) {
            const tx = await playermonPrivate1Sale.claimTGE();
            const receipt = await tx.wait();
            console.log(receipt.status);

            successAlert.value = `Broadcasted: ${tx.hash}`;
          } else {
            const tx = await playermonPrivate1Sale.claim();
            const receipt = await tx.wait();
            console.log(receipt.status);

            successAlert.value = `Broadcasted: ${tx.hash}`;
          }
        } else if (item.source == 'p2') {
          if (!item.isTGE) {
            const tx = await playermonPrivate2Sale.claimTGE();
            const receipt = await tx.wait();
            console.log(receipt.status);

            successAlert.value = `Broadcasted: ${tx.hash}`;
          } else {
            const tx = await playermonPrivate2Sale.claim();
            const receipt = await tx.wait();
            console.log(receipt.status);

            successAlert.value = `Broadcasted: ${tx.hash}`;
          }
        } else if (item.source == 'sgem') {
          await sgemClaiming(signer);
        }
      } catch (err) {
        store.dispatch('globalAlert/show', { type: 'alert', message: err?.message });
        console.error(err);
      }
    };

    const sgemClaiming = async (signer) => {
      let response;
      if (sgemReclaimBool.value) {
        response = await checkSgemNonce();
      } else {
        response = await claimSgem();
      }
      if (response.status) {
        sgemReclaimBool.value = true;
        const userNetwork = await signer.getChainId();
        const sgemManager = new ethers.Contract(userNetwork === 137 ? '0x5153167E9816Fe0072703348AC06761ae73F74f1' : '0x8c2e4E093DA54f2F2aD27F266FBf734Af0c997FB', SpaceGemManager.abi, signer);
        const { address, sgem_amount, nonce, split_sig } = response.data;
        const tx = await sgemManager.claimSGEM(address, nonce, sgem_amount.toString(), split_sig.v, split_sig.r, split_sig.s);
        if (tx) {
          await getSgem();
          totalToken.value += (await getSgem()) || 0;
          successAlert.value = tx.hash;
        }
      } else {
        throw response?.message;
      }
    };

    const changeNetwork = async () => {
      const provider = window.ethereum;
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          connectWallet();
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    };

    const countDown = () => {
      const now = new Date().getTime();
      setTimeout(() => {
        const calDistance = sgemClaimableTime.value - now;
        if (calDistance > 0) {
          if (distance.value < 0) {
            days.value = '00';
            hours.value = '00';
            minutes.value = '00';
            seconds.value = '00';
          } else {
            // Time calculations for days, hours, minutes and seconds
            const calDays = Math.floor(calDistance / (1000 * 60 * 60 * 24));
            const calHours = Math.floor((calDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const calMinutes = Math.floor((calDistance % (1000 * 60 * 60)) / (1000 * 60));
            const calSeconds = Math.floor((calDistance % (1000 * 60)) / 1000);
            days.value = ('0' + calDays).slice(-2);
            hours.value = ('0' + calHours).slice(-2);
            minutes.value = ('0' + calMinutes).slice(-2);
            seconds.value = ('0' + calSeconds).slice(-2);
            distance.value = calDistance;
            countDown();
          }
        }
      }, 1000);
    };

    return {
      connectWallet,
      changeNetwork,
      claimableTokens,
      claimButton,
      sgemClaimable,
      sgemClaimableTime,
      successAlert,
      connected,
      totalToken,
      matic,
      sgem,
      pym,
      // count down
      days,
      hours,
      minutes,
      seconds,
      distance,
    };
  },
  data: () => ({
    // 80001,
    modalValue: {},
    noMetaMaskAlert: '',
  }),
  methods: {
    setModalValue(value) {
      this.modalValue = value;
    },
  },
};
</script>

<style scoped>
.token-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border: 1px solid #ffffff26;
  border-radius: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
}

@media screen and (max-width: 568px) {
  .token-container {
    margin-right: 0;
    width: 260px;
  }
}

.claim-button {
  width: 120px;
  padding: 8px;
  border: 1px solid #ffffff26;
  border-radius: 4px;
  cursor: pointer;
}

.claim-button:hover {
  border-color: #ffffff50;
}

.modal-content,
.modal-body {
  background-color: var(--background-color);
  border-radius: 16px;
}

.connect-wallet {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ffffff26;
  border-radius: 8px;
}

.modal {
  z-index: 9999;
}
</style>
