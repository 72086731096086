<template>
  <div>
    <h2>Unauthorised!</h2>
    <div class="unauthorise-description">
      <span>You are not authorised to perform this action.</span>
    </div>
    <button data-bs-dismiss="modal" type="button" class="btn btn-outline-secondary">Got it</button>
  </div>
</template>

<style scoped>
.unauthorise-description {
  font-size: 16px;
  color: crimson;
  margin: 24px 0;
  font-weight: 400;
}
</style>
