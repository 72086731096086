<template>
  <loading />
  <router-view />
  <global-alert />
</template>

<script>
import GlobalAlert from './modules/global-alert/views/GlobalAlert.vue';
import Loading from './views/Loading.vue';
export default {
  components: { Loading, GlobalAlert },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import './variable.css';

a {
  text-decoration: none;
}

#app {
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

.sys-container {
  color: whitesmoke;
  background-image: url('./assets/backgrounds/main_bg.png');
  background-size: cover;
  height: 100%;
  padding-top: 81px;
  padding-bottom: var(--global-padding-verticaL-value);
  padding-left: var(--global-padding-horizontal-value);
  padding-right: var(--global-padding-horizontal-value);
  margin-top: -61px;
  overflow: auto;
}

.custom-container {
  color: whitesmoke;
  background-image: url('./assets/backgrounds/main_bg.png');
  background-size: cover;
  background-color: var(--background-color);
  height: 100%;
  padding-top: 61px;
  padding-bottom: var(--global-padding-verticaL-value);
  margin-top: -61px;
  overflow: auto;
  font-size: 18px;
}

.card {
  background-color: #1e083065;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track-piece {
  margin-top: 61px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (min-width: 768px) {
  .sys-container {
    margin-left: var(--global-margin-left);
    transition: 0.5s;
  }
}

.modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
}
</style>
