<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header" />
          <mdicon class="close icon" name="close" @click="$emit('close')" />
        </div>

        <div class="modal-body">
          <slot name="body" />
        </div>

        <div v-if="footer" class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Craft modal',
  props: ['footer'],
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  z-index: 9998;
  width: 60%;
  min-width: 300px;
  max-width: 576px;
  max-height: 75vh;
  overflow-y: scroll;
  margin: 0px auto;
  padding: 24px;
  background: var(--main-background-color);
  border: 2px solid #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-header {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  font-size: 1.25rem;
  font-weight: bold;
  color: whitesmoke;
}

.close {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-footer {
  border: none;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-track-piece {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
