import AuthService from '../services/authService';
import { USER_TOKEN } from '../services/authService';

const user = JSON.parse(localStorage.getItem(USER_TOKEN));
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.jwt = user.jwt;
      state.username = user.username;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    getQrTokenSuccess(state, qrToken) {
      state.qrToken = qrToken;
    },
    getQrTokenFailure(state) {
      state.qrToken = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const response = await AuthService.login(payload);
        if (response) {
          commit('loginSuccess', response);
        }
      } catch (e) {
        console.error(e);
        commit('loginFailure');
      }
    },
    async getQrToken({ commit }) {
      try {
        const response = await AuthService.getQrToken();
        if (response.data.status) {
          commit('getQrTokenSuccess', response.data.data.qr_token);
        }
      } catch (e) {
        console.error(e);
        commit('getQrTokenFailure');
      }
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    async reGenQrToken({ commit }) {
      try {
        const response = await AuthService.reGenQrToken();
        if (response.data.status) {
          commit('getQrTokenSuccess', response.data.data.qr_token);
        }
      } catch (e) {
        console.error(e);
        commit('getQrTokenFailure');
      }
    },
  },
};
