<template>
  <div class="alert-container" :class="isShow ? 'show' : 'hide'">
    <div v-if="type === 'success'" class="alert alert-success d-flex" role="alert">
      <div class="message">{{ message }}</div>
      <div class="button" @click="dismiss"><mdicon name="close" /></div>
    </div>
    <div v-if="type === 'alert'" class="alert alert-danger d-flex" role="alert">
      <div class="message">{{ message }}</div>
      <div class="button" @click="dismiss"><mdicon name="close" /></div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    const type = computed(() => store.state.globalAlert.type);
    const message = computed(() => store.state.globalAlert.message);
    const isShow = computed(() => store.state.globalAlert.show);

    const dismiss = () => {
      store.dispatch('globalAlert/dismiss');
    };

    return { message, isShow, type, dismiss };
  },
};
</script>

<style scoped>
.alert-container {
  position: absolute;
  max-width: 320px;
  top: 50px;
  right: 10px;
  z-index: 9999;
}

.alert-container.show {
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.alert-container.hide {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.alert .message {
  padding-top: 3px;
  text-align: left;
  padding-right: 10px;
  word-break: break-all;
}

.alert .button {
  cursor: pointer;
}
</style>
