<template>
  <div style="max-width: 576px; padding: 24px; border: 1px solid #ffffff65; border-radius: 16px">
    <img src="../../../assets/breeding_pod.png" width="150" />
    <h5 style="margin-top: 32px; font-weight: bold">Breeding pod</h5>
    <div style="color: #ffffff95; margin-top: 16px">
      <span>You're require </span>
      <span style="font-weight: bold; color: whitesmoke">{{ shardCost }} Breeding Pod Shards</span>
      <span> and </span>
      <span style="font-weight: bold; color: whitesmoke">{{ pymCost }} PYM</span>
      <span> to generate this item.</span>
    </div>
    <button class="btn btn-outline-primary" style="width: 100%; margin-top: 16px; padding: 16px" @click="craft">Craft</button>
  </div>
  <breeding-pod-shard-craft-modal :showModal="showModal" @close="close" :pymBalance="pymBalance" :pymCost="pymCost" :shardCost="shardCost" />
</template>

<script>
import { ref } from '@vue/reactivity';
import BreedingPodShardCraftModal from './BreedingPodShardCraftModal.vue';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
import { ethers } from 'ethers';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';
import PlayermonCrafting from '../../../assets/abi/PlayermonBreedingPodCrafting.json';

export default {
  components: { BreedingPodShardCraftModal },
  name: 'Craft breeding pod',
  props: ['pymBalance'],
  setup() {
    const showModal = ref(false);
    const pymCost = ref(0);
    const shardCost = ref(0);
    const store = useStore();

    onMounted(async () => {
      await connectWallet();
    });

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.error('No Metamask');
        return false;
      }
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ethersProvider.getSigner();
      try {
        await provider.request({
          method: 'eth_requestAccounts',
        });
        // const userAddress = await signer.getAddress();
        const userNetwork = await signer.getChainId();

        const breedingPodCraftingContract = new ethers.Contract(userNetwork === 137 ? '0xE92ea35306DbDCBADE8Cc4032389597b1f132ecA' : '0xDf4c1683f6a31979F221Cbc51234a1F62EF0D27b', PlayermonCrafting.abi, signer);
        const rawPymCost = await breedingPodCraftingContract._pymCost();
        const rawShardCost = await breedingPodCraftingContract._shardCost();
        pymCost.value = new Fraction(JSBI.BigInt(rawPymCost), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        shardCost.value = new Fraction(JSBI.BigInt(rawShardCost), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error('Metamask', e);
      }
    };

    const close = () => (showModal.value = false);
    const craft = () => (showModal.value = true);

    return { showModal, pymCost, shardCost, close, craft };
  },
};
</script>
