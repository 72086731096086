<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div style="position: absolute; right: 5%">
            <span data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="cursor: pointer; color: white"><mdicon name="close" /></span>
            </span>
          </div>
          <div style="position: relative">
            <div class="logo">
              <img class="img-fluid" src="../assets/horizontal_logo.png" alt="" style="height: 25px" />
            </div>
            <div class="qrcode-container">
              <qrcode-vue id="qr-code" :value="data" :size="qrCodeWidth" level="H" />
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <a type="button" class="reGenQr" @click="reGenQrToken">Generate New QR Code?</a>
          </div>
          <div class="d-flex button-container">
            <a :href="qrCodeUrl" download type="button" class="btn btn-primary" @click="save">Save</a>
            <button type="button" class="btn btn-primary" @click="copyToClipboard">Copy</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import { useStore } from 'vuex';
import { onBeforeMount, onMounted, ref } from '@vue/runtime-core';

export default {
  components: {
    QrcodeVue,
  },
  setup() {
    const store = useStore();
    const data = ref('');
    const qrCodeUrl = ref('');
    const qrCodeWidth = ref(300);

    onMounted(() => {
      qrCodeWidth.value = window.innerWidth < 321 ? window.innerWidth - 48 : 300;
    });

    const reGenQrToken = async () => {
      await store.dispatch('auth/reGenQrToken');
      data.value = store.state.auth.qrToken;
      store.dispatch('globalAlert/show', { type: 'success', message: 'New QR code generated' });
    };

    onBeforeMount(async () => {
      await store.dispatch('auth/getQrToken');
      data.value = store.state.auth.qrToken;
    });

    const save = () => {
      const qrCode = document.getElementById('qr-code');
      qrCodeUrl.value = qrCode.toDataURL('image/png');
    };

    const copyToClipboard = () => {
      if (data.value) {
        navigator.clipboard.writeText(data.value);
        store.dispatch('globalAlert/show', { type: 'success', message: 'Copied to clipboard' });
      } else {
        store.dispatch('globalAlert/show', { type: 'alert', message: `Invalid QR Code: ${data.value}` });
      }
    };

    return { data, qrCodeUrl, save, copyToClipboard, qrCodeWidth, reGenQrToken };
  },
};
</script>

<style scoped>
.modal-content {
  background: var(--main-background-color);
}

.qrcode-container {
  display: flex;
  justify-content: center;
  margin: 30px;
  margin-bottom: 16px;
  background: white;
  padding: 16px;
}

@media screen and (max-width: 420px) {
  .modal-dialog {
    margin: 0;
  }

  .modal-body {
    margin: 30px 0;
    padding: 0;
  }

  .modal-content {
    width: 100%;
    height: 100%;
  }
  .qrcode-container {
    margin: 0;
    margin-top: 30px;
  }
}

.modal-dialog {
  max-width: 450px;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.button-container {
  justify-content: center;
  gap: 16px;
  margin: 16px 0;
}

.btn-primary {
  padding: 8px 32px;
  background: var(--sub-background-color);
}

.reGenQr {
  color: whitesmoke;
  border-bottom: 1px solid whitesmoke;
}
</style>
