<template>
  <marketplace-nav-bar :matic="matic" :pym="pym" :sgem="sgem" />
  <div class="sys-container">
    <div class="header">
      <h4>Select an item to craft</h4>
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{{ currentTab }}</button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li v-for="item in tabs" :key="item.name">
            <a class="dropdown-item" @click="setTab(item.name)">
              <img v-if="item.img" :src="item.img" height="20" style="margin-right: 8px" />
              <span>{{ item.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="body">
      <craft-breeding-pod v-if="currentTab === 'Breeding Pod'" :pymBalance="pym" />
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import ERC20 from '../../../assets/abi/ERC20.json';
import MarketplaceNavBar from '../../../components/MarketplaceNavBar.vue';
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';
import CraftBreedingPod from './CraftBreedingPod.vue';
export default {
  components: { MarketplaceNavBar, CraftBreedingPod },
  data: () => ({
    tabs: [{ name: '... Select items ...' }, { name: 'Breeding Pod', img: require('../../../assets/breeding_pod.png') }],
  }),
  setup() {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    const store = useStore();

    const pym = ref(0);
    const sgem = ref(0);
    const matic = ref(0);

    const currentTab = ref('... Select items ...');

    onMounted(async () => {
      await connectWallet();
    });

    const setTab = (value) => (currentTab.value = value);

    const getMatic = async () => {
      await store.dispatch('loading/setLoading', true);
      const signer = await ethersProvider.getSigner();
      const matic = await signer.getBalance();
      const readableMatic = new Fraction(JSBI.BigInt(matic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
      await store.dispatch('loading/setLoading', false);
      return readableMatic;
    };

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.log('No Metamask');
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await changeNetwork();
          await store.dispatch('loading/setLoading', false);
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });
          const userAddress = await signer.getAddress();

          const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
          const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
          matic.value = await getMatic();

          const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
          const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
          sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          const readablePYM = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          pym.value = readablePYM;
        }
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.log('Metamask', e);
      }
    };

    const changeNetwork = async () => {
      const provider = window.ethereum;
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(137).toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    };

    return { pym, sgem, matic, currentTab, setTab };
  },
};
</script>

<style scoped>
.dropdown {
  width: 240px;
  margin: auto;
  margin-top: 32px;
}

.dropdown-toggle {
  display: flex;
  width: 240px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: none;
  background-color: #3a3f50;
  border: none;
}

.dropdown-menu {
  width: 240px;
  min-width: 240px;
  max-height: 0;
  background-color: #3a3f50;
  top: 0;
  display: block;
  overflow: hidden;
  padding: 0;
  transform: translate(0px, 38px);
  transition: 0.4s ease;
}

.dropdown-toggle,
.dropdown-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: rgb(206, 193, 193);
  text-transform: capitalize;
}

.dropdown-item:hover {
  background: #ffffff36;
}

.dropdown-menu.show {
  max-height: 300px;
  transition: 0.4s;
}

.body {
  display: flex;
  justify-content: center;
  margin-top: 64px;
}
</style>
