import axios from 'axios';
import { geneColor } from './playermonDetailsConstant';

const classMapping = (playermonClass) => {
  const lowerCasePlayermonClass = playermonClass.toLowerCase();
  switch (lowerCasePlayermonClass) {
    case 'cosmos':
    case 'constellation':
    case 'pegasus':
    case 'galaxy bear':
    case 'wishing deer':
    case 'ursa':
    case 'wish':
    case 'eclipse':
      return 'Comet';

    case 'wharfe':
    case 'natilus':
    case 'nautilus':
    case 'razortooth':
    case 'kraken':
    case 'clamhorn':
    case 'seahorse':
    case 'umi':
      return 'Aqua';

    case 'gaia':
    case 'petunia':
    case 'moonmoth':
    case 'crawleaf':
    case 'venus':
    case 'venus flytrap':
    case 'ivy':
      return 'Nature';

    case 'igneous':
    case 'dynamite':
    case 'wick':
    case 'phoenix':
    case 'hollow':
    case 'cinders':
    case 'jack o lantern':
    case 'soul reaper':
      return 'Inferno';

    case 'artificer':
    case 'developer':
    case 'the inventor':
    case 'artillery':
    case 'tank':
    case 'turbo jet':
    case 'bronze dive':
    case 'ingenium':
    case 'phantacle':
    case 'clockwork':
      return 'Mech';

    case 'juggler':
    case 'ankh':
    case 'anubis':
    case 'hermes':
    case 'shinu':
    case 'doktha':
    case 'glitter':
    case 'lion dance':
    case 'unicorn':
    case 'golden glory':
      return 'Mythos';

    default:
      return null;
  }
};

const generateBodyParts = (dominant, r1, r2, part) => ({
  value: dominant.split(' -')[0],
  color: geneColor[classMapping(dominant.split(' -')[0])],
  image: require(`../../../assets/playermon/${classMapping(dominant.split(' -')[0])}_${part}.png`),
  r1: r1 ? r1.split(' -')[0] : '',
  r1Color: r1 ? geneColor[classMapping(r1.split(' -')[0])] : '',
  r2: r2 ? r2.split(' -')[0] : '',
  r2Color: r2 ? geneColor[classMapping(r2.split(' -')[0])] : '',
});

export const playermonMetadata = async (id) => {
  const metadata = await axios.get(`https://metadata.playermon.com/playermon/${id}`);
  const image = metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
  if (metadata.data.attributes) {
    const pClass = metadata.data.attributes.find((attribute) => attribute.trait_type === 'class')?.value;
    const rarity = metadata.data.attributes.find((attribute) => attribute.trait_type === 'rarity')?.value;
    const icon = pClass !== 'Egg' ? require(`../../../assets/${pClass}.png`) : '';
    const bodyParts = [];

    const rawHead = metadata.data.attributes.find((attribute) => attribute.trait_type === 'head')?.value;
    const rawEye = metadata.data.attributes.find((attribute) => attribute.trait_type === 'eye')?.value;
    const rawArm = metadata.data.attributes.find((attribute) => attribute.trait_type === 'arm' || attribute.trait_type === 'Whip')?.value;
    const rawLeg = metadata.data.attributes.find((attribute) => attribute.trait_type === 'leg' || attribute.trait_type === 'Stomper')?.value;
    const rawBody = metadata.data.attributes.find((attribute) => attribute.trait_type === 'body' || attribute.trait_type === 'Mantle')?.value;
    const rawTail = metadata.data.attributes.find((attribute) => attribute.trait_type === 'tail' || attribute.trait_type === 'Blessing')?.value;
    const rawChest = metadata.data.attributes.find((attribute) => attribute.trait_type === 'chest' || attribute.trait_type === 'Core')?.value;

    const rawHeadR1 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'head_r1')?.value;
    const rawEyeR1 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'eye_r1')?.value;
    const rawArmR1 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'arm_r1')?.value;
    const rawLegR1 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'leg_r1')?.value;
    const rawBodyR1 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'body_r1')?.value;
    const rawTailR1 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'tail_r1')?.value;
    const rawChestR1 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'chest_r1')?.value;

    const rawHeadR2 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'head_r2')?.value;
    const rawEyeR2 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'eye_r2')?.value;
    const rawArmR2 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'arm_r2')?.value;
    const rawLegR2 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'leg_r2')?.value;
    const rawBodyR2 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'body_r2')?.value;
    const rawTailR2 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'tail_r2')?.value;
    const rawChestR2 = metadata.data.attributes.find((attribute) => attribute.trait_type === 'chest_r2')?.value;

    if (rawHead && rawEye && rawArm && rawLeg && rawBody && rawTail && rawChest) {
      bodyParts.push(generateBodyParts(rawHead, rawHeadR1, rawHeadR2, 'Head'));
      bodyParts.push(generateBodyParts(rawEye, rawEyeR1, rawEyeR2, 'Eyes'));
      bodyParts.push(generateBodyParts(rawArm, rawArmR1, rawArmR2, 'Arm'));
      bodyParts.push(generateBodyParts(rawLeg, rawLegR1, rawLegR2, 'Leg'));
      bodyParts.push(generateBodyParts(rawBody, rawBodyR1, rawBodyR2, 'Body'));
      bodyParts.push(generateBodyParts(rawTail, rawTailR1, rawTailR2, 'Tail Object'));
      bodyParts.push(generateBodyParts(rawChest, rawChestR1, rawChestR2, 'Chest'));
    }

    return { image, pClass, rarity, icon, bodyParts };
  }
  return { image };
};
