<template>
  <div class="section column">
    <h4 style="text-align: left">About</h4>
    <div class="box">
      <span style="font-size: 14px; margin-bottom: 8px">Hatch by {{ days }}:{{ hours }}:{{ minutes }}:{{ seconds }} </span>
      <div style="margin-bottom: 16px; display: flex; align-items: center; font-size: 14px">
        <span>EGG</span>
        <div class="progress" style="flex: 1; height: 10px; margin: 0 8px">
          <div class="progress-bar" role="progressbar" :style="`width:${hatchingPercentage}%`" :aria-valuenow="hatchingPercentage" aria-valuemin="0" aria-valuemax="100">{{ hatchingPercentage.toFixed(2) }}%</div>
        </div>
        <span>HATCH</span>
      </div>
      <div class="two-line">
        <span class="label">Owner</span>
        <div class="text-truncate" style="display: block; width: 100%; text-align: left">
          <span>{{ ownerUsername }}</span>
          <span style="font-size: 12px; color: #ffffff50; margin-left: 4px">({{ ownerAddress }})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
export default {
  props: ['hatchingTime', 'ownerUsername', 'ownerAddress'],
  setup(props) {
    const { hatchingTime } = toRefs(props);
    const countDownTime = ref(hatchingTime.value);
    const days = ref('00');
    const hours = ref('00');
    const minutes = ref('00');
    const seconds = ref('00');
    const distance = ref(0);
    const hatchingPercentage = ref(0);

    onMounted(() => {
      countDown();
    });

    const countDown = () => {
      const now = new Date().getTime();
      setTimeout(() => {
        const calDistance = countDownTime.value - now;
        if (calDistance > 0) {
          hatchingPercentage.value = (1 - calDistance / 1036800000) * 100;

          // Time calculations for days, hours, minutes and seconds
          const calDays = Math.floor(calDistance / (1000 * 60 * 60 * 24));
          const calHours = Math.floor((calDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const calMinutes = Math.floor((calDistance % (1000 * 60 * 60)) / (1000 * 60));
          const calSeconds = Math.floor((calDistance % (1000 * 60)) / 1000);
          days.value = ('0' + calDays).slice(-2);
          hours.value = ('0' + calHours).slice(-2);
          minutes.value = ('0' + calMinutes).slice(-2);
          seconds.value = ('0' + calSeconds).slice(-2);
          distance.value = calDistance;
          countDown();
        } else {
          hatchingPercentage.value = 100;
        }
      }, 1000);
    };

    return {
      days,
      hours,
      minutes,
      seconds,
      hatchingPercentage,
    };
  },
};
</script>

<style scoped>
.box {
  padding: 24px;
  border: 1px solid #ffffff75;
  border-radius: 12px;
  background: var(--main-background-color);
}
</style>
