<template>
  <div>
    <div class="row" style="height: 100%; width: 100%; position: relative">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-white align-self-end" align="start" align-self="end" style="z-index: 1">
        <a href="#">
          <img src="../assets/horizontal_logo.png" alt="" style="width: 30vw; margin-bottom: 2vw" />
        </a>
        <div>
          <div style="margin-left: 3vw; display: inline-flex; cursor: pointer" @click="gotosite('https://link.playermon.com/twitter')">
            <img style="width: 32px" src="../assets/social/twitter.png" />
          </div>
          <div style="margin-left: 10px; display: inline-flex; cursor: pointer" @click="gotosite('https://link.playermon.com/discord')">
            <img style="width: 32px" src="../assets/social/discord.png" />
          </div>
          <div style="margin-left: 10px; display: inline-flex; cursor: pointer" @click="gotosite('https://link.playermon.com/telegram')">
            <img style="width: 32px" src="../assets/social/telegram.png" />
          </div>
          <div style="margin-left: 10px; display: inline-flex; cursor: pointer" @click="gotosite('https://link.playermon.com/facebook')">
            <img style="width: 32px" src="../assets/social/facebook.png" />
          </div>
          <div style="margin-left: 10px; display: inline-flex; cursor: pointer" @click="gotosite('https://link.playermon.com/instagram')">
            <img s style="width: 32px" src="../assets/social/instagram.png" />
          </div>
          <div style="margin-left: 10px; display: inline-flex; cursor: pointer" @click="gotosite('https://link.playermon.com/youtube')">
            <img style="width: 32px" src="../assets/social/youtube.png" />
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-white d-flex" style="z-index: 1; flex-direction: column; text-align: end; justify-content: flex-end">
        <div>
          <a href="https://whitepaper.playermon.com/gameplay-1/daily-quest" target="_blank" style="color: white">Tutorial</a>
        </div>
        <div>
          <a href="https://playermon.com/" target="_blank" style="color: white">Term of Use</a>
        </div>
      </div>
    </div>
    <img class="img-fluid" src="../assets/backgrounds/playermon_footer.png" style="width: 100%; margin-top: -43%" />
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  methods: {
    gotosite(url) {
      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      );
    },
  },
  data: function () {
    return {
      backgroundImage: require('../assets/backgrounds/playermon_footer.png'),
      info: '',
    };
  },
};
</script>
