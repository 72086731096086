import { createRouter, createWebHistory } from 'vue-router';
import VueJwtDecode from 'vue-jwt-decode';
import { trackRouter } from 'vue-gtag-next';

import Home from '../views/Home.vue';
// import Private1Sale from '../views/Private1Sale.vue';
// import Private2Sale from '../views/Private2Sale.vue';
// import PreIDOSale from '../views/PreIDOSale.vue';
// import IDOSale from '../views/IDOSale.vue';
// import SeedSale from '../views/SeedSale.vue';
// import EggSale from '../views/EggSale.vue';
// import Roadmap from '../views/Roadmap.vue';
import Team from '../views/Team.vue';
import Tokenomics from '../views/Tokenomics.vue';
import PYMTokenomics from '../views/PYMTokenomics.vue';
import PlayNow from '../views/PlayNow.vue';
import Link from '../views/Link.vue';

/** Marketplace */
import { USER_TOKEN } from '../services/authService';
import Inventory from '../views/Inventory.vue';
import Login from '../views/Login.vue';
import ClaimToken from '../views/ClaimToken.vue';
import PlayermonDetails from '../modules/PlayermonDetails/views/PlayermonDetails.vue';
import Marketplace from '../modules/marketplace/views/Marketplace.vue';
import Dashboard from '../modules/marketplace/views/Dashboard.vue';
import Staking from '../modules/staking/views/Staking.vue';
import Crafting from '../modules/crafting/views/Crafting.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Team',
    name: 'Team',
    component: Team,
  },
  {
    path: '/Tokenomics',
    name: 'Tokenomics',
    component: Tokenomics,
  },
  {
    path: '/PYMTokenomics',
    name: 'PYM Tokenomics',
    component: PYMTokenomics,
  },
  {
    path: '/download',
    name: 'Play Now',
    component: PlayNow,
  },
  // {
  //   path: '/SeedSale',
  //   name: 'SeedSale',
  //   component: SeedSale,
  // },
  // {
  //   path: '/Private1Sale',
  //   name: 'Private1Sale',
  //   component: Private1Sale,
  // },
  // {
  //   path: '/Private2Sale',
  //   name: 'Private2Sale',
  //   component: Private2Sale,
  // },
  // {
  //   path: '/PreIDOSale',
  //   name: 'PreIDOSale',
  //   component: PreIDOSale,
  // },
  // {
  //   path: '/IDOSale',
  //   name: 'IDOSale',
  //   component: IDOSale,
  // },
  // {
  //   path: '/EggSale',
  //   name: 'EggSale',
  //   component: EggSale,
  // },
  /** Marketplace */
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Inventory',
    name: 'Inventory',
    component: Inventory,
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/Playermon',
    name: 'Playermon',
    component: PlayermonDetails,
    props: true,
  },
  {
    path: '/ClaimTokens',
    name: 'ClaimTokens',
    component: ClaimToken,
  },
  {
    path: '/Staking',
    name: 'Staking',
    component: Staking,
  },
  {
    path: '/Crafting',
    name: 'Crafting',
    component: Crafting,
  },
  {
    path: '/links',
    name: 'Link',
    component: Link,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/Team', '/Tokenomics', '/login', '/PYMTokenomics', '/download', '/marketplace', '/dashboard', '/playermon', '/links'];
  const authRequired = !publicPages.some((path) => to.path.match(path));
  const jwt = JSON.parse(localStorage.getItem(USER_TOKEN));

  if (authRequired && jwt) {
    const jwtInfo = VueJwtDecode.decode(jwt);
    const exp = jwtInfo.exp;
    if (new Date(exp * 1000) < new Date()) {
      return next('/login');
    }
  }
  if (authRequired && !jwt) {
    next('/login');
  } else {
    next();
  }
});

trackRouter(router);

export default router;
