import moment from 'moment';

export const getDateFromTo = (duration) => {
  switch (duration) {
    case '24h':
      return {
        dateFrom: moment().subtract(1, 'days').utc().format(),
        dateTo: moment().utc().format(),
      };
    case '7d':
      return {
        dateFrom: moment().subtract(7, 'days').utc().format(),
        dateTo: moment().utc().format(),
      };
    case '30d':
      return {
        dateFrom: moment().subtract(30, 'days').utc().format(),
        dateTo: moment().utc().format(),
      };
    default:
      return {
        dateFrom: undefined,
        dateTo: undefined,
      };
  }
};
