<template>
  <div class="sidebar-filter">
    <nav class="navbar nav-background d-flex align-items-start d-flex justify-content-center">
      <ul class="nav nav-pills flex-column mt-4 w-75">
        <li class="nav-item d-flex justify-content-end"><button type="button" class="btn-close text-reset btn-close-white d-block d-lg-none my-4" data-bs-dismiss="offcanvas" aria-label="Close" /></li>

        <li class="nav-item d-none d-xxl-block">
          <div class="d-flex justify-content-between mb-3">
            <h6 class="mt-1">Filter ({{ filtersCount }})</h6>
            <a @click="clearFilters" href="" class="text-decoration-underline pe-auto link-light fs-6">Clear filters</a>
          </div>
        </li>

        <li class="nav-item d-none d-xxl-block">
          <div class="btn-group btn-group-lg rounded-pill nav nav-pills d-flex flex-nowrap" role="tablist">
            <button class="btn btn-outline-light" id="pills-general-tab" data-bs-toggle="pill" data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general" aria-selected="true">General</button>
            <button class="btn btn-outline-light" id="pills-parts-tab" data-bs-toggle="pill" data-bs-target="#pills-parts" type="button" role="tab" aria-controls="pills-parts" aria-selected="false">Parts</button>
            <button class="btn btn-outline-light" id="pills-stats-tab" data-bs-toggle="pill" data-bs-target="#pills-stats" type="button" role="tab" aria-controls="pills-stats" aria-selected="false">Stats</button>
          </div>
        </li>

        <li class="nav-item d-none d-xxl-block">
          <div class="tab-content">
            <div class="tab-pane show active" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
              <li class="nav-item mt-4">
                <h6 class="text-start text-secondary">Class</h6>

                <div class="row">
                  <div class="col-lg-6 flex-column">
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Inferno" id="infernoChecked" v-model="classes" />
                      <img class="mt-1 me-2" src="../../../../assets/Inferno.png" width="14" height="14 " /><label class="form-check-label" for="infernoChecked"> Inferno </label>
                    </div>

                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Aqua" id="aquaChecked" v-model="classes" />
                      <img class="mt-1 me-2" src="../../../../assets/Aqua.png" width="14" height="14 " /> <label class="form-check-label" for="aquaChecked"> Aqua </label>
                    </div>

                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Nature" id="natureChecked" v-model="classes" />
                      <img class="mt-2 me-2" src="../../../../assets/Nature.png" width="13" height="13 " /><label class="form-check-label" for="natureChecked"> Nature </label>
                    </div>
                  </div>
                  <div class="col-lg-6 flex-column">
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Mythos" id="mythosChecked" v-model="classes" />
                      <img class="mt-1 me-2" src="../../../../assets/Mythos.png" width="14" height="14 " /><label class="form-check-label" for="mythosChecked"> Mythos </label>
                    </div>
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Comet" id="cometChecked" v-model="classes" />
                      <img class="mt-1 me-2" src="../../../../assets/Comet.png" width="14" height="14 " /> <label class="form-check-label" for="cometChecked"> Comet </label>
                    </div>
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Mech" id="mechChecked" v-model="classes" />
                      <img class="mt-1 me-2" src="../../../../assets/Mech.png" width="14" height="14 " /> <label class="form-check-label" for="mechChecked"> Mech </label>
                    </div>
                  </div>
                </div>
              </li>

              <li class="nav-item mt-4">
                <h6 class="text-start text-secondary">Rarity</h6>
                <div class="row">
                  <div class="col-lg-6 d-flex flex-column">
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Common" id="commonChecked" v-model="rarities" />
                      <label class="form-check-label ms-1" for="commonChecked"> Common </label>
                    </div>

                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Uncommon" id="unCommonChecked" v-model="rarities" />
                      <label class="form-check-label ms-1" for="unCommonChecked"> Uncommon </label>
                    </div>

                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Rare" id="rareChecked" v-model="rarities" />
                      <label class="form-check-label ms-1" for="rareChecked"> Rare </label>
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex flex-column">
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Epic" id="epicChecked" v-model="rarities" />
                      <label class="form-check-label ms-1" for="epicChecked"> Epic </label>
                    </div>
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Legendary" id="legendChecked" v-model="rarities" />
                      <label class="form-check-label ms-1" for="legendChecked"> Legendary </label>
                    </div>
                  </div>
                </div>
              </li>

              <li class="nav-item mt-4">
                <h6 class="text-start text-secondary">Stage</h6>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Adult" id="adultChecked" v-model="stages" />
                      <label class="form-check-label" for="adultChecked"> Adult </label>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-check d-flex justify-content-start">
                      <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Egg" id="eggChecked" v-model="stages" />
                      <label class="form-check-label" for="eggChecked"> Egg </label>
                    </div>
                  </div>
                </div>
              </li>
            </div>

            <div class="tab-pane" id="pills-stats" role="tabpanel" aria-labelledby="pills-stats-tab">
              <li class="nav-item mt-4">
                <div class="row">
                  <div class="col-lg-12 flex-column">
                    <div class="mb-4 text-start" v-for="item in stats" :key="item.name">
                      <label class="text-start"> {{ item.name }} </label> <vue-slider v-model="item.value" :enable-cross="false" @drag-end="setStats(item)" @click="setStats(item)" />
                    </div>
                  </div>
                </div>
              </li>
            </div>

            <div class="tab-pane" id="pills-parts" role="tabpanel" aria-labelledby="pills-parts-tab">
              <li class="nav-item mt-4">
                <div class="row">
                  <div class="col-lg-12 flex-column">
                    <div class="input-group">
                      <input class="form-control border-end-0 border" type="search" id="search" v-model="search" placeholder="Search parts" aria-label="search-parts" @keypress.enter="filterSwappableBodyParts(search)" />
                      <span class="btn bg-white border-start-0 border-bottom-0 border ms-n5" type="button" @click="filterSwappableBodyParts(search)">
                        <i class="bi bi-search mt-4"></i>
                      </span>
                    </div>
                    <!-- <input class="form-control mb-3" type="search" id="search" v-model="search" placeholder="Search parts" aria-label="search-parts" @keypress.enter="filterSwappableBodyParts(search)" /> -->
                    <div v-if="resultFilterSwappableBodyParts" class="mb-3">
                      <ul class="list-group list-group-flush bg-transparent text-start" v-for="item in resultFilterSwappableBodyParts.slice(0, 6)" :key="item._id.$oid">
                        <li class="list-group-item text-white bg-transparent text-start" @click="selectSwappableBodyParts(item)">{{ item.name }}</li>
                      </ul>
                    </div>
                    <div class="d-flex flex-wrap">
                      <div class="text-start mt-1" v-for="item in swappableBodyParts" :key="item._id.$oid">
                        <button type="button" class="btn btn-primary text-white mb-2 me-2">{{ item.name }}<span @click="removeSwappableBodyParts(item)" class="bi bi-x-lg ms-2" /></button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </li>

        <li class="nav-item d-block d-xxl-none">
          <div class="d-block d-xxl-none offcanvas-header">
            <div class="d-flex justify-content-between mt-3">
              <h6 class="mt-1">Filter ({{ filtersCount }})</h6>
              <a @click="clearFilters" href="" class="text-decoration-underline pe-auto link-light fs-6">Clear filters</a>
            </div>
          </div>

          <div class="mb-4">
            <h3 class="text-start text-white mb-2">General</h3>
            <h6 class="text-start text-secondary">Class</h6>
            <div class="row mb-4">
              <div class="col-lg-6 flex-column">
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Inferno" id="infernoChecked" v-model="classes" />
                  <img class="mt-1 me-2" src="../../../../assets/Inferno.png" width="14" height="14 " /><label class="form-check-label" for="infernoChecked"> Inferno </label>
                </div>

                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Aqua" id="aquaChecked" v-model="classes" />
                  <img class="mt-1 me-2" src="../../../../assets/Aqua.png" width="14" height="14 " /> <label class="form-check-label" for="aquaChecked"> Aqua </label>
                </div>

                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Nature" id="natureChecked" v-model="classes" />
                  <img class="mt-2 me-2" src="../../../../assets/Nature.png" width="13" height="13 " /><label class="form-check-label" for="natureChecked"> Nature </label>
                </div>
              </div>
              <div class="col-lg-6 flex-column">
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Mythos" id="mythosChecked" v-model="classes" />
                  <img class="mt-1 me-2" src="../../../../assets/Mythos.png" width="14" height="14 " /><label class="form-check-label" for="mythosChecked"> Mythos </label>
                </div>
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Comet" id="cometChecked" v-model="classes" />
                  <img class="mt-1 me-2" src="../../../../assets/Comet.png" width="14" height="14 " /> <label class="form-check-label" for="cometChecked"> Comet </label>
                </div>
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Mech" id="mechChecked" v-model="classes" />
                  <img class="mt-1 me-2" src="../../../../assets/Mech.png" width="14" height="14 " /> <label class="form-check-label" for="mechChecked"> Mech </label>
                </div>
              </div>
            </div>

            <h6 class="text-start text-secondary">Rarity</h6>
            <div class="row mb-4">
              <div class="col-lg-6 flex-column">
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Common" id="commonChecked" v-model="rarities" />
                  <label class="form-check-label" for="commonChecked"> Common </label>
                </div>

                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Uncommon" id="unCommonChecked" v-model="rarities" />
                  <label class="form-check-label" for="unCommonChecked"> Uncommon </label>
                </div>

                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Rare" id="rareChecked" v-model="rarities" />
                  <label class="form-check-label" for="rareChecked"> Rare </label>
                </div>
              </div>
              <div class="col-lg-6 flex-column">
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Epic" id="epicChecked" v-model="rarities" />
                  <label class="form-check-label" for="epicChecked"> Epic </label>
                </div>
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-2" @change="onClick" type="checkbox" value="Legendary" id="legendChecked" v-model="rarities" />
                  <label class="form-check-label" for="legendChecked"> Legendary </label>
                </div>
              </div>
            </div>

            <h6 class="text-start text-secondary">Stage</h6>
            <div class="row mb-4">
              <div class="col-lg-6">
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Adult" id="adultChecked" v-model="stages" />
                  <label class="form-check-label" for="adultChecked"> Adult </label>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-check d-flex justify-content-start">
                  <input class="form-check-input me-3" @change="onClick" type="checkbox" value="Egg" id="eggChecked" v-model="stages" />
                  <label class="form-check-label" for="eggChecked"> Egg </label>
                </div>
              </div>
            </div>
          </div>
          <hr class="bg-white border-2 border-top border-white" />
          <div class="mb-4">
            <h3 class="text-start text-white mb-2">Parts</h3>
            <div class="row">
              <div class="col-lg-12 flex-column">
                <div class="input-group">
                  <input class="form-control border-end-0 border" type="search" id="search" v-model="search" placeholder="Search parts" aria-label="search-parts" @keypress.enter="filterSwappableBodyParts(search)" />
                  <span class="btn bg-white border-start-0 border-bottom-0 border ms-n5" type="button" @click="filterSwappableBodyParts(search)">
                    <i class="bi bi-search mt-4"></i>
                  </span>
                </div>
                <div v-if="resultFilterSwappableBodyParts" class="mb-3">
                  <ul class="list-group list-group-flush bg-transparent text-start" v-for="item in resultFilterSwappableBodyParts.slice(0, 6)" :key="item._id.$oid">
                    <li class="list-group-item text-white bg-transparent text-start" @click="selectSwappableBodyParts(item)">{{ item.name }}</li>
                  </ul>
                </div>
                <div class="d-flex flex-wrap">
                  <div class="text-start mt-1" v-for="item in swappableBodyParts" :key="item._id.$oid">
                    <button type="button" class="btn btn-primary text-white mb-2 me-2">{{ item.name }}<span @click="removeSwappableBodyParts(item)" class="bi bi-x-lg ms-2" /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="bg-white border-2 border-top border-white" />
          <div>
            <h3 class="text-start text-white mb-2">Stats</h3>
            <div class="row mb-4">
              <div class="col-lg-12 flex-column">
                <div class="mb-4 text-start" v-for="item in stats" :key="item.name">
                  <label class="text-start"> {{ item.name }} </label> <vue-slider v-model="item.value" :enable-cross="false" @drag-end="setStats(item)" @click="setStats(item)" />
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useModelWrapper } from '../../../../utils/modelWrapper';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  components: {
    VueSlider,
  },
  props: {
    modelValue: Array,
    rarityCheck: Array,
    stageCheck: Array,
    filtersCount: String,
    filterSwappableBodyParts: Function,
    resultFilterSwappableBodyParts: Array,
    selectSwappableBodyParts: Function,
    swappableBodyParts: Array,
    removeSwappableBodyParts: Function,
    setStats: Function,
  },
  setup(props, { emit }) {
    return {
      classes: useModelWrapper(props, emit, 'modelValue'),
      rarities: useModelWrapper(props, emit, 'rarityCheck'),
      stages: useModelWrapper(props, emit, 'stageCheck'),
    };
  },
  data() {
    return {
      search: '',
      value: [0, 100],
      stats: [
        { name: 'Agility', value: [0, 100] },
        { name: 'Strength', value: [0, 100] },
        { name: 'Intelligence', value: [0, 100] },
        { name: 'Luck', value: [0, 100] },
      ],
      stage: ['Adult', 'Egg'],
    };
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
    clearFilters() {
      this.$emit('clearFilters');
    },
  },
};
</script>

<style scoped>
.nav-background {
  background-color: var(--main-background-color);
  z-index: 99;
  height: 100vh;
}
.form-check-input {
  background-color: transparent;
  border-color: white;
}
</style>
