<template>
  <div class="summaries-table">
    <div class="timeline-option">
      <a :class="duration === '24h' ? 'active' : ''" @click="setDuration('24h')">Last 24h</a>
      <a :class="duration === '7d' ? 'active' : ''" @click="setDuration('7d')">7 days</a>
      <a :class="duration === '30d' ? 'active' : ''" @click="setDuration('30d')">30 days</a>
    </div>
    <div class="summaries">
      <div class="d-flex align-items-center">
        <div style="display: flex; justify-content: center; align-items: center; background: #2bbaa7; height: 42px; width: 42px; border-radius: 50%">
          <mdicon name="chart-areaspline-variant" size="25" />
        </div>
        <div>
          <div class="label">Average Sale</div>
          <div class="value">{{ averageSale }}</div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <img src="../../../../assets/logo.png" width="42" />
        <div>
          <div class="label">Total Volume</div>
          <div class="value">
            {{ totalVolume }}
            <span style="font-size: 16px; color: #ffffff65">${{ totalVolumeInUsd }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div style="display: flex; justify-content: center; align-items: center; background: var(--bs-indigo); height: 42px; width: 42px; border-radius: 50%">
          <img src="../../../../assets/playermon_white.png" width="25" />
        </div>
        <div>
          <div class="label">Playermon Sold</div>
          <div class="value">{{ playermonSold }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import { onMounted, onUpdated } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { getSaleSummaries } from '../../src/marketplaceApi';

export default {
  props: ['usdPerMatic'],
  setup(props) {
    const store = useStore();
    const { usdPerMatic } = toRefs(props);
    const usdPerMaticLocal = ref(1);
    const duration = ref('24h');
    const averageSale = ref(0);
    const totalVolume = ref(0);
    const totalVolumeInUsd = ref(0);
    const playermonSold = ref(0);

    onUpdated(() => {
      assignUsdPerMatic(usdPerMatic.value);
    });

    const assignUsdPerMatic = (value) => {
      usdPerMaticLocal.value = value;
      totalVolumeInUsd.value = (totalVolume.value * usdPerMaticLocal.value).toFixed(2);
    };

    onMounted(async () => {
      await getSaleSummary();
    });

    const getSaleSummary = async () => {
      try {
        await store.dispatch('loading/setLoading', true);
        const response = await getSaleSummaries(duration.value);
        if (response.status) {
          const data = response.data[0];
          const rawAverageSale = data.average_sale;
          const rawTotalVolume = data.total_sale;
          averageSale.value = rawAverageSale.toFixed(2);
          totalVolume.value = rawTotalVolume.toFixed(2);
          totalVolumeInUsd.value = (rawTotalVolume * usdPerMaticLocal.value).toFixed(2);
          playermonSold.value = data.count;
        }
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error(e);
      }
    };

    const setDuration = async (value) => {
      duration.value = value;
      await getSaleSummary();
    };
    return { duration, playermonSold, averageSale, totalVolume, totalVolumeInUsd, setDuration };
  },
};
</script>

<style scoped>
.summaries-table {
  border: 1px solid #ffffff26;
  border-radius: 8px;
  margin: 32px 0;
  background: var(--main-background-color);
}

.timeline-option {
  display: flex;
  gap: 16px;
  padding: 0 24px;
  padding-top: 16px;
  border-bottom: 1px solid #ffffff26;
  font-size: 14px;
}

.active {
  color: white !important;
  border-bottom: 3px solid white;
  padding-bottom: 12px;
}

.timeline-option > a {
  width: 70px;
  color: #ffffff75;
  cursor: pointer;
}

.summaries {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-around;
  padding: 32px;
}

@media screen and (max-width: 768px) {
  .summaries > div {
    width: 100%;
    text-align: left;
  }
}

.summaries > div {
  gap: 16px;
}

.label {
  font-size: 10px;
  color: #ffffff65;
  text-align: left;
  text-transform: uppercase;
}

.value {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
}
</style>
