<template>
  <div class="modal fade" id="sellPlayermonModal" tabindex="-1" role="dialog" aria-labelledby="sellModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <unauthorised-modal v-if="notMatch" />
          <div v-else>
            <div style="display: flex; justify-content: space-between">
              <div style="display: flex">
                <div><mdicon name="tag" width="20" /></div>
                <span style="padding-top: 2px; margin-left: 8px">{{ title || 'Sell Playermon' }}</span>
              </div>
              <span data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="cursor: pointer"><mdicon name="close" /></span>
              </span>
            </div>
            <div class="description">
              <span style="color: whitesmoke; margin-right: 5px">Playermon #{{ id }}</span>
              <span>will be listed on the Marketplace with a fixed price. In order to get it back, you'll have to cancel the sale.</span>
            </div>
            <div class="price">
              <span>Sell at </span>
              <div style="display: flex; align-items: center">
                <span>PYM</span>
                <div><input @input="onChange($event.target.value)" :value="amount" /></div>
              </div>
            </div>
            <div class="fee">
              <span style="max-width: 150px">You'll receive {{ finalAmount }} PYM after subtracting a 5% fee.</span>
            </div>
            <div style="display: flex; height: 16px; justify-content: flex-end">
              <span v-if="amount <= 0" style="color: crimson; text-align: end; font-size: 12px">Must be more than 0 PYM</span>
            </div>
            <div class="form-check disclaim">
              <input class="form-check-input" type="checkbox" :value="disclaim" @click="toggleDisclaim" id="flexCheckChecked" />
              <label class="form-check-label" for="flexCheckChecked">I understand that after successfully sold, action is not invertible.</label>
            </div>
            <div style="margin-bottom: 24px" />

            <a v-if="hash" style="color: green; margin-bottom: 16px; word-break: break-word; cursor: pointer" @click="gotoPolygonScan(hash)">{{ hash }}</a>
            <div v-else-if="errorMsg" style="color: crimson; margin-bottom: 16px; word-break: break-word">{{ errorMsg }}</div>
            <div style="display: flex; justify-content: flex-end">
              <button v-if="disabledWallet" type="button" class="btn btn-outline-primary" @click="disableWallet()" style="border-color: #ffffff80">Disable Wallet for Marketplace V1</button>
              <button v-else-if="enabledWallet" type="button" class="btn btn-outline-primary" @click="sell()" style="border-color: #ffffff80" :disabled="!disclaim">Confirm</button>
              <button v-else-if="!connected" type="button" class="btn btn-outline-primary" @click="connectWallet()" style="border-color: #ffffff80">Connect Wallet</button>
              <button v-else type="button" class="btn btn-outline-primary" @click="enableWallet()" style="border-color: #ffffff80">Enable Wallet</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnauthorisedModal from '@/components/UnauthorisedModal.vue';
import { ethers } from 'ethers';
import { ref, toRefs } from 'vue';
// import { useStore } from 'vuex';
// import ERC20 from '../assets/abi/ERC20.json';
import PlayermonNFTMarketplace from '../../../../assets/abi/PlayermonNFTMarketplace.json';

export default {
  components: {
    UnauthorisedModal,
  },
  props: ['id', 'connected', 'enabledWallet', 'disabledWallet', 'title', 'price'],
  setup(props, context) {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    // const store = useStore();
    const { id } = toRefs(props);
    const amount = ref(100);
    const finalAmount = ref(100 * 0.95);
    const notMatch = ref(false);
    const hash = ref('');
    const errorMsg = ref('');
    const disclaim = ref(false);

    const onChange = (newValue) => {
      amount.value = newValue;
      const amountAfterFee = ethers.utils.parseEther(newValue.toString()).mul(95).div(100);
      finalAmount.value = ethers.utils.formatEther(amountAfterFee);
    };

    const toggleDisclaim = () => {
      disclaim.value = !disclaim.value;
    };

    const connectWallet = () => {
      context.emit('connectWallet');
    };

    const enableWallet = () => {
      context.emit('enabledWallet');
    };

    const disableWallet = () => {
      context.emit('disabledWallet');
    };

    const sell = async () => {
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      if (userNetwork !== 137 && userNetwork !== 80001) {
        await this.changeNetwork();
      } else {
        const playermonNFTMarketplaceV2 = new ethers.Contract(userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2', PlayermonNFTMarketplace.abi, signer);

        try {
          const price = ethers.utils.parseEther(amount.value.toString());
          const tx = await playermonNFTMarketplaceV2.setSalePrice(id.value, price);
          const receipt = await tx.wait();
          console.log(receipt.status);

          hash.value = tx.hash;
          context.emit('refreshPlayermon');
        } catch (err) {
          console.log(err);
          // errorMsg.value = err.data.message;
        }
      }
    };

    const gotoPolygonScan = async (hash) => {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const url = userNetwork === 137 ? `https://polygonscan.com/tx/${hash}` : `https://mumbai.polygonscan.com/tx/${hash}`;
      window.open(url, '_blank');
    };

    return {
      amount,
      notMatch,
      finalAmount,
      hash,
      errorMsg,
      disclaim,
      sell,
      onChange,
      confirm,
      connectWallet,
      enableWallet,
      disableWallet,
      gotoPolygonScan,
      toggleDisclaim,
    };
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 450px;
}

.modal-dialog,
.modal-content,
.modal-body {
  border-radius: 8px;
  background: none;
}

.modal-content {
  padding: 16px;
  background: var(--background-color);
}

.description {
  font-size: 14px;
  color: #ffffff75;
  margin: 24px 0;
  text-align: left;
  font-weight: 300;
}

.price {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
}

input {
  max-width: 80px;
  padding: 4px 8px;
  margin-left: 8px;
  border: 1px solid #ffffff26;
  border-radius: 4px;
  outline: none;
  background: #00000050;
  color: whitesmoke;
}

.fee {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  margin-top: 4px;
}

.disclaim {
  display: flex;
  margin-top: 8px;
  align-items: center;
}

.form-check-label {
  text-align: left;
  font-size: 16px;
  margin-left: 16px;
  padding-top: 8px;
}

button,
button:focus {
  box-shadow: none;
}
</style>
