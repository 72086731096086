<template>
  <div class="d-flex playermon-sold align-items-center" @click="onClick">
    <!-- <div class="card-content d-flex align-items-center justify-content-between"> -->
    <div class="d-flex align-items-center">
      <img :src="playermon.playermon_image" width="150" />
      <div class="vertical-box">
        <div class="token-id" :style="`background: ${playermonClassColor}`">
          <div style="text-align: center"><img class="mb-1" :src="require(`@/assets/${playermonClassImage}`)" width="10" /> #{{ playermon.token_id }}</div>
        </div>
        <!-- <span style="text-align: left">Breed count: {{ playermon.breed_count }}</span> -->
      </div>
    </div>
    <div class="vertical-box">
      <h6>Buyer</h6>
      <div>{{ buyerAddress }}</div>
      <span>({{ buyerAddress }})</span>
    </div>
    <div class="vertical-box">
      <h6>Seller</h6>
      <div>{{ sellerAddress }}</div>
      <span>({{ sellerAddress }})</span>
    </div>
    <div>
      <div style="display: flex; flex-direction: row; align-items: baseline; margin-top: 16px">
        <h2>{{ playermon.price }} PYM</h2>
        <h6 style="margin-left: 4px; color: #ffffff75">(${{ priceInUsd }})</h6>
      </div>
      <h6 style="color: #ffffff85">{{ time }}</h6>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import { onUpdated } from '@vue/runtime-core';
import moment from 'moment';
import { classColorMapper, classImageMapper } from '../../../../helper';
import { useRouter } from 'vue-router';

export default {
  name: 'playermon-sold',
  props: ['playermon', 'usdPerMatic'],
  setup(props) {
    const router = useRouter();
    const buyerAddress = ref('');
    const sellerAddress = ref('');
    const playermonClassColor = ref('');
    const playermonClassImage = ref('');
    const priceInUsd = ref(0);
    const usdPerMaticLocal = ref(0);
    const time = ref('');
    const { playermon, usdPerMatic } = toRefs(props);
    const { token_id, buyer, price, seller, timestamp, class_type } = playermon.value;

    onUpdated(() => {
      assignUsdPerMatic(usdPerMatic.value);
    });

    const assignUsdPerMatic = (value) => {
      usdPerMaticLocal.value = value;
      priceInUsd.value = (price * usdPerMatic.value).toFixed(2);
    };

    playermonClassColor.value = classColorMapper(class_type);
    playermonClassImage.value = classImageMapper(class_type);
    priceInUsd.value = (price * usdPerMatic.value).toFixed(2);
    buyerAddress.value = buyer.substring(0, 8) + '...' + buyer.substring(buyer.length - 8, buyer.length);
    sellerAddress.value = seller.substring(0, 8) + '...' + seller.substring(seller.length - 8, seller.length);
    time.value = moment(timestamp).calendar();

    const onClick = () => {
      router.push({ name: 'Playermon', query: { id: token_id } });
    };

    return { buyerAddress, sellerAddress, priceInUsd, playermonClassColor, playermonClassImage, time, onClick };
  },
};
</script>

<style scoped>
.playermon-sold {
  width: 280px;
  flex-direction: column;
  border: 2px solid whitesmoke;
  border-radius: 16px;
  padding: 16px;
  cursor: pointer;
}

.playermon-sold > div {
  flex-direction: column;
}

.card-content {
  flex: 1;
}

.vertical-box {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  text-align: center;
  gap: 4px;
}

.vertical-box > h2 {
  margin: 0;
}

.vertical-box > h6 {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff75;
}

.token-id {
  background: var(--sub-background-color);
  color: whitesmoke;
  border-radius: 8px;
  width: 100px;
}

.token-id > div {
  margin-top: 2px;
  font-size: 14px;
}

.vertical-box > span {
  font-size: 14px;
  color: #ffffff50;
}
</style>
