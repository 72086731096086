<template>
  <transition name="modal">
    <craft-modal v-if="showModal" @close="close" :footer="false">
      <template v-slot:header>
        <div class="d-flex flex-column justify-content-center w-100">
          <div>Crafting</div>
          <div>Breeding Pod</div>
        </div>
      </template>
      <template v-slot:body>
        <img src="../../../assets/breeding_pod.png" height="100" />
        <div class="mt-3" style="color: #ffffff75; font-size: 14px">
          <span>Notes:</span>
          <div>1. Every breeding pod require {{ shardCost }} shards for crafting.</div>
          <div>2. Each time of crafting require {{ pymCost }} PYM.</div>
        </div>
        <div class="d-flex justify-content-between mt-4" style="flex-wrap: wrap">
          <div class="d-flex">
            <div style="color: #ffffff75">PYM Balance:</div>
            <div class="ms-2">{{ pymBalance }}</div>
          </div>
          <div class="d-flex">
            <div style="color: #ffffff75">Shard Balance:</div>
            <div class="ms-2">{{ shardBalance }}</div>
          </div>
        </div>
        <div class="mt-3">
          <button v-if="!enabledPYM" class="btn btn-outline-secondary py-3 w-100" @click="enablePYM">Enable PYM</button>
          <button v-if="!enabledCrafting" class="btn btn-outline-secondary py-3 w-100 mt-3" @click="enableCrafting">Enable Shard</button>
          <div v-if="enabledPYM && enabledCrafting" class="d-flex justify-content-between mt-4 gap-3" style="flex-wrap: wrap">
            <div class="d-flex justify-content-between w-100 align-items-center" style="border: 1px solid #ffffff50; border-radius: 8px; padding: 16px">
              <input class="w-100 me-1" type="number" min="0" style="font-size: 24px; background: transparent; border: none; outline: none; color: whitesmoke" @input="onChange($event.target.value)" :value="craftingValue" />
              <div style="cursor: pointer" @click="max">MAX</div>
            </div>
            <button class="btn btn-dark py-3 w-100" @click="confirm">Confirm</button>
          </div>
        </div>
        <div v-if="successHash" class="mt-3">
          <div>Broadcasted:</div>
          <a @click="gotoPolygonScan()" style="word-break: break-all">{{ successHash }}</a>
        </div>
      </template>
      <!-- <template v-slot:footer> </template> -->
    </craft-modal>
  </transition>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import CraftModal from './components/CraftModal.vue';
import { ethers } from 'ethers';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';
import ERC20 from '../../../assets/abi/ERC20.json';
import PlayermonCrafting from '../../../assets/abi/PlayermonBreedingPodCrafting.json';
import PlayermonNFT from '../../../assets/abi/PlayermonNFT.json';

export default {
  props: ['showModal', 'pymBalance', 'pymCost', 'shardCost'],
  components: { CraftModal },
  setup(props, context) {
    const craftingValue = ref(0);
    const enabledPYM = ref(false);
    const enabledCrafting = ref(false);
    const successHash = ref('');
    const store = useStore();
    const shardBalance = ref(0);
    const rawShardBalance = ref(0);
    const propsObj = toRefs(props);

    onMounted(async () => {
      await connectWallet();
    });

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.error('No Metamask');
        return false;
      }
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ethersProvider.getSigner();
      try {
        await provider.request({
          method: 'eth_requestAccounts',
        });
        const userAddress = await signer.getAddress();
        const userNetwork = await signer.getChainId();

        const breedingPodShardErc20Contract = new ethers.Contract(userNetwork === 137 ? '0x14006b7fefa4b6ab562916a5caf1d9a3848e5a3a' : '0xFA16D3D4e8bE193775283ec825eBfe9f4dF6e1D0', ERC20.abi, signer);
        const rawBreedingPodShard = await breedingPodShardErc20Contract.balanceOf(userAddress);
        shardBalance.value = new Fraction(JSBI.BigInt(rawBreedingPodShard), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        rawShardBalance.value = ethers.utils.formatEther(rawBreedingPodShard);

        const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bD49815EA8e2682220BCB41524c0dd10Ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
        const playermonTokenAllowance = await pymContract.allowance(userAddress, userNetwork === 137 ? '0xE92ea35306DbDCBADE8Cc4032389597b1f132ecA' : '0xDf4c1683f6a31979F221Cbc51234a1F62EF0D27b');
        enabledPYM.value = playermonTokenAllowance.gt('2000000000000000000000');

        const craftingContract = new ethers.Contract(userNetwork === 137 ? '0x14006b7fefa4b6ab562916a5caf1d9a3848e5a3a' : '0xfa16d3d4e8be193775283ec825ebfe9f4df6e1d0', ERC20.abi, signer);
        const craftingAllowance = await craftingContract.allowance(userAddress, userNetwork === 137 ? '0xE92ea35306DbDCBADE8Cc4032389597b1f132ecA' : '0xDf4c1683f6a31979F221Cbc51234a1F62EF0D27b');
        enabledCrafting.value = craftingAllowance.gt('2000000000000000000000');

        const nftContract = new ethers.Contract(userNetwork === 137 ? '0x927163B67Dacc20Ce9cd8fdfC44C970B1b881e37' : '0x8561CC7299374d88724426A144f52A0D76D64253', PlayermonNFT.abi, signer);
        const userNFTBalance = await nftContract.balanceOf(userAddress);
        console.log('breeding pod: ', parseInt(userNFTBalance.toString()));

        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error('Metamask', e);
      }
    };

    const close = () => {
      successHash.value = '';
      context.emit('close');
    };

    const enablePYM = async () => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
        const tx = await pymContract.approve(userNetwork === 137 ? '0xE92ea35306DbDCBADE8Cc4032389597b1f132ecA' : '0xDf4c1683f6a31979F221Cbc51234a1F62EF0D27b', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
        const receipt = await tx.wait();
        enabledPYM.value = true;
        console.log(receipt.status);
      } catch (e) {
        console.error(e);
      }
    };

    const enableCrafting = async () => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const shardContract = new ethers.Contract(userNetwork === 137 ? '0x14006b7fefa4b6ab562916a5caf1d9a3848e5a3a' : '0xfa16d3d4e8be193775283ec825ebfe9f4df6e1d0', ERC20.abi, signer);
        const tx = await shardContract.approve(userNetwork === 137 ? '0xE92ea35306DbDCBADE8Cc4032389597b1f132ecA' : '0xDf4c1683f6a31979F221Cbc51234a1F62EF0D27b', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
        const receipt = await tx.wait();
        enabledCrafting.value = true;
        console.log(receipt.status);
      } catch (e) {
        console.error(e);
      }
    };

    const confirm = async () => {
      // call blockchain here
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const craftingContract = new ethers.Contract(userNetwork === 137 ? '0xE92ea35306DbDCBADE8Cc4032389597b1f132ecA' : '0xDf4c1683f6a31979F221Cbc51234a1F62EF0D27b', PlayermonCrafting.abi, signer);
        const tx = await craftingContract.craft(craftingValue.value);
        successHash.value = tx.hash;
        await tx.wait();
        successHash.value = '';
        close();
      } catch (e) {
        console.error(e);
      }
    };

    const onChange = (newValue) => (craftingValue.value = newValue);

    const gotoPolygonScan = async () => {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const url = userNetwork === 137 ? `https://polygonscan.com/tx/${successHash.value}` : `https://mumbai.polygonscan.com/tx/${successHash.value}`;
      window.open(url, '_blank');
    };

    const max = () => {
      const shardCost = propsObj.shardCost.value;
      craftingValue.value = Math.floor(rawShardBalance.value / shardCost);
    };

    return { craftingValue, enabledPYM, enabledCrafting, successHash, shardBalance, close, onChange, enablePYM, enableCrafting, confirm, gotoPolygonScan, max };
  },
};
</script>

<style scoped>
.btn-outline-secondary {
  color: whitesmoke;
  border-color: whitesmoke;
}

.btn-outline-secondary:hover {
  color: whitesmoke;
  background: #2b60de;
}

a {
  color: blue !important;
  cursor: pointer;
}
</style>
