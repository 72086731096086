<template>
  <div class="login">
    <nav class="navbar navbar-dark nav-background custom-nav">
      <a class="navbar-brand" href="/">
        <img class="img-fluid" src="../assets/horizontal_logo.png" alt="" style="height: 35px" />
      </a>
    </nav>
    <div class="btn-container">
      <button type="button" @click="connectWallet" class="login-btn btn" :disabled="loading">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <div v-else>
          <img src="../assets/metamask_logo.svg" width="50" style="padding: 5px 0" />
          <h5>Login with MetaMask</h5>
        </div>
      </button>
      <div style="padding: 10px 0; color: #d3d3d3">By continuing, you agree to our <a href="#">Terms of Use</a></div>
      <div style="color: crimson; margin-bottom: 10px" :style="errorMsg !== '' ? 'display: block' : 'display: none'">{{ errorMsg }}</div>
      <div class="get-started">Don’t know where to start? <a href="#">Getting Started</a></div>
    </div>
    <div style="position: absolute; bottom: 10px; width: 100%">
      <div style="display: flex; width: 260px; justify-content: space-between; margin: auto; color: #999">
        <div style="">© Playermon</div>
        <a href="https://link.playermon.com/discord" target="_blank">Discord</a>
        <a href="#">Term of use</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { useRouter } from 'vue-router';

export default {
  name: 'Login',
  data: () => ({
    errorMsg: '',
    successAlert: '',
  }),
  setup() {
    const router = useRouter();

    const routeTo = (url) => {
      router.push(url);
    };

    return { routeTo };
  },
  computed: {
    loading() {
      return this.$store.state.loading.loading;
    },
  },
  methods: {
    async connectWallet() {
      const provider = window.ethereum;
      if (!provider) {
        this.errorMsg = 'Please install MetaMask extension';
        return false;
      }
      this.noMetaMask = '';

      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await this.changeNetwork();
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });
          const walletAddress = await signer.getAddress();
          const timestamp = new Date().getTime();
          const message = `Hi ${walletAddress}! Welcome to Playermon! Sign this message to prove you have access to this wallet and we'll log you in. This won't cost you any Matic. \n\nTimestamp: ${timestamp}`;
          const signature = await signer.signMessage(message);
          const loginPayload = {
            walletAddress,
            timestamp,
            message,
            signature,
          };
          await this.$store.dispatch('loading/setLoading', true);
          await this.$store.dispatch('auth/login', loginPayload);
          await this.$store.dispatch('loading/setLoading', false);
          if (this.$store.state.auth.status) {
            this.routeTo('/inventory');
          }
        }
      } catch (e) {
        await this.$store.dispatch('loading/setLoading', false);
        console.log(e);
        this.errorMsg = 'Please unlock and sign the message on your MetaMask';
      }
    },
    async changeNetwork() {
      const provider = window.ethereum;
      if (provider) {
        const chainId = 137;
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.nav-background {
  background-color: var(--main-background-color);
}

.login {
  background-image: url('../assets/backgrounds/main_bg.png');
  background-size: cover;
  background-color: var(--background-color);
  height: 100%;
}

.btn-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  padding: 12px 24px;
  border-radius: 5px;
  width: 100%;
  max-width: 420px;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  color: white;
  padding: 0 10px;
  border: #666666 solid 1px;
}

.login-btn:hover {
  border-color: #ccc;
}

.get-started {
  border-top: #636363 solid 1px;
  padding: 10px 0;
  color: #939393;
  font-size: 12px;
  font-weight: 400;
}
</style>
