import { createStore } from 'vuex';
import { auth } from './auth.module';
import { inventory } from './inventory.module';
import { loading } from './loading.module';
import { globalAlert } from '../modules/global-alert/src/globalAlertStore';

export default createStore({
  modules: {
    auth,
    inventory,
    loading,
    globalAlert,
  },
});
