<template>
  <div :class="show ? 'show' : 'hidden'" class="modal fade">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div style="display: flex; justify-content: flex-end">
            <span @click="close">
              <span style="color: #ffffff; cursor: pointer"><mdicon name="close" /></span>
            </span>
          </div>
          <div style="color: #ffffff; text-align: center">
            <div>Transaction has been broadcast successfully</div>
          </div>
          <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; margin-top: 32px">
            <a v-if="hash" style="color: green; margin-bottom: 16px; word-break: break-word; cursor: pointer" @click="gotoPolygonScan(hash)">{{ hash }}</a>
            <button type="button" class="btn btn-outline-secondary" @click="close">Okay</button>
          </div>
        </div>
      </div>
    </div>
    <div class="background" @click="close" />
  </div>
</template>

<script>
import { ethers } from 'ethers';

export default {
  name: 'Modal',
  props: ['show', 'hash'],
  setup(props, context) {
    const close = () => {
      context.emit('close', true);
    };
    const gotoPolygonScan = async (hash) => {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const url = userNetwork === 137 ? `https://polygonscan.com/tx/${hash}` : `https://mumbai.polygonscan.com/tx/${hash}`;
      window.open(url, '_blank');
    };

    return { close, gotoPolygonScan };
  },
};
</script>

<style scoped>
.show {
  opacity: 1;
  display: block;
  transition: display 0.5s linear;
}

.hidden {
  opacity: 0;
  display: none !important;
  transition: display 0.5s linear;
}

.modal {
  top: 0;
  left: 0;
  padding-top: 20vh;
  transform: none;
}

.background {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #00000075;
}

.modal-dialog {
  max-width: 450px;
}

.modal-dialog,
.modal-content,
.modal-body {
  border-radius: 8px;
  background: none;
  z-index: 99999;
}

.modal-content {
  padding: 16px;
  background: var(--background-color);
}

input {
  padding: 4px 8px;
  margin: 0 8px;
  border: 1px solid #ffffff26;
  border-radius: 4px;
  outline: none;
  background: #00000050;
  color: whitesmoke;
}

button,
button:focus {
  box-shadow: none;
}
</style>
