<template>
  <MarketplaceNavBar :noSidebar="true" :matic="matic" :pym="pym" :sgem="sgem" />
  <div class="playermon-details custom-container">
    <div class="container">
      <div style="display: flex">
        <div class="back-button" @click="goBack">
          <div style="font-size: 12px">
            <mdicon name="chevron-left" />
          </div>
          <span style="font-size: 14px; margin-left: 4px; padding-top: 2px">Back</span>
        </div>
        <div style="flex: 1" />
      </div>

      <div class="row">
        <!-- Left section -->
        <div class="col-md">
          <div style="display: flex; flex-direction: column; margin: 50px 0">
            <div style="display: flex; align-items: center; gap: 16px">
              <div style="background: #ffb812; font-size: 14px; padding: 2px 16px; border-radius: 16px; margin-bottom: 16px">#{{ playermonId }}</div>
              <div v-if="playermonRarity" style="font-size: 14px; padding: 2px 16px; border-radius: 16px; margin-bottom: 16px; border: 1px solid white">{{ playermonRarity }}</div>
              <div v-if="playermonId <= 30000" style="background: #f05b36; font-size: 14px; padding: 2px 16px; border-radius: 16px; margin-bottom: 16px">Genesis</div>
            </div>
            <div style="display: flex; gap: 4px; margin-bottom: 8px">
              <h4 style="margin: 0; padding-top: 3px">{{ name }}</h4>
              <!-- <div @click="openUpdateNameModal" style="padding-bottom: 4px; cursor: pointer">
                <mdicon name="file-edit-outline" :width="15" />
              </div> -->
            </div>
            <div style="display: flex; justify-content: center; background: var(--main-background-color); padding: 36px 12px; border-radius: 12px; border: 1px solid #ffffff75">
              <img class="img-fluid" :src="playermonImg" style="max-width: 300px" />
            </div>
          </div>
        </div>
        <!-- section end -->

        <!-- Right section -->
        <div class="col-md">
          <!-- Actions -->
          <div v-if="connected && isOwnerOfPlayermon && playermonPriceDecimal > 0" class="section" style="justify-content: flex-end; flex-warp: wrap; gap: 16px">
            <div style="display: flex; flex-direction: column; align-items: flex-end; margin-right: 16px">
              <div style="font-size: 20px; text-shadow: 2px 2px 2px black">{{ priceOfPlayermon }} PYM</div>
              <div style="font-size: 16px; color: #cccccc; text-shadow: 2px 2px 2px black; margin-top: -8px">${{ priceOfPlayermonInUSD }}</div>
            </div>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" @click="cancelSell" style="display: flex; padding: 8px 16px">
              <span style="padding-top: 2px">Cancel Sell</span>
            </button>
            <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#sellPlayermonModal" style="display: flex; padding: 8px 16px">
              <span style="padding-top: 2px; margin-left: 8px">Update Price</span>
            </button>
          </div>
          <div v-else-if="connected && isOwnerOfPlayermon" class="section" style="flex-warp: wrap; gap: 16px">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#sellPlayermonModal" style="display: flex; padding: 8px 16px">
              <div><mdicon name="tag" width="20" /></div>
              <span style="padding-top: 2px; margin-left: 8px">Sell</span>
            </button>
            <button v-if="!isHatched" type="button" class="btn btn-secondary" style="display: flex; padding: 8px 16px; background: var(--main-background-color)" @click="hatching">
              <div><mdicon name="heart" width="20" /></div>
              <span style="padding-top: 2px; margin-left: 8px">Hatch</span>
            </button>
            <button type="button" class="btn btn-secondary" style="display: flex; padding: 8px 16px" @click="openGiftModal">
              <div><mdicon name="gift" width="20" /></div>
              <span style="padding-top: 2px; margin-left: 8px">Gift</span>
            </button>
          </div>
          <div v-else-if="playermonPriceDecimal > 0" class="section" style="flex-warp: wrap; justify-content: flex-end; align-items: center">
            <div style="display: flex; flex-direction: column; align-items: flex-end; margin-right: 32px">
              <div style="font-size: 20px">{{ priceOfPlayermon }} PYM</div>
              <div style="font-size: 14px; color: #ffffff65; margin-top: -8px">${{ priceOfPlayermonInUSD }}</div>
            </div>
            <button v-if="!connected" type="button" class="btn btn-outline-primary" @click="connectWallet()" style="border-color: #ffffff80">Connect Wallet</button>
            <button v-else-if="!userEnablePYMWallet" type="button" class="btn btn-outline-primary" @click="enablePYMWallet()" style="border-color: #ffffff80">Enable PYM For Marketplace</button>
            <button v-else type="button" class="btn btn-outline-primary" @click="buy()" style="border-color: #ffffff80">Buy</button>
          </div>
          <div v-else-if="!connected" class="section" style="flex-warp: wrap; justify-content: flex-end; align-items: center">
            <button type="button" class="btn btn-outline-primary" @click="connectWallet()" style="border-color: #ffffff80">Connect Wallet</button>
          </div>
          <div v-else style="height: 92px" />
          <!-- Actions end -->

          <div v-if="isHatched">
            <!-- About -->
            <div class="section column">
              <h4 style="text-align: left">About</h4>
              <div class="box">
                <div style="display: flex; margin-bottom: 16px">
                  <div class="two-line">
                    <span class="label">Class</span>
                    <div class="d-flex align-items-center" style="margin-top: 2px">
                      <img v-if="playermonClassIcon" class="img-fluid" :src="playermonClassIcon" style="width: 12px; height: 15px; margin-right: 8px" />
                      <div>{{ playermonClass || 'Egg' }}</div>
                    </div>
                  </div>
                  <div class="two-line" style="margin-left: 72px">
                    <span class="label">Breed count</span>
                    <div style="margin-top: 2px">
                      <span>{{ 0 }} / {{ 5 }}</span>
                    </div>
                  </div>
                </div>
                <div class="two-line">
                  <span class="label">Owner</span>
                  <div class="text-truncate" style="display: block; width: 100%; text-align: left">
                    <span>{{ ownerOfPlayermonUsername }}</span>
                    <span style="font-size: 12px; color: #ffffff50; margin-left: 4px">({{ ownerOfPlayermon }})</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- About end -->

            <!-- stats -->
            <!-- <div class="section column">
              <h4 style="text-align: left">Stats</h4>
              <div class="box">
                <div style="display: flex; margin-bottom: 16px; flex: 1">
                  <div class="two-line" style="flex: 1">
                    <span class="label">Health</span>
                    <div style="display: flex; align-items: center">
                      <div class="icon" style="color: limegreen">
                        <mdicon name="heart" height="20" />
                      </div>
                      <span>87</span>
                    </div>
                  </div>
                  <div class="two-line" style="flex: 1">
                    <span class="label">Speed</span>
                    <div style="display: flex; align-items: center">
                      <div class="icon" style="color: gold">
                        <mdicon name="lightning-bolt" height="20" />
                      </div>
                      <span>87</span>
                    </div>
                  </div>
                </div>
                <div style="display: flex">
                  <div class="two-line" style="flex: 1">
                    <span class="label">Skill</span>
                    <div style="display: flex; align-items: center">
                      <div class="icon" style="color: slateblue">
                        <mdicon name="star" height="20" />
                      </div>
                      <span>87</span>
                    </div>
                  </div>
                  <div class="two-line" style="flex: 1">
                    <span class="label">Morale</span>
                    <div style="display: flex; align-items: center">
                      <div class="icon" style="color: crimson">
                        <mdicon name="fire" height="20" />
                      </div>
                      <span>87</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- stats end -->

            <!-- Skill -->
            <div class="section column" v-if="playermonBodyParts.length > 0">
              <h4 style="text-align: left">Body Parts</h4>
              <div class="skill box" style="padding-bottom: 8px">
                <div class="d-flex">
                  <div style="display: flex; flex: 1; justify-content: space-between">
                    <div style="text-align: center; width: 100%">Dominant</div>
                    <div style="text-align: center; width: 100%">R1</div>
                    <div style="text-align: center; width: 100%">R2</div>
                  </div>
                </div>
                <div class="d-flex justify-content-between" v-for="bodyPart in playermonBodyParts" :key="bodyPart.value">
                  <div class="two-line">
                    <img width="50" :src="bodyPart.image" style="margin-right: 16px" />
                    <span style="text-align: left" :style="`color: ${bodyPart.color}`">{{ bodyPart.value }}</span>
                  </div>
                  <span style="text-align: left" :style="`color: ${bodyPart.r1Color}`">{{ bodyPart.r1 }}</span>
                  <span style="text-align: left" :style="`color: ${bodyPart.r2Color}`">{{ bodyPart.r2 }}</span>
                </div>
              </div>
            </div>
            <!-- Skill end -->

            <!-- Abilities -->
            <!-- <div class="section column">
              <h4 style="text-align: left">Abilities</h4>
              <div class="abilities box" style="padding-bottom: 8px">
                <div v-for="ability in abilities" :key="ability">
                  <div>
                    <img width="30" :src="ability.logo" />
                    <span style="font-size: 12px; color: #ffffff50; text-transform: uppercase">{{ ability.name }}</span>
                  </div>
                  <img class="img-fluid" :src="ability.img" style="padding: 4px 8px" />
                </div>
              </div>
            </div> -->
            <!-- Abilities end -->

            <!-- Parents -->
            <!-- <div class="section column">
              <h4 style="text-align: left">Parents</h4>
              <div class="parents" style="display: flex">
                <PlayermonCard :disabled="true" v-for="parent in parents" :key="parent.id" :id="parent.id" :breedCount="parent.breedCount" :img="parent.img" />
              </div>
            </div> -->
            <!-- Parents end -->

            <!-- Children -->
            <!-- <div class="section column">
              <h4 style="text-align: left">Children</h4>
              <div class="children" style="display: flex">
                <PlayermonCard :disabled="true" v-for="parent in parents" :key="parent.id" :id="parent.id" :breedCount="parent.breedCount" :img="parent.img" />
              </div>
            </div> -->
            <!-- Children end -->

            <!-- Sale history -->
            <!-- <div class="section column">
              <h4 style="text-align: left">Sale history</h4>
              <div class="sale-history" style="display: flex">
                <div v-for="history in saleHistory" :key="history.id">
                  <div class="buyer">
                    <div class="label">Buyer</div>
                    <div>{{ history.buyer }}</div>
                    <div class="text-truncate subtitle">{{ history.buyerAddress }}</div>
                  </div>
                  <div class="seller">
                    <div class="label">Seller</div>
                    <div>{{ history.seller }}</div>
                    <div class="text-truncate subtitle">{{ history.sellerAddress }}</div>
                  </div>
                  <div class="price-time">
                    <div style="font-size: 16px">{{ history.amount }} PYM</div>
                    <div class="subtitle">{{ history.datetime }}</div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Sale history end -->
          </div>
          <playermon-egg v-if="!isHatched" :hatchingTime="countDownDate" :ownerUsername="ownerOfPlayermonUsername" :ownerAddress="ownerOfPlayermon" />

          <!-- Modal -->
          <SellPlayermonModal
            :id="playermonId"
            :connected="connected"
            :enabledWallet="userEnableNFTWallet"
            :disabledWallet="userEnabledNFTV1Wallet"
            @connectWallet="connectWallet"
            @enabledWallet="enableNFTWallet"
            @disabledWallet="disableNFTV1Wallet"
            @refreshPlayermon="refreshPlayermon"
            :price="priceOfPlayermon"
          />
          <update-name-modal style="font-size: 16px" :show="showUpdateNameModal" @close="closeUpdateNameModal" :name="name" @rename="rename" title="What would you like to call this playermon?" />
          <gift-modal style="font-size: 16px" :show="showGiftModal" @close="closeGiftModal" @gift="gift" :hash="giftSuccessHash" />
          <modal style="font-size: 16px" :show="showModal" @close="closeModal" :hash="successHash" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { ethers } from 'ethers';
import { useStore } from 'vuex';
import { onBeforeMount, ref } from '@vue/runtime-core';
import { JSBI, Fraction } from '@pancakeswap-libs/sdk';

import ERC20 from '../../../assets/abi/ERC20.json';
import PlayermonNFT from '../../../assets/abi/PlayermonNFT.json';
import PlayermonNFTMarketplace from '../../../assets/abi/PlayermonPYMMarketplace.json';
import PlayermonBreedingManager from '../../../assets/abi/PlayermonBreedingManager.json';

/** Components */
import MarketplaceNavBar from '@/components/MarketplaceNavBar.vue';
// import PlayermonCard from '@/components/PlayermonCard.vue';
import SellPlayermonModal from './components/SellPlayermonModal.vue';
import UpdateNameModal from '../../../components/UpdateNameModal.vue';
import GiftModal from '../../../components/giftModal.vue';
import Modal from '../../../components/Inventory/Modal.vue';
import PlayermonEgg from './components/PlayermonEgg.vue';
import userService from '../../../services/userService';

import { playermonMetadata } from '../src/util';

export default {
  name: 'Playermon details',
  props: ['id'],
  components: {
    MarketplaceNavBar,
    // PlayermonCard,
    SellPlayermonModal,
    UpdateNameModal,
    GiftModal,
    Modal,
    PlayermonEgg,
  },
  setup() {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // User info
    const matic = ref(0);
    const sgem = ref(0.0);
    const pym = ref(0);
    const connected = ref(false);
    const userEnableNFTWallet = ref(false);
    const userEnablePYMWallet = ref(false);
    const userEnabledNFTV1Wallet = ref(true);
    const userWalletAddress = ref('');

    // hatch
    const countDownDate = ref(1640008800000);
    const days = ref(0);
    const hours = ref(0);
    const minutes = ref(0);
    const seconds = ref(0);
    const distance = ref(0);

    // Playermon details
    const { id } = route.query;
    const name = ref(`Playermon #${id}`);
    const playermonId = ref(id);
    const playermonImg = ref('');
    const ownerOfPlayermon = ref('');
    const isOwnerOfPlayermon = ref(false); //compare ownerofPlayermon with userWalletAddress
    const priceOfPlayermon = ref(0); // 0 or null or undefined indicated this playermon is not on sale.
    const priceOfPlayermonInUSD = ref(0);
    const ownerOfPlayermonUsername = ref('');
    const giftSuccessHash = ref('');
    const rawPlayermonPrice = ref(0);
    const playermonPriceDecimal = ref(0);
    const playermonClass = ref('');
    const playermonBodyParts = ref([]);
    const isHatched = ref(false);
    const playermonClassIcon = ref('');
    const playermonRarity = ref('');

    // Modal
    const successHash = ref('');
    const showModal = ref(false);
    const showGiftModal = ref(false);
    const showUpdateNameModal = ref(false);

    onBeforeMount(async () => {
      // await connectWallet();
      await getPlayermonMetadata();
      await getPlayermonHatchDetails();
      await getPlayermonPrice();
      await getUsdPerPym();
      ownerOfPlayermonUsername.value = await getUsername();
    });

    const getUsername = async () => {
      await store.dispatch('loading/setLoading', true);
      try {
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const playermonNftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, ethersProvider);
        ownerOfPlayermon.value = await playermonNftContract.ownerOf(id);
        const response = await userService.getUsernameByWalletAddress(ownerOfPlayermon.value);
        ownerOfPlayermonUsername.value = response.data.data.username;
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error(e);
      }
    };

    const refreshPlayermon = async () => {
      await getPlayermonHatchDetails();
      await getPlayermonMetadata();
      await getPlayermonPrice();
      await getUsdPerPym();
      isOwnerOfPlayermon.value = ownerOfPlayermon.value === userWalletAddress.value;
    };

    const getMatic = async () => {
      await store.dispatch('loading/setLoading', true);
      const signer = await ethersProvider.getSigner();
      const matic = await signer.getBalance();
      const readableMatic = new Fraction(JSBI.BigInt(matic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
      await store.dispatch('loading/setLoading', false);
      return readableMatic;
    };

    const getPlayermonPrice = async () => {
      await store.dispatch('loading/setLoading', true);
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const playermonNFTMarketplaceV2 = new ethers.Contract(userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2', PlayermonNFTMarketplace.abi, signer);
      try {
        const price = await playermonNFTMarketplaceV2.getSalePrice(id);
        const readablePrice = new Fraction(JSBI.BigInt(price), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        rawPlayermonPrice.value = ethers.utils.formatEther(price);
        playermonPriceDecimal.value = parseInt(rawPlayermonPrice.value);
        priceOfPlayermon.value = readablePrice;
        await store.dispatch('loading/setLoading', false);
      } catch (err) {
        console.log(err);
        await store.dispatch('loading/setLoading', false);
      }
    };

    const getUsdPerPym = async () => {
      await store.dispatch('loading/setLoading', true);
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=playermon&vs_currencies=usd');
      const usdPerPym = response.data['playermon'].usd;
      const usdPerPymBN = ethers.utils.parseEther(usdPerPym.toString()); // parseEther will have 18 digits
      const pricePerPlayermonBN = ethers.utils.parseEther(rawPlayermonPrice.value); // parseEther will have 18 digits
      const priceOfPlayermonInUSDBN = usdPerPymBN.mul(pricePerPlayermonBN); // 18 digits * 18 digits = 36 digits
      priceOfPlayermonInUSD.value = ethers.utils.formatUnits(priceOfPlayermonInUSDBN, 36); // format unit 36 digits
      await store.dispatch('loading/setLoading', false);
    };

    const getPlayermonHatchDetails = async () => {
      try {
        await store.dispatch('loading/setLoading', true);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const playermonBreedingManagerContract = new ethers.Contract(userNetwork === 137 ? '0xc24B9ed9f1A36d7FC26Bf3807a5B6e71410C43e5' : '0xa5B79764D1DdDD8940662E29fcAcC03dabc2Dcc3', PlayermonBreedingManager.abi, ethersProvider);
        const hatchableTime = (await playermonBreedingManagerContract.hatchableOn(id)).toString();
        countDownDate.value = parseInt(hatchableTime) * 1000;
        isHatched.value = await playermonBreedingManagerContract.isHatched(id);
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error('getPlayermonHatchDetails error: ', e);
      }
    };

    const getPlayermonMetadata = async () => {
      try {
        const { image, pClass, rarity, icon, bodyParts } = await playermonMetadata(id);
        playermonImg.value = image;
        playermonClass.value = pClass;
        playermonRarity.value = rarity;
        playermonClassIcon.value = icon;
        playermonBodyParts.value = bodyParts;
      } catch (e) {
        console.error('getPlayermonMetadata error: ', e);
      }
    };

    const openUpdateNameModal = () => {
      showUpdateNameModal.value = true;
    };

    const closeUpdateNameModal = () => {
      showUpdateNameModal.value = false;
    };

    const openGiftModal = () => {
      showGiftModal.value = true;
    };

    const closeGiftModal = () => {
      showGiftModal.value = false;
    };

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const goBack = () => {
      router.go(-1);
    };

    const rename = async (value) => {
      name.value = value;
      showUpdateNameModal.value = false;
    };

    const getSkills = () => {
      return [
        {
          name: 'Skill 1',
          lvl: 1,
          img: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
        },
        {
          name: 'Skill 2',
          img: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
        },
        {
          name: 'Skill 3',
          lvl: 1,
          img: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
        },
        {
          name: 'Skill 4',
          lvl: 1,
          img: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
        },
        {
          name: 'Skill 5',
          img: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
        },
        {
          name: 'Skill 6',
          img: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
        },
      ];
    };

    const getAbilities = () => {
      return [
        {
          name: 'Abilities 1',
          logo: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
          img: require('../../../assets/skill_card_aqua.png'),
          description: 'This is abilities description',
          level: 6,
          atk: 88,
          def: 900,
        },
        {
          name: 'Abilities 2',
          logo: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
          img: require('../../../assets/skill_card_inferno.png'),
          description: 'This is abilities description',
          level: 12,
          atk: 999,
          def: 900,
        },
        {
          name: 'Abilities 3',
          logo: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
          img: require('../../../assets/skill_card_mech.png'),
          description: 'This is abilities description',
          level: 60,
          atk: 999,
          def: 900,
        },
        {
          name: 'Abilities 4',
          logo: 'https://cdn3.iconfinder.com/data/icons/role-playing-game-5/340/ability_skill_swords_game_magic_fire-512.png',
          img: require('../../../assets/skill_card_nature.png'),
          description: 'This is abilities description blahblah aisdaiooghingvqwoi',
          level: 99,
          atk: 999,
          def: 900,
        },
      ];
    };

    const getParents = () => {
      return [
        // {
        //   id: '000',
        //   name: 'Playermon 000',
        //   breedCount: 1,
        //   img: 'https://staging-playermon.s3.ap-southeast-1.amazonaws.com/3c0c20c9-e9a0-4390-a1f5-dde65331a1a1-screenshot.png',
        // },
        // {
        //   id: '000',
        //   name: 'Playermon 000',
        //   breedCount: 1,
        //   img: 'https://staging-playermon.s3.ap-southeast-1.amazonaws.com/3c0c20c9-e9a0-4390-a1f5-dde65331a1a1-screenshot.png',
        // },
        // {
        //   id: '000',
        //   name: 'Playermon 000',
        //   breedCount: 1,
        //   img: 'https://staging-playermon.s3.ap-southeast-1.amazonaws.com/3c0c20c9-e9a0-4390-a1f5-dde65331a1a1-screenshot.png',
        // },
      ];
    };

    const getSaleHistory = () => [
      // {
      //   id: 123,
      //   buyer: 'Buyer 1',
      //   buyerAddress: '78c2a927b8f43f4209900ee93d7cb981417102a0',
      //   seller: 'Seller 1',
      //   sellerAddress: '78c2a927b8f43f4209900ee93d7cb981417102a0',
      //   amount: '65',
      //   datetime: '35 minutes ago',
      // },
      // {
      //   id: 124,
      //   buyer: 'Buyer 2',
      //   buyerAddress: '78c2a927b8f43f4209900ee93d7cb981417102a0',
      //   seller: 'Seller 2',
      //   sellerAddress: '78c2a927b8f43f4209900ee93d7cb981417102a0',
      //   amount: '59',
      //   datetime: 'Oct 31, 2021',
      // },
    ];

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.log('No Metamask');
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await changeNetwork();
          await store.dispatch('loading/setLoading', false);
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });
          connected.value = true;
          const userAddress = await signer.getAddress();
          userWalletAddress.value = userAddress;

          const playermonNftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, signer);
          ownerOfPlayermon.value = await playermonNftContract.ownerOf(id);
          isOwnerOfPlayermon.value = ownerOfPlayermon.value === userWalletAddress.value;

          const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
          const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
          const userPYMAllowanceAmount = await pymContract.allowance(userAddress, userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2');
          matic.value = await getMatic();

          const userPlayermonNFTApproval = await playermonNftContract.isApprovedForAll(userAddress, userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2');
          const userPlayermonNFTV1Approval = await playermonNftContract.isApprovedForAll(userAddress, userNetwork === 137 ? '0x56838b13A07961B23B9d74B8B1d5a290270B3Ced' : '0x77c268BD74c6E50A10e40E7eeC814dC87971640a');
          const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
          const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
          sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          console.log('userPYMBalanceAmount: ', userPYMBalanceAmount);
          console.log('userPYMAllowanceAmount: ', userPYMAllowanceAmount);
          console.log('userPlayermonNFTApproval: ', userPlayermonNFTApproval);
          console.log('userPlayermonNFTV1Approval', userPlayermonNFTV1Approval);

          const readablePYM = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          pym.value = readablePYM;

          userEnableNFTWallet.value = userPlayermonNFTApproval;
          userEnabledNFTV1Wallet.value = userPlayermonNFTV1Approval;
          userEnablePYMWallet.value = userPYMAllowanceAmount.gte('10000000000000000000000000');
          console.log('userEnablePYMWallet: ', userEnablePYMWallet.value);
        }
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        connected.value = false;
        console.log('Metamask', e);
        // this.noMetaMaskAlert = 'Please unlock and sign the message on your MetaMask';
      }
    };

    const cancelSell = async () => {
      try {
        await store.dispatch('loading/setLoading', true);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const playermonNFTMarketplaceV2 = new ethers.Contract(userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2', PlayermonNFTMarketplace.abi, signer);

        const tx = await playermonNFTMarketplaceV2.cancelSelling(id);
        const receipt = tx.wait().then(() => refreshPlayermon());
        console.log(receipt);
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error(e);
      }
    };

    const buy = async () => {
      try {
        await store.dispatch('loading/setLoading', true);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const playermonNFTMarketplaceV2 = new ethers.Contract(userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2', PlayermonNFTMarketplace.abi, signer);

        const price = await playermonNFTMarketplaceV2.getSalePrice(id);
        const readablePrice = new Fraction(JSBI.BigInt(price), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        if (readablePrice === priceOfPlayermon.value) {
          const tx = await playermonNFTMarketplaceV2.purchase(id);
          const receipt = tx.wait().then(() => refreshPlayermon());
          console.log(receipt);
        } else {
          await refreshPlayermon();
        }
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error(e);
      }
    };

    const gift = async (receiver) => {
      try {
        await store.dispatch('loading/setLoading', true);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const playermonNftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, signer);
        const tx = await playermonNftContract.transferFrom(userWalletAddress.value, receiver, id);
        const receipt = tx.wait();
        console.log(receipt.status);

        giftSuccessHash.value = tx.hash;
        console.log(receipt);
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error(e);
      }
    };

    const hatching = async () => {
      try {
        await store.dispatch('loading/setLoading', true);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const playermonBreedingManagerContract = new ethers.Contract(userNetwork === 137 ? '0xc24B9ed9f1A36d7FC26Bf3807a5B6e71410C43e5' : '0xa5B79764D1DdDD8940662E29fcAcC03dabc2Dcc3', PlayermonBreedingManager.abi, signer);
        const tx = await playermonBreedingManagerContract.hatchPlayermon(id);

        const receipt = tx.wait();
        console.log(receipt.status);

        successHash.value = tx.hash;
        console.log(tx.hash);
        openModal();
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error(e);
      }
    };

    const enableNFTWallet = async () => {
      console.log('helo');
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const playermonNftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, signer);

      if (userNetwork !== 137 && userNetwork !== 80001) {
        await this.changeNetwork();
      } else {
        const tx = await playermonNftContract.setApprovalForAll(userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2', true);
        const receipt = await tx.wait();
        userEnableNFTWallet.value = true;
        console.log(receipt.status);
      }
    };

    const disableNFTV1Wallet = async () => {
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const playermonNftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, signer);

      if (userNetwork !== 137 && userNetwork !== 80001) {
        await this.changeNetwork();
      } else {
        const tx = await playermonNftContract.setApprovalForAll(userNetwork === 137 ? '0x56838b13A07961B23B9d74B8B1d5a290270B3Ced' : '0x77c268BD74c6E50A10e40E7eeC814dC87971640a', false);
        const receipt = await tx.wait();
        userEnabledNFTV1Wallet.value = false;
        console.log(receipt.status);
      }
    };
    const enablePYMWallet = async () => {
      console.log('helo');
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);

      if (userNetwork !== 137 && userNetwork !== 80001) {
        await this.changeNetwork();
      } else {
        const tx = await pymContract.approve(userNetwork === 137 ? '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411' : '0xff986dC7ebbb4A59F460e1aB5a6838053be3F5a2', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
        const receipt = await tx.wait();
        userEnablePYMWallet.value = true;
        console.log(receipt.status);
      }
    };

    const changeNetwork = async () => {
      const provider = window.ethereum;
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(137).toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    };

    const skills = getSkills();
    const abilities = getAbilities();
    const parents = getParents();
    const saleHistory = getSaleHistory();

    return {
      goBack,
      skills,
      abilities,
      parents,
      saleHistory,
      countDownDate,
      days,
      hours,
      minutes,
      seconds,
      distance,
      name,
      rename,
      showUpdateNameModal,
      openUpdateNameModal,
      closeUpdateNameModal,
      playermonId,
      playermonImg,
      connected,
      userEnableNFTWallet,
      userEnablePYMWallet,
      connectWallet,
      enableNFTWallet,
      disableNFTV1Wallet,
      userEnabledNFTV1Wallet,
      enablePYMWallet,
      userWalletAddress,
      ownerOfPlayermon,
      pym,
      sgem,
      matic,
      gift,
      hatching,
      showGiftModal,
      openGiftModal,
      closeGiftModal,
      giftSuccessHash,
      isOwnerOfPlayermon,
      priceOfPlayermon,
      priceOfPlayermonInUSD,
      refreshPlayermon,
      cancelSell,
      buy,
      ownerOfPlayermonUsername,
      playermonPriceDecimal,
      playermonClass,
      playermonBodyParts,
      isHatched,
      showModal,
      openModal,
      closeModal,
      successHash,
      playermonClassIcon,
      playermonRarity,
    };
  },
};
</script>

<style scoped>
.playermon-details {
  padding-top: 81px;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1024px;
  }
}

.back-button {
  display: flex;
  cursor: pointer;
  margin-right: auto;
  align-items: center;
  margin-bottom: 16px;
}

.buy-btn {
  display: flex;
  padding: 8px 16px;
  border: 1px solid white;
  border-radius: 16px;
  background: var(--main-background-color);
}

.buy-btn:hover {
  background: var(--sub-background-color);
}

.btn {
  box-shadow: none;
}

.section {
  display: flex;
  margin-bottom: 48px;
}

.box {
  padding: 24px;
  border: 1px solid #ffffff75;
  border-radius: 12px;
  background: var(--main-background-color);
}

.column {
  flex-direction: column;
}

.two-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skill .two-line {
  align-items: center;
  flex-direction: row;
}

.label {
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff65;
  font-weight: 600;
}

.icon {
  padding: 2px;
  border: 1px solid #ffffff26;
  border-radius: 4px;
  margin-right: 8px;
}

.skill {
  display: flex;
  flex-direction: column;
}

.abilities {
  display: flex;
  flex-wrap: wrap;
}

.abilities > div,
.skill > div {
  display: flex;
  flex-grow: 1;
  width: 50%;
  align-items: center;
  margin-bottom: 16px;
}

.skill > div {
  width: 100%;
}

@media screen and (max-width: 376px) {
  .abilities > div,
  .skill > div {
    width: 100%;
  }
}

.abilities > div {
  justify-content: center;
  flex-direction: column;
}

.parents,
.children {
  flex-wrap: wrap;
}

.sale-history {
  display: flex;
  flex-direction: column;
}

.sale-history > div {
  display: flex;
  padding: 24px;
  border: 1px solid #ffffff75;
  border-radius: 8px;
  margin-bottom: 16px;
  background: var(--main-background-color);
}

.buyer,
.seller,
.price-time {
  text-align: left;
  font-size: 12px;
  width: 40%;
}

.price-time {
  width: 20%;
  text-align: end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.sale-history .label {
  color: #ffffff65;
  text-transform: uppercase;
  font-size: 10px !important;
}

.subtitle {
  color: #ffffff50;
}

.buyer .subtitle,
.seller .subtitle {
  width: 90%;
}

@media screen and (max-width: 468px) {
  .sale-history > div {
    flex-direction: column;
  }

  .buyer,
  .seller,
  .price-time {
    width: 100%;
    text-align: start;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .buyer .subtitle,
  .seller .subtitle {
    width: 100%;
  }

  .price-time {
    margin-bottom: 0;
  }
}
</style>
