<template>
  <staking-card :stakingData="stakingData" @select-nft="select" :pool="pool" :lpToken="lpToken" :staked="readableStaked" :readableLpToken="readableLpToken" :readableEarnPod="readableEarnPod" />
  <breeding-pot-modal :visible="visible" :currency="deposit" @close="close" :pool="pool" :lpToken="lpToken" :staked="staked" :readableStaked="readableStaked" :readableLpToken="readableLpToken" :earnPod="earnPod" :readableEarnPod="readableEarnPod" @refreshBalance="refreshBalance" />
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import BreedingPotModal from './BreedingPotModal.vue';
import StakingCard from '../StakingCard.vue';
import ERC20 from '../../../../../assets/abi/ERC20.json';
import BreedingPodShardMiner from '../../../../../assets/abi/BreedingPodShardMiner.json';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';

export default {
  components: { BreedingPotModal, StakingCard },
  name: 'breeding-pot-staking-card',
  props: ['deposit', 'pool'],
  setup(props) {
    const store = useStore();
    const connected = ref(false);
    const visible = ref(false);
    const lpToken = ref(0);
    const readableLpToken = ref(0);
    const staked = ref(0);
    const readableStaked = ref(0);
    const earnPod = ref(0);
    const readableEarnPod = ref(0);
    const { deposit, pool } = toRefs(props);

    onMounted(async () => {
      await connectWallet();
    });

    const getAmount = (pool) => {
      switch (pool) {
        case '0':
          return '30%';
        case '1':
          return '60%';
        case '2':
          return '10%';

        default:
          return '-';
      }
    };

    const getLpAddress = (pool) => {
      switch (pool) {
        case '0':
          return '0xe6191476b72152a43c04769e3d76c285c2a89145';
        case '1':
          return '0xd236ca19caa7f8ed19797a5fff7e89e256674904';
        case '2':
          return '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41';

        default:
          return '-';
      }
    };

    const getIcon = (pool) => {
      switch (pool) {
        case '0':
          return require('../../../../../assets/sgemUsdtBreedingPodShard.png');
        case '1':
          return require('../../../../../assets/pymUsdtBreedingPodShard.png');
        case '2':
          return require('../../../../../assets/pymBreedingPodShard.png');

        default:
          return '-';
      }
    };

    const generateStakingData = () => {
      return {
        image: getIcon(pool.value),
        deposit: deposit.value,
        balanceLp: lpToken.value,
        earn: 'Breeding pod shard',
        amount: getAmount(pool.value),
        amountCurrency: '',
        duration: 'day',
      };
    };

    const select = () => {
      visible.value = true;
    };

    const close = () => {
      visible.value = false;
    };

    const refreshBalance = async () => {
      await connectWallet();
    };

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.log('No Metamask');
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await ethersProvider.getSigner();
      try {
        await provider.request({
          method: 'eth_requestAccounts',
        });
        connected.value = true;
        const userAddress = await signer.getAddress();
        const userNetwork = await signer.getChainId();

        const erc20Contract = new ethers.Contract(userNetwork !== 137 && pool.value === '2' ? '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a' : getLpAddress(pool.value), ERC20.abi, signer);
        const rawLpToken = await erc20Contract.balanceOf(userAddress);
        lpToken.value = rawLpToken.toString();
        readableLpToken.value = new Fraction(JSBI.BigInt(lpToken.value), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

        const breedingPodShardMinerContract = new ethers.Contract(userNetwork === 137 ? '0x21c6cc8e06f07523b1166928cac5db7310250978' : '0xaa0c7648002c354DCBa1739bdab0697b7c595975', BreedingPodShardMiner.abi, signer);
        const rawStaked = await breedingPodShardMinerContract.userInfo(userNetwork === 137 ? pool.value : '0', userAddress);
        const pendingPBPS = await breedingPodShardMinerContract.pendingPBPS(userNetwork === 137 ? pool.value : '0', userAddress);
        staked.value = rawStaked[0].toString();
        earnPod.value = pendingPBPS.toString();
        readableStaked.value = new Fraction(JSBI.BigInt(rawStaked[0]), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        readableEarnPod.value = new Fraction(JSBI.BigInt(pendingPBPS), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        connected.value = false;
        console.log('Metamask', e);
        // this.noMetaMaskAlert = 'Please unlock and sign the message on your MetaMask';
      }
    };

    return { close, select, visible, stakingData: generateStakingData(), lpToken, staked, earnPod, readableEarnPod, readableLpToken, readableStaked, refreshBalance };
  },
};
</script>
