import axios from 'axios';
import { USER_TOKEN } from './authService';

const API_URL = process.env.VUE_APP_API_URL;

const ROUTES = Object.freeze({
  GET_BALANCES: '/get-balance',
  UPDATE_USERNAME: '/auth/updateUsername',
  GET_USER_SGEM: '/auth/getUserSgem',
  GET_USERNAME_BY_WALLET_ADDRESS: '/auth/getUsernameByWalletAddress',
});

class UserService {
  async updateUsername(username) {
    const user = JSON.parse(localStorage.getItem(USER_TOKEN));

    const res = await axios.patch(
      API_URL + ROUTES.UPDATE_USERNAME,
      { username },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );
    return res;
  }
  async getUserSgem() {
    const user = JSON.parse(localStorage.getItem(USER_TOKEN));

    const res = await axios.get(API_URL + ROUTES.GET_USER_SGEM, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    return res;
  }
  async getUsernameByWalletAddress(walletAddress) {
    const res = await axios.get(API_URL + ROUTES.GET_USERNAME_BY_WALLET_ADDRESS + '/' + walletAddress);
    return res;
  }
}

export default new UserService();
