<template>
  <staking-input-form title="Unstake" :currency="formattedCurrency" @cancel="cancel" />
</template>

<script>
import { toRefs } from '@vue/reactivity';
import StakingInputForm from './StakingInputForm.vue';
export default {
  components: { StakingInputForm },
  name: 'stake',
  props: ['currency'],
  setup(props, context) {
    const { currency } = toRefs(props);
    const formattedCurrency = currency.value.toUpperCase();

    const cancel = () => context.emit('cancel');

    return { formattedCurrency, cancel };
  },
};
</script>

<style scoped>
.input-container {
  padding: 16px 24px;
  border: 1px solid #ffffff25;
  border-radius: 16px;
}

input {
  display: flex;
  outline: none;
  box-shadow: none;
  width: 80%;
  border: none;
  border-bottom: 1px solid #ffffff25;
  font-size: 24px;
  color: whitesmoke;
  background: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.max-btn {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: lightblue;
}

.max-btn:hover {
  color: lightskyblue;
}

.btn {
  width: 100%;
  margin-top: 24px;
  color: whitesmoke;
  background: var(--sub-background-color);
}

.btn:hover {
  background: #add8e675;
}
</style>
