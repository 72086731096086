import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;
export const USER_TOKEN = 'user';

const ROUTES = Object.freeze({
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  GET_QR_TOKEN: '/auth/qrToken',
  RE_GEN_QR_TOKEN: '/auth/reGenQrToken',
});

class AuthService {
  async login(user) {
    const response = await axios.post(API_URL + ROUTES.LOGIN, user);
    const jwt = response.data.data.session_token;
    const username = response.data.data.username;

    localStorage.setItem(USER_TOKEN, JSON.stringify(jwt));
    localStorage.setItem('username', JSON.stringify(username));
    return { jwt, username };
  }

  async getQrToken() {
    const rawJwt = localStorage.getItem(USER_TOKEN);
    const jwt = JSON.parse(rawJwt);
    const response = await axios.get(API_URL + ROUTES.GET_QR_TOKEN, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response;
  }

  async reGenQrToken() {
    const rawJwt = localStorage.getItem(USER_TOKEN);
    const jwt = JSON.parse(rawJwt);
    const response = await axios.get(API_URL + ROUTES.RE_GEN_QR_TOKEN, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response;
  }

  async logout() {
    const rawJwt = localStorage.getItem(USER_TOKEN);
    const jwt = JSON.parse(rawJwt);
    const response = await axios.post(
      API_URL + ROUTES.LOGOUT,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    return response.data;
  }
}

export default new AuthService();
