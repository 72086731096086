<template>
  <div :class="show ? 'show' : 'hidden'" class="modal fade">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div style="display: flex; justify-content: flex-end">
            <span @click="close">
              <span style="color: #ffffff; cursor: pointer"><mdicon name="close" /></span>
            </span>
          </div>
          <div style="color: #ffffff; text-align: center">
            <div style="margin-bottom: 8px">{{ title || 'Welcome… What should we call you?' }}</div>
            <div style="color: #ffffff75">Please select a name. This can be changed later.</div>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 32px">
            <label style="color: #ffffff">Name</label>
            <input style="width: 100%" @input="onInput($event.target.value)" :value="username" maxlength="20" />
            <div>
              <button type="button" class="btn btn-outline-secondary" @click="rename">Confirm</button>
            </div>
          </div>
          <div style="margin-top: 8px; color: #ffffff60; font-size: 12px">** Your name must be within 20 characters</div>
        </div>
      </div>
    </div>
    <div class="background" @click="close" />
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
export default {
  name: 'Update Name Modal',
  props: ['name', 'show', 'title'],
  setup(props, context) {
    const { name } = toRefs(props);
    const username = ref(name.value);
    const onInput = (value) => {
      username.value = value;
    };
    const rename = () => {
      context.emit('rename', username.value);
    };
    const close = () => {
      context.emit('close', true);
    };

    return { rename, onInput, username, close };
  },
};
</script>

<style scoped>
.show {
  opacity: 1;
  display: block;
  transition: display 0.5s linear;
}

.hidden {
  opacity: 0;
  display: none !important;
  transition: display 0.5s linear;
}

.modal {
  top: 0;
  left: 0;
  padding-top: 20vh;
  transform: none;
}

.background {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #00000075;
}

.modal-dialog {
  max-width: 450px;
}

.modal-dialog,
.modal-content,
.modal-body {
  border-radius: 8px;
  background: none;
  z-index: 99999;
}

.modal-content {
  padding: 16px;
  background: var(--background-color);
}

input {
  padding: 4px 8px;
  margin: 0 8px;
  border: 1px solid #ffffff26;
  border-radius: 4px;
  outline: none;
  background: #00000050;
  color: whitesmoke;
}

button,
button:focus {
  box-shadow: none;
}
</style>
