<template>
  <marketplace-nav-bar :noSidebar="true" :matic="matic" :pym="pym" :sgem="sgem" :connected="connected" @connectWallet="connectWallet" />
  <div class="custom-container">
    <div class="container">
      <dashboard-header :usdPerMatic="usdPerMatic" />
      <div v-if="!renderCard"><playermon-sold class="playermon-container" v-for="playermon in playermonsSold" :key="playermon.token_id" :playermon="playermon" :usdPerMatic="usdPerMatic" style="margin-bottom: 16px" /></div>
      <div v-else class="playermon-container">
        <playermon-sold-card v-for="playermon in playermonsSold" :key="playermon.token_id" :playermon="playermon" :usdPerMatic="usdPerMatic" />
      </div>
      <bottom-page-button v-if="playermonsSold.length > 0" @onLeft="onLeft" @onRight="onRight" :currentPage="currentPage" :totalPage="totalPage" />
    </div>
  </div>
</template>

<script>
import MarketplaceNavBar from '../../../components/MarketplaceNavBar.vue';
import { onBeforeMount, onMounted, ref } from '@vue/runtime-core';
import DashboardHeader from './components/DashboardHeader.vue';
import { getRecentSold } from '../src/marketplaceApi';
import { useStore } from 'vuex';
import PlayermonSold from './components/PlayermonSold.vue';
import { useRoute, useRouter } from 'vue-router';
import BottomPageButton from '../../../components/BottomPageButton.vue';
import { ethers } from 'ethers';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';
import ERC20 from '../../../assets/abi/ERC20.json';
import axios from 'axios';
import PlayermonSoldCard from './components/PlayermonSoldCard.vue';

export default {
  components: { MarketplaceNavBar, DashboardHeader, BottomPageButton, PlayermonSold, PlayermonSoldCard },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const connected = ref(false);
    const pym = ref(0);
    const matic = ref(0);
    const sgem = ref(0);
    const usdPerMatic = ref(1);

    const currentPage = ref(1);
    const totalPage = ref(1);
    const playermonsSold = ref([]);

    const renderCard = ref(false);

    onMounted(async () => {
      const { page } = route.query;
      if (page) {
        const queryPage = parseInt(page);
        if (queryPage > 0) {
          currentPage.value = queryPage;
        }
      }
      await getPlayermonsSold(currentPage.value);
      await connectWallet();
      renderCard.value = window.innerWidth > 991 ? false : true;
    });

    onBeforeMount(async () => {
      await getUsdPerMatic();
    });

    const getPlayermonsSold = async (page) => {
      try {
        const response = await getRecentSold({ page });
        if (response.status) {
          const { docs, total_pages } = response.data;
          playermonsSold.value = docs;
          totalPage.value = total_pages;
        } else {
          store.dispatch('globalAlert/show', { type: 'alert', message: response.message });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const getUsdPerMatic = async () => {
      try {
        await store.dispatch('loading/setLoading', true);
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=playermon&vs_currencies=usd');
        usdPerMatic.value = response.data['playermon'].usd;
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error(e);
      }
    };

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = window.ethereum;
      const signer = ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      if (!provider) {
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await changeNetwork();
          await store.dispatch('loading/setLoading', false);
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });
          connected.value = true;
          const userAddress = await signer.getAddress();

          const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
          const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
          pym.value = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
          const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
          sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          const rawMatic = await signer.getBalance();
          const readableMatic = new Fraction(JSBI.BigInt(rawMatic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
          matic.value = readableMatic;

          await store.dispatch('loading/setLoading', false);
        }
      } catch (e) {
        connected.value = false;
        await store.dispatch('loading/setLoading', false);
        console.error('Metamask', e);
      }
    };

    const changeNetwork = async () => {
      const provider = window.ethereum;
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(137).toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    };

    const onLeft = async () => {
      if (currentPage.value >= 1) {
        currentPage.value -= 1;
        router.push({ name: 'Dashboard', query: { page: currentPage.value } });
        await getPlayermonsSold(currentPage.value);
      }
    };

    const onRight = async () => {
      if (totalPage.value > 1) {
        currentPage.value += 1;
        router.push({ name: 'Dashboard', query: { page: currentPage.value } });
        await getPlayermonsSold(currentPage.value);
      }
    };

    return { currentPage, playermonsSold, totalPage, onLeft, onRight, pym, matic, sgem, usdPerMatic, connected, connectWallet, renderCard };
  },
};
</script>

<style scoped>
.playermon-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
</style>
