<template>
  <marketplace-nav-bar :matic="matic" :pym="pym" :sgem="sgem" />
  <div class="sys-container">
    <div class="staking">
      <breeding-pot-staking-card deposit="SGEM USDT LP" pool="0" />
      <breeding-pot-staking-card deposit="PYM USDT LP" pool="1" />
      <breeding-pot-staking-card deposit="PYM" pool="2" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';
import MarketplaceNavBar from '../../../components/MarketplaceNavBar.vue';
import BreedingPotStakingCard from './components/BreedingPot/BreedingPotStakingCard.vue';
import { ethers } from 'ethers';
import ERC20 from '../../../assets/abi/ERC20.json';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';
import { useStore } from 'vuex';

export default {
  components: { MarketplaceNavBar, BreedingPotStakingCard },
  setup() {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    const store = useStore();
    const pym = ref(0);
    const sgem = ref(0);
    const matic = ref(0);

    onMounted(async () => {
      await connectWallet();
    });

    const getMatic = async () => {
      await store.dispatch('loading/setLoading', true);
      const signer = await ethersProvider.getSigner();
      const matic = await signer.getBalance();
      const readableMatic = new Fraction(JSBI.BigInt(matic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
      await store.dispatch('loading/setLoading', false);
      return readableMatic;
    };

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.log('No Metamask');
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await changeNetwork();
          await store.dispatch('loading/setLoading', false);
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });
          const userAddress = await signer.getAddress();

          const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
          const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
          matic.value = await getMatic();

          const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
          const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
          sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          const readablePYM = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          pym.value = readablePYM;
        }
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.log('Metamask', e);
        // this.noMetaMaskAlert = 'Please unlock and sign the message on your MetaMask';
      }
    };

    const changeNetwork = async () => {
      const provider = window.ethereum;
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(137).toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    };

    return { pym, sgem, matic };
  },
};
</script>

<style scoped>
.staking {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
</style>
