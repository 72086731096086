<template>
  <div style="background-color: #0a105a">
    <NavBar />
    <container style="padding: 0; margin: 0; max-width: 100%">
      <img style="height: 100%; width: 100%" src="../assets/backgrounds/playermon_tokenomics.png" />
    </container>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'Tokenomics',
  components: {
    Footer,
    NavBar,
  },
};
</script>
