<template>
  <div class="breeding-pot-modal" :class="visible ? 'show' : 'hide'">
    <div class="modal-content">
      <div class="d-flex justify-content-between align-items-center">
        <h5>Breeding Pod</h5>
        <mdicon class="icon" name="close" @click="close" />
      </div>
      <stake v-if="isStake" style="margin-top: 16px" :currency="currency" @cancel="cancel" @confirm="stake" :balance="lpToken" />
      <unstake v-else-if="isUnStake" style="margin-top: 16px" :currency="currency" @cancel="cancel" @confirm="unstake" :balance="staked" />
      <balance v-else :image="icon" :balance="readableEarnPod" :harvestable="0" />
      <button v-if="!isApproved" class="btn approve-btn" @click="enableFarm">Enable Farm</button>
      <div v-if="isApproved && !isStake && !isUnStake" class="d-flex justify-content-between">
        <button class="btn btn-outline-secondary" @click="gotoUnstake">Unstake</button>
        <button class="btn harvest-btn" :disabled="earnPod <= 0" @click="harvest">Harvest</button>
        <button class="btn btn-primary" @click="gotoStake">Stake</button>
      </div>
      <a v-if="msg" style="color: green; margin-bottom: 16px; word-break: break-word; cursor: pointer" @click="gotoPolygonScan(msg)">Transaction hash: {{ msg }}</a>
    </div>
    <div class="overlay-background" :class="visible ? 'show' : 'hide'" @click="close" />
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import Balance from '../Balance.vue';
import Stake from '../Stake.vue';
import Unstake from '../Unstake.vue';
import ERC20 from '../../../../../assets/abi/ERC20.json';
import BreedingPodShardMiner from '../../../../../assets/abi/BreedingPodShardMiner.json';
import { onMounted } from '@vue/runtime-core';
import { ethers } from 'ethers';

export default {
  components: { Stake, Unstake, Balance },
  props: ['modalData', 'visible', 'pool', 'currency', 'lpToken', 'staked', 'earnPod', 'readableEarnPod'],
  setup(props, context) {
    const isStake = ref(false);
    const icon = ref('');
    const isUnStake = ref(false);
    const isApproved = ref(false);
    const msg = ref('');
    const { pool } = toRefs(props);

    onMounted(async () => {
      await connectWallet();
      icon.value = getIcon(pool.value);
    });

    const close = () => {
      console.log('close');
      context.emit('close');
    };

    const getLpAddress = (pool) => {
      switch (pool) {
        case '0':
          return '0xe6191476b72152a43c04769e3d76c285c2a89145';
        case '1':
          return '0xd236ca19caa7f8ed19797a5fff7e89e256674904';
        case '2':
          return '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41';

        default:
          return '-';
      }
    };

    const getIcon = (pool) => {
      switch (pool) {
        case '0':
          return require('../../../../../assets/sgemUsdtBreedingPodShard.png');
        case '1':
          return require('../../../../../assets/pymUsdtBreedingPodShard.png');
        case '2':
          return require('../../../../../assets/pymBreedingPodShard.png');

        default:
          return '-';
      }
    };

    const connectWallet = async () => {
      const provider = window.ethereum;
      if (!provider) {
        console.log('No Metamask');
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await ethersProvider.getSigner();
      try {
        await provider.request({
          method: 'eth_requestAccounts',
        });
        const userAddress = await signer.getAddress();
        const userNetwork = await signer.getChainId();

        const erc20Contract = new ethers.Contract(userNetwork !== 137 && pool.value === '2' ? '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a' : getLpAddress(pool.value), ERC20.abi, signer);
        const erc20Allowance = await erc20Contract.allowance(userAddress, userNetwork === 137 ? '0x21c6cc8e06f07523b1166928cac5db7310250978' : '0xaa0c7648002c354DCBa1739bdab0697b7c595975');
        isApproved.value = erc20Allowance.gt('2000000000000000000000');
      } catch (e) {
        console.log('Metamask', e);
      }
    };

    const enableFarm = async () => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const erc20Contract = new ethers.Contract(userNetwork !== 137 && pool.value === '2' ? '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a' : getLpAddress(pool.value), ERC20.abi, signer);
        const tx = await erc20Contract.approve(userNetwork === 137 ? '0x21c6cc8e06f07523b1166928cac5db7310250978' : '0xaa0c7648002c354DCBa1739bdab0697b7c595975', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
        const receipt = await tx.wait();
        isApproved.value = true;
        console.log(receipt.status);
      } catch (e) {
        console.error(e);
      }
    };

    const cancel = () => {
      isStake.value = false;
      isUnStake.value = false;
    };
    const gotoStake = () => (isStake.value = true);
    const gotoUnstake = () => (isUnStake.value = true);

    const harvest = async () => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const userAddress = await signer.getAddress();
        const breedingPodShardMinerContract = new ethers.Contract(userNetwork === 137 ? '0x21c6cc8e06f07523b1166928cac5db7310250978' : '0xaa0c7648002c354DCBa1739bdab0697b7c595975', BreedingPodShardMiner.abi, signer);
        const tx = await breedingPodShardMinerContract.harvest(userAddress, userNetwork === 137 ? pool.value : '0');
        msg.value = tx.hash;
        await tx.wait();
        context.emit('refreshBalance');
        msg.value = '';
      } catch (e) {
        console.error(e);
      }
    };

    const stake = async (value) => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const breedingPodShardMinerContract = new ethers.Contract(userNetwork === 137 ? '0x21c6cc8e06f07523b1166928cac5db7310250978' : '0xaa0c7648002c354DCBa1739bdab0697b7c595975', BreedingPodShardMiner.abi, signer);
        const tx = await breedingPodShardMinerContract.deposit(userNetwork === 137 ? pool.value : '0', ethers.utils.parseEther(value));
        msg.value = tx.hash;
        await tx.wait();
        context.emit('refreshBalance');
        msg.value = '';
      } catch (e) {
        console.error(e);
      }
    };

    const unstake = async (value) => {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await ethersProvider.getSigner();
        const userNetwork = await signer.getChainId();
        const breedingPodShardMinerContract = new ethers.Contract(userNetwork === 137 ? '0x21c6cc8e06f07523b1166928cac5db7310250978' : '0xaa0c7648002c354DCBa1739bdab0697b7c595975', BreedingPodShardMiner.abi, signer);
        const tx = await breedingPodShardMinerContract.withdraw(userNetwork === 137 ? pool.value : '0', ethers.utils.parseEther(value));
        msg.value = tx.hash;
        await tx.wait();
        context.emit('refreshBalance');
        msg.value = '';
      } catch (e) {
        console.error(e);
      }
    };

    const gotoPolygonScan = async (hash) => {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const url = userNetwork === 137 ? `https://polygonscan.com/tx/${hash}` : `https://mumbai.polygonscan.com/tx/${hash}`;
      window.open(url, '_blank');
    };

    return { close, isStake, isUnStake, isApproved, enableFarm, gotoStake, gotoUnstake, stake, unstake, harvest, cancel, msg, gotoPolygonScan, icon };
  },
};
</script>

<style scoped>
.breeding-pot-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10001;
}
.breeding-pot-modal.show {
  opacity: 1;
  transition: top 0.3s ease, opacity 0.5s ease;
}
.breeding-pot-modal.hide {
  top: -100%;
  opacity: 0;
  transition: top 0.5s ease, opacity 0.1s ease;
}

.modal-content {
  width: calc(100% - 32px);
  max-width: 420px;
  max-height: 500px;
  position: absolute;
  left: 50%;
  top: 40%;
  padding: 32px;
  border-radius: 16px;
  border: 2px solid #ffffff;
  color: whitesmoke;
  background: var(--main-background-color);
  transform: translate(-50%, -50%);
  z-index: 10003;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modal-content h5 {
  font-weight: 800;
  margin: 0;
}

.modal-content .icon {
  cursor: pointer;
}

.btn {
  color: whitesmoke;
  padding: 12px 24px;
}

.approve-btn {
  width: 100%;
  padding: 8px;
  margin-top: 16px;
  color: whitesmoke;
  background: var(--sub-background-color);
}

.approve-btn:hover {
  background: #ffffff25;
}

.harvest-btn {
  color: black;
  background: gold;
}

.harvest-btn:hover {
  color: whitesmoke;
  background: #ffd70075;
}

.overlay-background {
  background: #00000075;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10002;
}
.overlay-background.hide {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.overlay-background.hide {
  opacity: 0;
}
</style>
