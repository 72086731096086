<template>
  <div class="staking-card">
    <img class="img-fluid" :src="image" width="180" />
    <h6>Breeding Pod Shard</h6>
    <single-list-item title="Deposit:" :metadata="deposit" metadataConfig="font-weight: bold; text-transform: uppercase" />
    <single-list-item title="Earn:" :metadata="earn" metadataConfig="font-weight: bold; text-transform: uppercase; font-size: 14px" />
    <single-list-item title="Allocation:" :metadata="amount" metadataConfig="font-weight: bold; text-transform: uppercase" style="margin-top: 32px" />
    <single-list-item title="Available:" :metadata="readableLpToken" metadataConfig="font-weight: bold; text-transform: uppercase" style="margin-top: 32px" />
    <single-list-item title="Staked:" :metadata="`${staked}`" metadataConfig="font-weight: bold; text-transform: uppercase" />
    <button class="btn" @click="getLp">Get Token</button>
    <button class="btn" @click="select">Select Pool</button>
  </div>
</template>

<script>
import { toRefs } from '@vue/reactivity';
import SingleListItem from '../../../../components/SingleListItem.vue';
export default {
  components: { SingleListItem },
  name: 'staking-card',
  props: ['stakingData', 'title', 'staked', 'readableLpToken', 'pool'],
  setup(props, context) {
    const { stakingData, pool } = toRefs(props);
    const { deposit, earn, image, amount, amountCurrency, duration } = stakingData.value;

    const select = () => {
      context.emit('select-nft');
    };

    const getLpAddress = (pool) => {
      switch (pool) {
        case '0':
          return 'https://quickswap.exchange/#/add/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549';
        case '1':
          return 'https://quickswap.exchange/#/add/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/0x0bD49815EA8e2682220BCB41524c0dd10Ba71d41';
        case '2':
          return 'https://quickswap.exchange/#/swap?outputCurrency=0x0bD49815EA8e2682220BCB41524c0dd10Ba71d41';

        default:
          return '-';
      }
    };

    const getLp = () => {
      window.open(getLpAddress(pool.value), '_blank');
    };

    return { deposit, earn, image, amount, amountCurrency, duration, select, getLp };
  },
};
</script>

<style scoped>
.staking-card {
  border: 1px solid #ffffff75;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 280px;
  background: #00000035;
}

.staking-card h6 {
  margin-top: 16px;
  margin-bottom: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn {
  width: 100%;
  padding: 8px;
  margin-top: 16px;
  color: whitesmoke;
  background: var(--sub-background-color);
}

.btn:hover {
  background: #ffffff25;
}
</style>
