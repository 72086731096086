<template>
  <MarketplaceNavBar :matic="matic" :pym="pym" :sgem="sgem" />
  <div class="inventory sys-container">
    <TabLink style="border-bottom: 1px solid #ffffff26" />
    <div v-if="currentTab === 'playermon'">
      <div v-if="playermons.length <= 0" style="display: flex; justify-content: center">
        <EmptyPlayermon />
      </div>
      <div v-else>
        <!-- <div class="filter-container">
        <div class="filter-button">Filter</div>
      </div> -->
        <div class="playermons-container">
          <PlayermonCard v-for="item in playermons" :key="item.id" :id="item.id" :breedCount="item.breedCount" :img="item.img" :icon="item.icon" :disabled="false" />
        </div>
      </div>
      <BottomPageButton v-if="playermons.length > 0" @onLeft="onLeft" @onRight="onRight" :currentPage="page" :totalPage="totalPage" />
    </div>
    <div v-if="currentTab === 'crafting material'" style="display: flex">
      <crafting-material />
    </div>
    <div v-if="currentTab === 'breeding pod'" style="display: flex">
      <breeding-pod />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { ethers } from 'ethers';
import { useStore } from 'vuex';
import axios from 'axios';
import { JSBI, Fraction } from '@pancakeswap-libs/sdk';

import ERC20 from '../assets/abi/ERC20.json';
import PlayermonNFT from '../assets/abi/PlayermonNFT.json';

// @ is an alias to /src
import BottomPageButton from '@/components/BottomPageButton.vue';
import EmptyPlayermon from '@/components/Inventory/EmptyPlayermon.vue';
import MarketplaceNavBar from '@/components/MarketplaceNavBar.vue';
import PlayermonCard from '@/components/PlayermonCard.vue';
import TabLink from '@/components/Inventory/TabLink.vue';
import CraftingMaterial from '../modules/crafting-material/views/CraftingMaterial.vue';
import BreedingPod from '../modules/breeding-pod/views/BreedingPod.vue';

export default {
  name: 'Inventory',
  components: {
    BottomPageButton,
    EmptyPlayermon,
    MarketplaceNavBar,
    PlayermonCard,
    TabLink,
    CraftingMaterial,
    BreedingPod,
  },
  computed: {
    currentTab() {
      return this.$store.state.inventory.currentTab;
    },
  },
  setup() {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    const store = useStore();
    const playermonsPerPage = 10;

    const userWalletAddress = ref('');
    const connected = ref(false);
    const playermons = ref([]);
    const matic = ref(0);
    const sgem = ref(0.0);
    const pym = ref(0);
    const page = ref(1);
    const totalPage = ref(1);
    const totalPlayermons = ref(0);

    onMounted(async () => {
      await connectWallet();
      matic.value = await getMatic();
    });

    const getMatic = async () => {
      const signer = await ethersProvider.getSigner();
      const matic = await signer.getBalance();
      const readableMatic = new Fraction(JSBI.BigInt(matic), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
      return readableMatic;
    };

    const getPlayermonsMetadata = async () => {
      await store.dispatch('loading/setLoading', true);
      const playermonsArray = [];
      const signer = ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const nftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, signer);
      let i = page.value * playermonsPerPage - playermonsPerPage;
      const currentPagePlayermons = page.value * playermonsPerPage;
      const max = currentPagePlayermons < totalPlayermons.value ? currentPagePlayermons : totalPlayermons.value;
      for (i; i < max; i++) {
        const id = await nftContract.tokenOfOwnerByIndex(userWalletAddress.value, i);
        const metadata = await axios.get(userNetwork === 137 ? `https://metadata.playermon.com/playermon/${id}` : `https://metadata.playermon.com/playermon/${id}`);
        let playermonClassIcon = '';
        if (metadata.data.attributes) {
          const rawClass = metadata.data.attributes.find((attribute) => attribute.trait_type === 'class');
          if (rawClass.value !== 'Egg') {
            playermonClassIcon = require(`../assets/${rawClass.value}.png`);
          }
        }
        playermonsArray.push({
          id: id.toString(),
          breedCount: 0,
          price: 0.01,
          usd: 87,
          img: metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'),
          icon: playermonClassIcon,
        });
      }
      playermons.value = playermonsArray;
      await store.dispatch('loading/setLoading', false);
    };

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      const signer = ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      if (!provider) {
        this.noMetaMaskAlert = 'No Metamask';
        return false;
      }
      try {
        if (userNetwork !== 137 && userNetwork !== 80001) {
          await changeNetwork();
          await store.dispatch('loading/setLoading', false);
        } else {
          await provider.request({
            method: 'eth_requestAccounts',
          });

          connected.value = true;
          const userAddress = await signer.getAddress();
          userWalletAddress.value = userAddress;

          const pymContract = new ethers.Contract(userNetwork === 137 ? '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41' : '0xa4e2bac6ca3236dc53c42620e2cdb160a3dac74a', ERC20.abi, signer);
          const nftContract = new ethers.Contract(userNetwork === 137 ? '0x4e72439d00f0031bd88447e5505597e4de86407a' : '0x465942De0cDacF2701041ec54D6f2491Cde94505', PlayermonNFT.abi, signer);

          const userNFTBalance = await nftContract.balanceOf(userAddress);
          totalPlayermons.value = parseInt(userNFTBalance.toString());
          totalPage.value = Math.ceil(totalPlayermons.value / playermonsPerPage);

          await getPlayermonsMetadata();

          const userPYMBalanceAmount = await pymContract.balanceOf(userAddress);
          pym.value = new Fraction(JSBI.BigInt(userPYMBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);

          const spaceGemContract = new ethers.Contract(userNetwork === 137 ? '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549' : '0x439f8892304f5359A0fF61404F3689EC0FBDea54', ERC20.abi, signer);
          const spaceGemBalanceAmount = await spaceGemContract.balanceOf(userAddress);
          sgem.value = new Fraction(JSBI.BigInt(spaceGemBalanceAmount), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
          await store.dispatch('loading/setLoading', false);
        }
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        connected.value = false;
        console.error('Metamask', e);
      }
    };

    const changeNetwork = async () => {
      const provider = window.ethereum;
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(137).toString(16)}`,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'matic',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: [`https://polygonscan.com/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      } else {
        console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined");
        return false;
      }
    };

    const onLeft = async () => {
      if (page.value > 1) {
        page.value -= 1;
        await getPlayermonsMetadata();
      }
    };

    const onRight = async () => {
      if (totalPage.value > page.value) {
        page.value += 1;
        await getPlayermonsMetadata();
      }
    };

    return {
      playermons,
      sgem,
      pym,
      page,
      totalPage,
      matic,
      onLeft,
      onRight,
      connectWallet,
      changeNetwork,
    };
  },
  data: () => ({
    TabLink,
  }),
};
</script>

<style scoped>
.filter-container {
  display: flex;
  justify-content: flex-end;
}

.filter-button {
  border: 1px solid #ffffff26;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.filter-button:hover {
  border-color: #ffffff50;
}

.playermons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 16px;
}
</style>
