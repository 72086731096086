export const numberWithComma = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const CLASS_COLOR = {
  aqua: '#00FFFF',
  nature: '#228B22',
  comet: '#9932CC',
  inferno: '#FF4500',
  mythos: '#FFC100',
  mech: '#C0C0C0',
};

const CLASS_IMAGE = {
  aqua: 'Aqua.png',
  nature: 'Nature.png',
  comet: 'Comet.png',
  inferno: 'Inferno.png',
  mythos: 'Mythos.png',
  mech: 'Mech.png',
};

export const classColorMapper = (value) => {
  const playermonClass = value.toLocaleLowerCase();
  return CLASS_COLOR[playermonClass];
};

export const classImageMapper = (value) => {
  const playermonClass = value.toLocaleLowerCase();
  return CLASS_IMAGE[playermonClass];
};

const RARITY_PLAYERMON = {
  Common: 0,
  Uncommon: 1,
  Rare: 2,
  Epic: 3,
  Legendary: 4,
};

export const rarityMapper = (rarities) => {
  if (Array.isArray(rarities)) {
    return rarities.map((rarity) => RARITY_PLAYERMON[rarity]);
  } else {
    return RARITY_PLAYERMON[rarities];
  }
};
