<template>
  <div style="display: flex; margin: 32px 0; justify-content: center; align-items: center">
    <button type="button" class="button btn btn-outline-secondary" @click="onLeft" :disabled="currentPage === 1">
      <mdicon name="chevron-left" size="18" />
    </button>
    <div style="display: flex; align-items: center; font-size: 12px">
      <div class="pageText">Page {{ currentPage }}</div>
    </div>
    <button type="button" class="button btn btn-outline-secondary" @click="onRight" :disabled="currentPage >= totalPage">
      <mdicon name="chevron-right" size="18" />
    </button>
  </div>
</template>

<script>
export default {
  props: ['currentPage', 'totalPage'],
  methods: {
    onLeft() {
      this.$emit('onLeft');
    },

    onRight() {
      this.$emit('onRight');
    },
  },
};
</script>

<style scoped>
.button {
  padding: 0 8px;
  border: 2px solid #ffffff;
  border-radius: 12px;
  margin: 0px 8px;
  background: var(--main-background-color);
}

.pageText {
  background: var(--main-background-color);
  padding: 4px 16px;
  border: 2px solid #ffffff;
  border-radius: 12px;
  margin: 0 8px;
}
</style>
