const initialState = {
  loading: false,
};

export const loading = {
  namespaced: true,
  state: initialState,
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    setLoading({ commit }, loading) {
      commit('setLoading', loading);
    },
  },
};
