<template>
  <div class="balance">
    <img :src="image" width="150" />
    <div class="harvestable">Earn</div>
    <h1>{{ balance }}</h1>
    <!-- <div class="harvestable">Harvestable: {{ harvestable }}</div> -->
  </div>
</template>

<script>
export default {
  name: 'staking-balance',
  props: ['image', 'balance', 'harvestable'],
};
</script>

<style scoped>
.balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;
}

img {
  margin-bottom: 16px;
}

.subtitle {
  font-size: 14px;
  color: #ffffff65;
}

.harvestable {
  font-size: 16px;
  color: gold;
}
</style>
