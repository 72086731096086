<template>
  <nav id="nav" class="navbar navbar-dark nav-background">
    <div class="navbar-brand" style="display: flex">
      <a href="/">
        <img class="img-fluid" src="../assets/horizontal_logo.png" alt="" style="height: 35px" />
      </a>
      <div class="marketplace" @click="routeTo({ url: '/dashboard' })" :class="currentRoute.toLowerCase() === '/dashboard' ? 'active' : ''">
        <div style="padding-bottom: 4px">
          <mdicon name="view-dashboard" :width="20" />
        </div>
        <span style="padding-top: 2px; padding-left: 4px; font-size: 14px">Dashboard</span>
      </div>
      <div class="marketplace" @click="routeTo({ url: '/marketplace' })" :class="currentRoute.toLowerCase() === '/marketplace' ? 'active' : ''">
        <div style="padding-bottom: 4px">
          <mdicon name="store" :width="20" />
        </div>
        <span style="padding-top: 2px; padding-left: 4px; font-size: 14px">Radix Marketplace</span>
      </div>
    </div>
    <div v-if="noSidebar" class="nav-balance" style="display: flex; font-size: 14px; color: #ffffff; margin-left: auto">
      <div v-if="connected" style="display: flex; gap: 16px; margin-top: 8px">
        <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 8px">
          <div style="margin-right: 4px; text-align: right">{{ pym }}</div>
          <img class="balance-logo" src="../assets/logo.png" width="18" />
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 8px">
          <div style="margin-right: 4px; text-align: right">{{ sgem }}</div>
          <img class="balance-logo" src="../assets/sgem_icon.png" width="18" />
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 8px">
          <div style="margin-right: 4px; text-align: right">{{ matic }}</div>
          <img class="balance-logo" src="../assets/matic-token-icon.png" width="18" />
        </div>
      </div>
      <div v-else>
        <button type="button" class="btn btn-secondary-outline" style="color: whitesmoke; font-size: 12px; border: 1px solid whitesmoke" @click="connectWallet">Connect wallet</button>
      </div>
    </div>
    <div v-if="jwt" class="account" @click="routeTo({ url: 'Inventory' })" :class="currentRoute.toLowerCase() !== '/marketplace' ? 'active' : ''">
      <div style="padding-bottom: 4px">
        <mdicon name="account-circle" :width="20" />
      </div>
      <span style="padding-left: 4px; font-size: 14px">My account</span>
    </div>
    <div v-if="!jwt" class="account" @click="routeTo({ url: 'Login' })" :class="currentRoute.toLowerCase() !== '/marketplace' ? 'active' : ''">
      <div style="padding-bottom: 4px">
        <mdicon name="account-circle" :width="20" />
      </div>
      <span style="padding-left: 4px; font-size: 14px">Login</span>
    </div>
    <a class="btn custom-button" @click="toggleSideBar"><span class="navbar-toggler-icon"></span></a>
  </nav>
  <div :class="noSidebar ? 'hide' : ''">
    <div :class="isToggleSideBar ? 'active-nav' : ''" class="side-navbar d-flex justify-content-between flex-column custom-nav" id="sidebar" style="overflow-y: auto">
      <div>
        <div class="marketplace side-bar" @click="routeTo({ url: '/dashboard' })" :class="currentRoute.toLowerCase() === '/dashboard' ? 'active' : ''">
          <div style="padding-bottom: 4px">
            <mdicon name="view-dashboard" :width="20" />
          </div>
          <span style="padding-top: 2px; padding-left: 4px; font-size: 14px">Dashboard</span>
        </div>
        <div class="marketplace side-bar" @click="routeTo({ url: '/Marketplace' })">
          <div style="padding-bottom: 4px">
            <mdicon name="store" :width="20" />
          </div>
          <span style="padding-top: 2px; padding-left: 4px">Radix Marketplace</span>
        </div>
        <div class="my-account-label">My Account</div>
        <div class="qr-container">
          <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 32px">
            <div style="display: flex">
              <span style="font-size: 18px">{{ name }}</span>
              <div @click="openUpdateNameModal" style="margin-left: 4px; cursor: pointer">
                <mdicon name="file-edit-outline" :width="15" />
              </div>
            </div>
            <!-- <span style="font-size: 14px; font-weight: 300; color: #ffffff75">email@xxx.com here</span> -->
            <div class="show-qr" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <div style="color: whitesmoke; margin-right: 8px">
                <mdicon name="qrcode-scan" :width="20" />
              </div>
              <span style="margin-top: 2px">Show QR</span>
            </div>
          </div>
          <div style="display: flex; align-items: center; width: 150px; justify-content: space-between; margin-bottom: 8px">
            <img class="balance-logo" src="../assets/logo.png" width="25" />
            <div style="margin-left: 4px; text-align: right">{{ pym }} PYM</div>
          </div>
          <div style="display: flex; align-items: center; width: 150px; justify-content: space-between; margin-bottom: 8px">
            <img class="balance-logo" src="../assets/sgem_icon.png" width="25" />
            <div style="margin-left: 4px; text-align: right">{{ sgem }} SGEM</div>
          </div>
          <div style="display: flex; align-items: center; width: 150px; justify-content: space-between; margin-bottom: 8px">
            <img class="balance-logo" src="../assets/matic-token-icon.png" width="25" />
            <div style="margin-left: 4px; text-align: right">{{ matic }} MATIC</div>
          </div>
        </div>
        <ul class="nav flex-column text-white w-100">
          <li v-for="item in routes" :key="item.name">
            <a class="nav-link" :class="currentRoute === item.url ? 'active' : ''" @click="routeTo(item)" aria-current="page">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <a class="nav-link" @click="logout" style="color: crimson; margin-bottom: 10px; border-top: 1px solid #333">
        <mdicon name="export" />
        <div style="padding-top: 3px; margin-left: 5px">Log out</div>
      </a>
      <QRModal />
      <update-name-modal :show="showUpdateNameModal" @close="closeUpdateNameModal" :name="name" @rename="rename" />
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import QRModal from '@/components/QRModal';
import UpdateNameModal from './UpdateNameModal.vue';
import { ref } from '@vue/reactivity';
import UserService from '../services/userService';
import { useStore } from 'vuex';
import authService from '../services/authService';
// import authService from '../services/authService';

export default {
  name: 'MarketplaceNavBar',
  props: ['noSidebar', 'matic', 'sgem', 'pym', 'connected'],
  components: {
    QRModal,
    UpdateNameModal,
  },
  data: () => ({
    isToggleSideBar: false,
    routes: [
      {
        name: 'inventory',
        url: '/Inventory',
      },
      {
        name: 'claim tokens',
        url: '/ClaimTokens',
      },
      {
        name: 'PYM Tokenomics',
        url: '/PYMTokenomics',
        params: {
          fromMarketplace: true,
        },
      },
      {
        name: 'Staking',
        url: '/Staking',
      },
      {
        name: 'Crafting',
        url: '/Crafting',
      },
      {
        name: 'Download Now',
        url: '/download',
      },
    ],
  }),
  setup(props, context) {
    const username = localStorage.getItem('username') ? JSON.parse(localStorage.getItem('username')) : 'Sir';
    const jwt = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const name = ref(store.state.auth.username || username);
    const showUpdateNameModal = ref(false);

    const openUpdateNameModal = () => {
      showUpdateNameModal.value = true;
    };

    const closeUpdateNameModal = () => {
      showUpdateNameModal.value = false;
    };

    const connectWallet = () => {
      context.emit('connectWallet');
    };

    const routeTo = (item) => {
      if (item.params) {
        router.push({ path: item.url, query: item.params });
      } else {
        router.push(item.url);
      }
    };
    const logout = async () => {
      try {
        const response = await authService.logout();
        if (response.data.success) {
          localStorage.clear();
          routeTo({ url: '/login' });
        }
      } catch (e) {
        console.error(e);
      }
    };
    const rename = async (value) => {
      try {
        name.value = value;
        const response = await UserService.updateUsername(value);
        if (response.data.status > 0) {
          localStorage.setItem('username', JSON.stringify(value));
          showUpdateNameModal.value = false;
        }
      } catch (e) {
        console.error(e);
      }
    };
    return {
      jwt,
      currentRoute: route.path,
      routeTo,
      logout,
      name,
      rename,
      connectWallet,
      showUpdateNameModal,
      openUpdateNameModal,
      closeUpdateNameModal,
    };
  },
  methods: {
    toggleSideBar() {
      this.isToggleSideBar = !this.isToggleSideBar;
    },
  },
};
</script>

<style scoped>
.nav-background {
  background-color: var(--main-background-color);
  z-index: 9999;
  padding: 0;
  padding-left: 4px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  padding: 0;
  height: 61px;
}

.account,
.marketplace {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 0 16px;
  cursor: pointer;
  height: 61px;
}

.account {
  color: whitesmoke;
  padding-right: 16px;
}

.marketplace.active {
  background: var(--sub-background-color);
}

.my-account-label {
  display: none;
  margin-left: 16px;
  font-size: 14px;
  color: #ffffff75;
}

.side-bar {
  display: none;
}

.hide {
  display: none;
}

@media only screen and (max-width: 767px) {
  .custom-button {
    display: block !important;
  }

  .account,
  .marketplace {
    display: none;
  }

  .hide {
    display: block !important;
  }

  .side-bar {
    display: flex;
    margin-left: 16px;
    color: whitesmoke;
  }

  .my-account-label {
    margin-top: 16px;
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .custom-nav {
    margin-left: 0 !important;
  }
}

.custom-button {
  display: none;
}

.side-navbar {
  width: var(--side-bar-width);
  height: 100%;
  position: absolute;
  margin-top: -61px;
  padding-top: 80px;
  margin-left: var(--side-bar-margin-left);
  background-color: var(--main-background-color);
  transition: 0.5s;
  z-index: 9998;
  text-align: left;
  box-shadow: 1px 0px 15px var(--main-background-color);
}

@media screen and (max-width: 476px) {
  .side-navbar {
    width: 100%;
    margin-left: -100%;
  }
}

.nav-link {
  display: flex;
  margin: 0 15px;
  cursor: pointer;
  color: whitesmoke;
  text-transform: capitalize;
  border-radius: 5px;
  margin-bottom: 8px;
}

.active {
  background-color: #ffffff15;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #ffffff26;
}

.active-nav {
  margin-left: 0;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px;
  margin-top: 8px;
  padding: 24px 16px;
  border-radius: 8px;
  border: 1px solid #ffffff26;
  color: whitesmoke;
}

.show-qr {
  display: flex;
  margin-top: 8px;
  padding: 8px 16px;
  border: 1px solid #ffffff50;
  border-radius: 8px;
  cursor: pointer;
}

.show-qr:hover {
  border-color: #ffffff80;
}

::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width: 768px) {
  .nav-balance {
    display: none !important;
  }
}
</style>
