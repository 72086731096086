<template>
  <div v-if="loading" class="loading-background">
    <span class="spinner-border text-info spinner-border-xl" role="status" aria-hidden="true"></span>
  </div>
</template>

<script>
export default {
  components: 'Loading',
  computed: {
    loading() {
      return this.$store.state.loading.loading;
    },
  },
};
</script>

<style scoped>
.loading-background {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 99999999999;
  background: #00000075;
}
</style>
