<template>
  <div class="link container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-5 col-md-12 col-sm-12 min-vh-100 mt-3">
        <div class="container d-flex flex-column text-white">
          <img class="mx-auto mb-1 mt-5" height="130" width="130" src="../assets/logo.png" />
          <h4 class="text-white">Playermon</h4>
          <p>
            Playermon is a space colonial game <br />where everyone can earn crypto currency<br />
            through skills and strategic
          </p>
          <div class="d-flex flex-row justify-content-center mb-4">
            <div role="button" class="me-3 pe-auto" @click="gotosite('https://link.playermon.com/twitter')">
              <img style="width: 32px" src="../assets/social/twitter.png" />
            </div>
            <div class="me-3 pe-auto" role="button" @click="gotosite('https://link.playermon.com/discord')">
              <img style="width: 32px" src="../assets/social/discord.png" />
            </div>
            <div class="me-3 pe-auto" role="button" @click="gotosite('https://link.playermon.com/telegram')">
              <img style="width: 32px" src="../assets/social/telegram.png" />
            </div>
            <div class="me-3 pe-auto" role="button" @click="gotosite('https://link.playermon.com/facebook')">
              <img style="width: 32px" src="../assets/social/facebook.png" />
            </div>
            <div class="me-3 pe-auto" role="button" @click="gotosite('https://link.playermon.com/instagram')">
              <img style="width: 32px" src="../assets/social/instagram.png" />
            </div>
            <div @click="gotosite('https://link.playermon.com/youtube')" role="button">
              <img style="width: 32px" src="../assets/social/youtube.png" />
            </div>
          </div>

          <a href="https://playermon.com" type="button" class="btn btn-lg mb-3">Website</a>
          <a href="https://playermon.com/marketplace" type="button" class="btn btn-lg mb-3">Buy NFT on Official Marketplace</a>
          <a href="https://opensea.io/collection/playermon-nft" type="button" class="btn btn-lg mb-3">Buy NFT on OpenSea</a>
          <a href="https://polygonscan.com/address/0x0bd49815ea8e2682220bcb41524c0dd10ba71d41" type="button" class="btn btn-lg mb-3">Project Token - $PYM</a>
          <a href="https://www.certik.com/projects/playermon" type="button" class="btn btn-lg mb-3">Audited by Certik</a>
          <a href="https://www.gate.io/trade/pym_usdt" type="button" class="btn btn-lg mb-3">Buy $PYM on Gate IO</a>
          <a href="https://www.mexc.com/exchange/PYM_USDT" type="button" class="btn btn-lg mb-3">Buy $PYM on MEXC</a>
          <a href="https://quickswap.exchange/#/swap?outputCurrency=0x0bd49815ea8e2682220bcb41524c0dd10ba71d41" type="button" class="btn btn-lg mb-3">Buy $PYM on Quickswap</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Link',
  methods: {
    gotosite(url) {
      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      );
    },
  },
};
</script>

<style scoped>
.link {
  background: #270656;
}

.btn {
  background-color: #8149cf;
  color: white;
}

.btn:hover {
  background-color: #663ba3;
  color: white;
}
</style>
