<template>
  <form>
    <div class="input-container">
      <single-list-item :title="title" :metadata="`Available: ${readableBalance} ${currency}`" style="color: #ffffff80" />
      <div class="d-flex justify-content-between align-items-center" style="margin: 24px 0 8px 0">
        <input type="number" min="1" :max="formattedBalance" placeholder="0" :value="inputValue" @input="onChange($event.target.value)" />
        <div class="max-btn" @click="max">Max</div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <button class="btn btn-outline-secondary" type="reset" @click="cancel">Cancel</button>
      <button class="btn btn-primary" type="reset" @click="confirm">Confirm</button>
    </div>
  </form>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import SingleListItem from '../../../../components/SingleListItem.vue';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';
import { ethers } from 'ethers';
export default {
  components: { SingleListItem },
  name: 'staking-input-form',
  props: ['title', 'currency', 'balance'],
  setup(props, context) {
    const inputValue = ref('');
    const { balance } = toRefs(props);

    const readableBalance = new Fraction(JSBI.BigInt(balance.value), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
    const formattedBalance = ethers.utils.formatEther(balance.value);
    const max = () => {
      inputValue.value = ethers.utils.formatEther(balance.value);
    };

    const confirm = () => {
      context.emit('confirm', inputValue.value);
      inputValue.value = '';
    };
    const cancel = () => {
      context.emit('cancel');
    };

    const onChange = (value) => {
      inputValue.value = value;
    };

    return { confirm, cancel, inputValue, onChange, max, readableBalance, formattedBalance };
  },
};
</script>

<style scoped>
.input-container {
  padding: 16px 24px;
  border: 1px solid #ffffff25;
  border-radius: 16px;
}

input {
  display: flex;
  outline: none;
  box-shadow: none;
  width: 80%;
  border: none;
  border-bottom: 1px solid #ffffff25;
  font-size: 24px;
  color: whitesmoke;
  background: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.max-btn {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: lightblue;
}

.max-btn:hover {
  color: lightskyblue;
}

.btn {
  padding: 12px 24px;
  margin-top: 24px;
  color: whitesmoke;
}

.btn-primary {
  border: none;
  background: var(--sub-background-color);
}

.btn:hover {
  background: #add8e675;
}
</style>
