<template>
  <div class="d-flex playermon-sold align-items-center" @click="onClick" style="gap: 16px">
    <div class="card-content d-flex align-items-center justify-content-between row">
      <div class="d-flex align-items-center col-lg-3 col-md-3 col-sm-3" style="gap: 16px">
        <img :src="playermon.playermon_image" width="100" />
        <div class="vertical-box">
          <div class="token-id" :style="`background: ${playermonClassColor}`">
            <div style="text-align: center"><img class="mb-1" :src="require(`@/assets/${playermonClassImage}`)" width="10" /> #{{ playermon.token_id }}</div>
          </div>
          <!-- <span style="text-align: left">Breed count: {{ playermon.breed_count }}</span> -->
        </div>
      </div>
      <div class="vertical-box col-lg-3 col-md-3 col-sm-3">
        <h6>Buyer</h6>
        <div class="me-auto">{{ buyerAddress }}</div>
        <span>({{ buyerAddress }})</span>
      </div>
      <div class="vertical-box col-lg-3 col-md-3 col-sm-3">
        <h6>Seller</h6>
        <div class="me-auto">{{ sellerAddress }}</div>
        <span>({{ sellerAddress }})</span>
      </div>
      <div class="vertical-box col-lg-3 col-md-3 col-sm-3">
        <div>{{ playermon.price }} PYM</div>
        <h6>${{ priceInUsd }}</h6>
        <span>{{ time }}</span>
      </div>
    </div>
    <mdicon name="chevron-right" />
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import { onUpdated } from '@vue/runtime-core';
import moment from 'moment';
import { classColorMapper, classImageMapper } from '../../../../helper';
import { useRouter } from 'vue-router';

export default {
  name: 'playermon-sold',
  props: ['playermon', 'usdPerMatic'],
  setup(props) {
    const router = useRouter();
    const buyerAddress = ref('');
    const sellerAddress = ref('');
    const playermonClassColor = ref('');
    const playermonClassImage = ref('');
    const priceInUsd = ref(0);
    const usdPerMaticLocal = ref(0);
    const time = ref('');
    const { playermon, usdPerMatic } = toRefs(props);
    const { token_id, buyer, price, seller, timestamp, class_type } = playermon.value;

    onUpdated(() => {
      assignUsdPerMatic(usdPerMatic.value);
    });

    const assignUsdPerMatic = (value) => {
      usdPerMaticLocal.value = value;
      priceInUsd.value = (price * usdPerMatic.value).toFixed(2);
    };

    playermonClassColor.value = classColorMapper(class_type);
    playermonClassImage.value = classImageMapper(class_type);
    priceInUsd.value = (price * usdPerMatic.value).toFixed(2);
    buyerAddress.value = buyer.substring(0, 6) + '...' + buyer.substring(buyer.length - 6, buyer.length);
    sellerAddress.value = seller.substring(0, 6) + '...' + seller.substring(seller.length - 6, seller.length);
    time.value = moment(timestamp).calendar();
    // time.value = moment(timestamp).calendar({
    //   sameDay: '[Today], hA',
    //   nextDay: '[Tomorrow], hA',
    //   nextWeek: 'dddd',
    //   lastDay: '[Yesterday]',
    //   lastWeek: 'DD/MM/YYYY, hA',
    //   sameElse: 'DD/MM/YYYY',
    // });

    const onClick = () => {
      router.push({ name: 'Playermon', query: { id: token_id } });
    };

    return { buyerAddress, sellerAddress, priceInUsd, playermonClassColor, playermonClassImage, time, onClick };
  },
};
</script>

<style scoped>
.playermon-sold {
  border: 2px solid whitesmoke;
  border-radius: 16px;
  padding: 8px 24px;
  cursor: pointer;
}

.card-content {
  flex: 1;
}

.vertical-box {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.vertical-box > h6 {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  color: #ffffff75;
}

.token-id {
  background: var(--sub-background-color);
  color: whitesmoke;
  border-radius: 8px;
  width: 100px;
}

.token-id > div {
  margin-top: 2px;
  font-size: 14px;
}

.vertical-box > span {
  font-size: 14px;
  text-align: left;
  color: #ffffff50;
}

.vertical-box:last-child,
.vertical-box:last-child h6,
.vertical-box:last-child > span {
  text-align: right;
}

.vertical-box:last-child h6 {
  font-size: 16px;
}
</style>
