const initialState = {
  message: '',
  type: '',
  show: false,
  isLink: false,
};

export const globalAlert = {
  namespaced: true,
  state: initialState,
  mutations: {
    showGlobalAlert(state, payload) {
      state.show = true;
      state.type = payload.type;
      state.message = payload.message;
      state.isLink = payload.isLink;
    },
    dismissGlobalAlert(state) {
      state.show = false;
      setTimeout(() => {
        state.type = '';
        state.message = '';
        state.isLink = false;
      }, 200);
    },
  },
  actions: {
    show({ commit }, payload) {
      commit('showGlobalAlert', payload);
    },
    dismiss({ commit }) {
      commit('dismissGlobalAlert');
    },
  },
};
