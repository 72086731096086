<template>
  <nav class="navbar navbar-expand-lg navbar-dark nav-background custom-nav">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="img-fluid" src="../assets/horizontal_logo.png" alt="" style="height: 40px; margin-left: 10px" />
      </a>
      <button class="navbar-toggler custom-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-for="item in navItems" :key="item.name" class="nav-item">
            <a :class="item.presale ? 'nav-link active presale' : 'nav-link active'" aria-current="page" @click="routeTo(item)">{{ item.name.toUpperCase() }}</a>
          </li>
        </ul>
        <div>
          <a href="/login">
            <img src="../assets/login.png" style="width: 200px; position: absolute; right: 0" />
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    jumptoSection(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
    async routeTo(item) {
      if (item.url) {
        window.open(
          item.url,
          '_blank' // <- This is what makes it open in a new window.
        );
        return;
      }

      if (this.$route.fullPath != item.link) {
        const routingComplete = await this.$router.push(item.link);

        if (routingComplete) {
          if (item.jumpto != '') {
            this.jumptoSection(item.jumpto);
          }
        }
      }

      if (item.jumpto != '') {
        this.jumptoSection(item.jumpto);
      }
    },
  },
  data: () => ({
    playermonCarouselModel: 0,
    navItems: [
      { name: 'HOME', link: '/', jumpto: '' },
      { name: 'TEAM', link: '/Team', jumpto: '' },
      { name: 'ROADMAP', url: 'https://whitepaper.playermon.com/roadmap' },
      { name: 'TOKENOMICS', link: '/Tokenomics', jumpto: '' },
      { name: 'DOCUMENTS', url: 'https://whitepaper.playermon.com' },
      { name: 'PLAY NOW', link: '/download', jumpto: '' },
      { name: 'MARKETPLACE', link: '/marketplace', jumpto: '' },
      { name: 'Login', link: '/login', jumpto: '', presale: true },
    ],
  }),
};
</script>

<style scoped>
@media only screen and (max-width: 991px) {
  .custom-nav {
    position: relative !important;
    background-color: rgb(10, 16, 90, 0.9) !important;
  }
}

.custom-nav {
  z-index: 99;
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0, 0.2);
}

nav a {
  cursor: pointer;
  color: white;
}

.presale {
  display: none;
}

.show .presale,
.collapsing .presale {
  display: block;
  background: linear-gradient(180deg, rgba(89, 198, 215, 0) 0%, #4db3e1 100%);
  width: 135px;
  margin: auto;
  margin-top: 16px;
  border: 5px solid #9fd3e7;
  border-radius: 20px;
}

.show img,
.collapsing img {
  display: none;
}

button:focus {
  box-shadow: none !important;
}
</style>
