import axios from 'axios';
import { getDateFromTo } from './utils';

const API_URL = process.env.VUE_APP_API_MARKETPLACE_URL;

const ROUTES = Object.freeze({
  PLAYERMON_LIST: '/marketplaceV2',
  SALE_SUMMARIES: '/marketplaceV2/saleSummaries',
  RECENT_SOLD: '/marketplaceV2/getRecentSold',
});

export const getPlayermonList = async (params) => {
  const response = await axios.get(API_URL + ROUTES.PLAYERMON_LIST, { params: params });
  return response.data;
};

export const getSaleSummaries = async (duration) => {
  const { dateFrom, dateTo } = getDateFromTo(duration);
  const response = await axios.get(API_URL + ROUTES.SALE_SUMMARIES, { params: { dateFrom, dateTo } });
  return response.data;
};

export const getRecentSold = async (params) => {
  const { page } = params;
  const response = await axios.get(API_URL + ROUTES.RECENT_SOLD, { params: { page, limit: 10 } });
  return response.data;
};
