import axios from 'axios';
import { USER_TOKEN } from '../../../services/authService';

const API_URL = process.env.VUE_APP_API_URL;

const ROUTES = Object.freeze({
  CLAIM_SGEM: '/sgemClaim/claimSgem',
  CHECK_SGEM_NONCE: '/sgemClaim/checkSgemNonce',
});

export const claimSgem = async () => {
  const jwt = JSON.parse(localStorage.getItem(USER_TOKEN));
  const response = await axios.post(
    API_URL + ROUTES.CLAIM_SGEM,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response.data;
};

export const checkSgemNonce = async () => {
  const jwt = JSON.parse(localStorage.getItem(USER_TOKEN));
  const response = await axios.get(API_URL + ROUTES.CHECK_SGEM_NONCE, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response.data;
};
