<template>
  <div style="background-color: #0a105a">
    <NavBar />
    <container style="padding: 0; margin: 0; max-width: 100%">
      <a href="https://whitepaper.playermon.com/the-team" target="_blank">
        <img class="img-fluid" style="height: 100%; width: 100%; margin-bottom: -20%" src="../assets/backgrounds/playermon_team.png" />
      </a>
    </container>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'Team',
  components: {
    Footer,
    NavBar,
  },
};
</script>
