<template>
  <div class="material-card">
    <img :src="img" width="100" />
    <div class="balance">{{ balance }}</div>
    <div>{{ name }}</div>
  </div>
</template>

<script>
export default {
  name: 'Material card',
  props: ['img', 'balance', 'name'],
  setup() {},
};
</script>

<style scoped>
.material-card {
  width: 100%;
  max-width: 300px;
  padding: 24px;
  border: 1px solid #ffffff50;
  border-radius: 12px;
}
.balance {
  margin: 32px 0 16px 0;
  font-size: 32px;
  font-weight: bold;
}
</style>
