import { createApp } from 'vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import VueGtag from 'vue-gtag-next';

import App from './App.vue';
import router from './router';
import store from './store';
import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';

const app = createApp(App)
  .use(store)
  .use(router)
  .use(mdiVue, {
    icons: mdijs,
  })
  .use(VueGtag, {
    property: { id: 'G-5QVQRDLTF3' },
  });

app.mount('#app');
