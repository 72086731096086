<template>
  <div class="d-flex" style="flex-direction: column; width: 100%">
    <div class="d-flex mb-3" style="flex-wrap: wrap; gap: 16px">
      <div v-for="breedingPod in breedingPods" :key="breedingPod.id" style="border: 1px solid #ffffff50; padding: 24px; border-radius: 16px">
        <div class="d-flex mb-3">
          <span style="border-radius: 8px; padding: 2px 8px; background: #ffffff26; font-size: 12px">ID: {{ breedingPod.id }}</span>
        </div>
        <img :src="breedingPod.img" width="150" />
      </div>
    </div>
    <bottom-page-button v-if="breedingPods.length > 0" @onLeft="onLeft" @onRight="onRight" :currentPage="page" :totalPage="totalPage" />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
// import axios from 'axios';
import PlayermonNFT from '../../../assets/abi/PlayermonNFT.json';
import BottomPageButton from '../../../components/BottomPageButton.vue';

export default {
  components: { BottomPageButton },
  setup() {
    const store = useStore();
    const totalBreedingPod = ref(0);
    const page = ref(1);
    const breedingPodPerPage = 10;
    const userWalletAddress = ref('');
    const breedingPods = ref([]);
    const totalPage = ref(1);

    onMounted(async () => {
      await connectWallet();
    });

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.error('No Metamask');
        return false;
      }
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ethersProvider.getSigner();
      try {
        await provider.request({
          method: 'eth_requestAccounts',
        });
        const userAddress = await signer.getAddress();
        userWalletAddress.value = userAddress;
        const userNetwork = await signer.getChainId();

        const nftContract = new ethers.Contract(userNetwork === 137 ? '0x927163B67Dacc20Ce9cd8fdfC44C970B1b881e37' : '0x8561CC7299374d88724426A144f52A0D76D64253', PlayermonNFT.abi, signer);
        const userNFTBalance = await nftContract.balanceOf(userAddress);
        totalBreedingPod.value = parseInt(userNFTBalance.toString());
        totalPage.value = Math.ceil(totalBreedingPod.value / breedingPodPerPage);

        await getBreedingPodMetadata();

        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error('Metamask', e);
      }
    };

    const getBreedingPodMetadata = async () => {
      await store.dispatch('loading/setLoading', true);
      const breedingPodArray = [];

      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ethersProvider.getSigner();
      const userNetwork = await signer.getChainId();
      const nftContract = new ethers.Contract(userNetwork === 137 ? '0x927163B67Dacc20Ce9cd8fdfC44C970B1b881e37' : '0x8561CC7299374d88724426A144f52A0D76D64253', PlayermonNFT.abi, signer);
      let i = page.value * breedingPodPerPage - breedingPodPerPage;
      const currentPageBreedingPod = page.value * breedingPodPerPage;
      const max = currentPageBreedingPod < totalBreedingPod.value ? currentPageBreedingPod : totalBreedingPod.value;
      for (i; i < max; i++) {
        const id = await nftContract.tokenOfOwnerByIndex(userWalletAddress.value, i);
        // const metadata = await axios.get(userNetwork === 137 ? `https://metadata.playermon.com/breedingpod/${id}.json` : `https://metadata.playermon.com/breedingpod/${id}.json`);
        breedingPodArray.push({
          id: parseInt(id.toString()),
          img: require('../../../assets/breeding_pod.png'), // metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'),
        });
      }
      breedingPods.value = breedingPodArray;
      console.log('jason', breedingPods.value);
      await store.dispatch('loading/setLoading', false);
    };

    const onLeft = async () => {
      if (page.value > 1) {
        page.value -= 1;
        await getBreedingPodMetadata();
      }
    };

    const onRight = async () => {
      if (totalPage.value > page.value) {
        page.value += 1;
        await getBreedingPodMetadata();
      }
    };

    return { totalBreedingPod, breedingPods, page, totalPage, onLeft, onRight };
  },
};
</script>
