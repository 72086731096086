<template>
  <material-card :img="require('../../../assets/breeding_pod_shard.png')" name="Breeding Pod Shard" :balance="readableShard" />
</template>

<script>
import { ethers } from 'ethers';
import { useStore } from 'vuex';
import MaterialCard from './components/MaterialCard.vue';
import { Fraction, JSBI } from '@pancakeswap-libs/sdk';
import ERC20 from '../../../assets/abi/ERC20.json';
import { onMounted, ref } from '@vue/runtime-core';

export default {
  components: { MaterialCard },
  name: 'Breeding pod shard',
  setup() {
    const store = useStore();
    const readableShard = ref(0);

    onMounted(async () => {
      await connectWallet();
    });

    const connectWallet = async () => {
      await store.dispatch('loading/setLoading', true);
      const provider = window.ethereum;
      if (!provider) {
        console.error('No Metamask');
        return false;
      }
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ethersProvider.getSigner();
      try {
        await provider.request({
          method: 'eth_requestAccounts',
        });
        const userAddress = await signer.getAddress();
        const userNetwork = await signer.getChainId();

        const breedingPodShardErc20Contract = new ethers.Contract(userNetwork !== 137 ? '0xFA16D3D4e8bE193775283ec825eBfe9f4dF6e1D0' : '0x14006b7fefa4b6ab562916a5caf1d9a3848e5a3a', ERC20.abi, signer);
        const rawBreedingPodShard = await breedingPodShardErc20Contract.balanceOf(userAddress);
        readableShard.value = new Fraction(JSBI.BigInt(rawBreedingPodShard), ethers.utils.parseUnits('1', 18)).toSignificant(8, {}, 1);
        console.log('harvested breeding pod shard: ', readableShard.value);
        await store.dispatch('loading/setLoading', false);
      } catch (e) {
        await store.dispatch('loading/setLoading', false);
        console.error('Metamask', e);
      }
    };

    return { readableShard };
  },
};
</script>
