const initialState = {
  currentTab: 'playermon',
};

export const inventory = {
  namespaced: true,
  state: initialState,
  mutations: {
    setCurrentTab(state, tab) {
      state.currentTab = tab;
    },
  },
  actions: {
    setCurrentTab({ commit }, tab) {
      commit('setCurrentTab', tab);
    },
  },
};
